<template>
  <div class="grid">
    <TotpManagement />

    <FirestorePasswordManagement />

    <div class="col-remaining panel panel--margin-right">
      <header class="panel__header">
        <h3 class="panel__header__title" :class="{ 'text-red-500': encryptPwTsExpired }">
          Update Encryption Password
        </h3>
        <span
          v-if="encryptPwDate != ''" class="panel__header__subtitle" :class="{ 'text-red-500': encryptPwTsExpired }"
        >
          Updated: {{ encryptPwDate }}
        </span>
      </header>

      <div class="form">
        <div class="form__row">
          <label for="oldPassword">Old Password:</label>
          <input
            id="oldPassword" v-model="encryptPws.oldPw" type="password" autocomplete="new-password"
            :disabled="requestProcessing" placeholder="Current password..."
          />
        </div>

        <div class="form__row">
          <label for="newPassword">New Password:</label>
          <input
            id="newPassword" v-model="encryptPws.newPw1" type="password" autocomplete="new-password"
            :disabled="requestProcessing" placeholder="New password..."
          />
        </div>

        <div class="form__row">
          <label for="newPassword2">New Password Again:</label>
          <input
            id="newPassword2" v-model="encryptPws.newPw2" type="password" autocomplete="new-password"
            :disabled="requestProcessing" placeholder="Re-enter new password..."
          />
        </div>

        <div class="form__row">
          <button
            class="form__row__button form__row__button form__row__button--right" :disabled="requestProcessing"
            @click="changePassword()"
          >
            Change Password
          </button>
        </div>

        <div v-if="warningMsg.changePassword != ''" class="form__row">
          <p class="warning-text">
            *{{ warningMsg.changePassword }}
          </p>
        </div>
      </div>
    </div>

    <div class="col-remaining panel panel--margin-right">
      <header class="panel__header">
        <h3 class="panel__header__title" :class="{ 'text-red-500': tradingPwTsExpired }">
          Update Trading Password
        </h3>
        <span
          v-if="tradingPwDate != ''" class="panel__header__subtitle" :class="{ 'text-red-500': tradingPwTsExpired }"
        >
          Updated: {{ tradingPwDate }}
        </span>
      </header>

      <div class="form">
        <div class="form__row">
          <label for="oldTradingPassword">Old Password:</label>
          <input
            id="oldTradingPassword" v-model="tradingPws.oldPw" type="password" autocomplete="off"
            :disabled="requestProcessing" placeholder="Current password..."
          />
        </div>

        <div class="form__row">
          <label for="newTradingPassword">New Password:</label>
          <input
            id="newTradingPassword" v-model="tradingPws.newPw1" type="password" autocomplete="off"
            :disabled="requestProcessing" placeholder="New password..."
          />
        </div>

        <div class="form__row">
          <label for="newTradingPassword2">New Password Again:</label>
          <input
            id="newTradingPassword2" v-model="tradingPws.newPw2" type="password" autocomplete="off"
            :disabled="requestProcessing" placeholder="Re-enter new password..."
          />
        </div>

        <div class="form__row">
          <button
            class="form__row__button form__row__button form__row__button--right" :disabled="requestProcessing"
            @click="setTradingPassword()"
          >
            Set Trading Password
          </button>
        </div>

        <div v-if="warningMsg.setTradingPassword != ''" class="form__row">
          <p class="warning-text">
            *{{ warningMsg.setTradingPassword }}
          </p>
        </div>
      </div>
    </div>

    <div class="col-remaining panel">
      <header class="panel__header">
        <h3 class="panel__header__title" :class="{ 'text-red-500': noncePwTsExpired }">
          Update Nonce
        </h3>
        <span v-if="noncePwDate != ''" class="panel__header__subtitle" :class="{ 'text-red-500': noncePwTsExpired }">
          Updated: {{ noncePwDate }}
        </span>
      </header>

      <div class="panel__form form">
        <div class="form__row">
          <label for="oldNonce">Old Nonce: </label>
          <input
            id="oldNonce" v-model="nonces.oldNonce" type="password" autocomplete="new-password"
            :disabled="requestProcessing" placeholder="Current nonce..."
          />
        </div>

        <div class="form__row">
          <label for="newNonce">New Nonce: </label>
          <input
            id="newNonce" v-model="nonces.newNonce" type="password" autocomplete="new-password"
            :disabled="requestProcessing" placeholder="New nonce..."
          />
        </div>

        <div class="form__row">
          <button
            class="form__row__button form__row__button form__row__button--right" :disabled="requestProcessing"
            @click="changeNonce()"
          >
            Change Nonce
          </button>
        </div>

        <div v-if="warningMsg.changeNonce != ''" class="form__row">
          <p class="warning-text">
            *{{ warningMsg.changeNonce }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useUserStore } from '@/stores/user/user';
import { useWebSocketStore } from '@/stores/user/ws';

import TotpManagement from '@/components/user/settings/system/TotpManagement.vue';
import FirestorePasswordManagement from '@/components/user/settings/system/FirestorePasswordManagement.vue';

import { UserPasswordWrapper, UserNonceWrapper } from '@/types/user';
import { useRequestStore } from '@/stores/request';
import { REQUEST_STATUS } from '@/types/general';
import { checkPwExpired } from '@/utilities';

// Stores
const requestStore = useRequestStore();
const userStore = useUserStore();
const wsStore = useWebSocketStore();

// Computed
const userPasswordsTs = computed(() => userStore.passwordsTs);
const encryptPwDate = computed(() => {
  const timestamp = userPasswordsTs.value.encrypt;
  if (!timestamp) return '';
  return new Date(timestamp * 1000).toLocaleString();
});
const encryptPwTsExpired = computed(() => {
  return checkPwExpired(userPasswordsTs.value.encrypt || 0);
});
const noncePwDate = computed(() => {
  const timestamp = userPasswordsTs.value.nonce;
  if (!timestamp) return '';
  return new Date(timestamp * 1000).toLocaleString();
});
const noncePwTsExpired = computed(() => {
  return checkPwExpired(userPasswordsTs.value.nonce || 0);
});
const tradingPwDate = computed(() => {
  const timestamp = userPasswordsTs.value.trading;
  if (!timestamp) return '';
  return new Date(timestamp * 1000).toLocaleString();
});
const tradingPwTsExpired = computed(() => {
  return checkPwExpired(userPasswordsTs.value.trading || 0);
});

// Reactive
const requestProcessing = ref(false);
const statusId = ref('');
const encryptPws = ref({ oldPw: '', newPw1: '', newPw2: '' });
const tradingPws = ref({ oldPw: '', newPw1: '', newPw2: '' });
const nonces = ref({ oldNonce: '', newNonce: '' });
const warningMsg = ref(
  { 'changeFirestorePassword': '', 'changePassword': '', 'changeNonce': '', 'setTradingPassword': '' });

// Watchers
watch(() => requestStore.requestStatus, (newVal) => {
  if (newVal && statusId.value in newVal && newVal[statusId.value] !== REQUEST_STATUS.PENDING) {
    requestStore.removeRequestStatus(statusId.value);
    statusId.value = '';
    requestProcessing.value = false;
    resetForms();
  }
}, { deep: true });

// Functions
const resetForms = () => {
  encryptPws.value = { oldPw: '', newPw1: '', newPw2: '' };
  tradingPws.value = { oldPw: '', newPw1: '', newPw2: '' };
  nonces.value = { oldNonce: '', newNonce: '' };
  warningMsg.value = {
    'changeFirestorePassword': '',
    'changePassword': '',
    'changeNonce': '',
    'setTradingPassword': '',
  };
};

const changePassword = () => {
  if (requestProcessing.value) return;

  if (encryptPws.value.oldPw === '') {
    warningMsg.value.changePassword = 'The old password is required';
    return;
  }

  if (encryptPws.value.newPw1 === '') {
    warningMsg.value.changePassword = 'The new password is required';
    return;
  }

  if (encryptPws.value.newPw1 !== encryptPws.value.newPw2) {
    warningMsg.value.changePassword = 'New passwords do not match';
    return;
  }

  const wrapper = new UserPasswordWrapper('user_change_pws', encryptPws.value.oldPw, encryptPws.value.newPw1);
  statusId.value = wrapper.statusId;
  requestProcessing.value = true;
  wsStore.sendWithRequestStatus(wrapper);
};

const setTradingPassword = () => {
  if (requestProcessing.value) return;

  if (tradingPws.value.oldPw === '') {
    if (userPasswordsTs.value.trading) {
      warningMsg.value.setTradingPassword = 'The old password is required';
      return;
    }
  }

  if (tradingPws.value.newPw1 === '') {
    warningMsg.value.setTradingPassword = 'The new password is required';
    return;
  }

  if (tradingPws.value.newPw1 !== tradingPws.value.newPw2) {
    warningMsg.value.setTradingPassword = 'New passwords do not match';
    return;
  }

  userStore.setTradingSessionPasswordHttp(tradingPws.value.oldPw, tradingPws.value.newPw1)
    .then(() => {
      tradingPws.value.oldPw = '';
      tradingPws.value.newPw1 = '';
      tradingPws.value.newPw2 = '';
      warningMsg.value.setTradingPassword = '';
    })
    .catch(() => {
      tradingPws.value.oldPw = '';
      tradingPws.value.newPw1 = '';
      tradingPws.value.newPw2 = '';
      warningMsg.value.setTradingPassword = '';
    });
};

const changeNonce = () => {
  if (requestProcessing.value) return;

  if (nonces.value.oldNonce === '') {
    warningMsg.value.changeNonce = 'The old nonce is required';
    return;
  }

  if (nonces.value.newNonce === '') {
    warningMsg.value.changeNonce = 'The new nonce is required';
    return;
  }

  if (nonces.value.newNonce.length !== 12) {
    warningMsg.value.changeNonce = 'The new nonce must be 12 characters long exactly';
    return;
  }

  const wrapper = new UserNonceWrapper('user_change_nonce', nonces.value.oldNonce, nonces.value.newNonce);
  statusId.value = wrapper.statusId;
  requestProcessing.value = true;
  wsStore.sendWithRequestStatus(wrapper);
};
</script>

<style lang="scss" scoped>
// Boxes in dropdown
.light, .dark {
  width: 40px;
  height: 40px;
  border: 1%;
  cursor: pointer;
}
.light {
  border: white 2px solid;
  background-color: white;
}
.dark {
  border: white 2px solid;
  background-color: black;
}
.selected {
  border-color: green;
}
</style>
