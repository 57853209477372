<template>
  <div class="grid">
    <div class="col col-remaining">
      <div class="panel">
        <div class="panel__header">
          <h3 class="panel__header__title">
            Client Log File
          </h3>
        </div>

        <div class="panel__body vtable">
          <div class="vtable__row vtable__row--selection hand" @click="redirectLogFile('client')">
            <div class="vtable__row__item">
              <pre>Client logs</pre>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class="panel">
        <div class="panel__header">
          <h3 class="panel__header__title">
            Server Log Files ({{ sortedLogsFileNames.length }})
          </h3>
        </div>

        <div class="panel__body vtable">
          <div
            v-for="(fileName, index) in sortedLogsFileNames" :key="index"
            class="vtable__row vtable__row--selection hand" @click="redirectLogFile(fileName)"
          >
            <div class="vtable__row__item">
              <pre>{{ fileName }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useServerLogsStore } from '@/stores/user/serverLogs';
import { useWebSocketStore } from '@/stores/user/ws';
import { LogsWrapper } from '@/types/serverLogs';

// Stores
const logsStore = useServerLogsStore();
const wsStore = useWebSocketStore();
const router = useRouter();
const route = useRoute();

const logsFileNames = computed(() => logsStore.fileNames);
const sortedLogsFileNames = computed(() => {
  return [...logsFileNames.value].sort((a, b) => (a > b ? -1 : 1));
});

onMounted(() => {
  const logsWrapper = new LogsWrapper('get_logs_file_names');
  wsStore.sendWithRequestStatus(logsWrapper);
});

const redirectLogFile = (id: string) => {
  void router.push({ path: `/logs/${id}`, query: route.query });
};
</script>

<style lang="scss" scoped>

</style>
