<template>
  <div>
    <h3 class="order-form-label text-lg">
      Strategy Form
    </h3>

    <div>
      <label class="order-form-label">Name</label>
      <input
        v-model="writeOrderStrategy.name" type="text" :class="orderFormClass"
        :disabled="requestProcessing" placeholder="e.g front run percent close" class="w-11/12"
      />
    </div>

    <div>
      <h3 class="order-form-label">
        Order Strategy Type
      </h3>
      <select
        v-model="writeOrderStrategy.type" :class="orderFormClass" :disabled="requestProcessing"
        class="w-11/12" @change="typeUpdate"
      >
        <option :value="ORDER_STRATEGY_TYPE.DEFAULT" default disabled>
          Select Strategy Type
        </option>
        <option :value="ORDER_STRATEGY_TYPE.NONE">
          None
        </option>
        <option :value="ORDER_STRATEGY_TYPE.PUSH_AWAY">
          Push Away
        </option>
        <option :value="ORDER_STRATEGY_TYPE.FRONTRUN_PRICE">
          Frontrun Price
        </option>
        <option :value="ORDER_STRATEGY_TYPE.FRONTRUN_PERCENT">
          Frontrun Percent
        </option>
      </select>
    </div>

    <div v-if="writeOrderStrategy.type == ORDER_STRATEGY_TYPE.PUSH_AWAY">
      <div>
        <label class="order-form-label">Percent Away</label>
        <input
          v-model="writeOrderStrategy.pushAwayPercent" type="text" :class="orderFormClass"
          placeholder="e.g 80%" :disabled="requestProcessing" class="w-11/12"
        />
      </div>

      <div>
        <label class="order-form-label">Percent Delta</label>
        <input
          v-model="writeOrderStrategy.pushAwayDelta" type="text" :class="orderFormClass"
          placeholder="e.g 5% (percent away 80% move at 75%)" :disabled="requestProcessing" class="w-11/12"
        />
      </div>
    </div>
    <div
      v-if="writeOrderStrategy.type == ORDER_STRATEGY_TYPE.FRONTRUN_PRICE
        || writeOrderStrategy.type == ORDER_STRATEGY_TYPE.FRONTRUN_PERCENT"
    >
      <div>
        <label class="order-form-label">Low Bound</label>
        <input
          v-model="writeOrderStrategy.frontrunLowBound" type="text" :class="orderFormClass"
          placeholder="e.g 20% or 0.5 (nearest price)" :disabled="requestProcessing" class="w-11/12"
        />
      </div>

      <div>
        <label class="order-form-label">Upper Bound</label>
        <input
          v-model="writeOrderStrategy.frontrunHighBound" type="text" :class="orderFormClass"
          placeholder="e.g 80% or 0.1 (furthest price)" :disabled="requestProcessing" class="w-11/12"
        />
      </div>

      <div>
        <label class="order-form-label">Orderbook Makeup % to Frontrun</label>
        <input
          v-model="writeOrderStrategy.makeupPercentage" type="text" :class="orderFormClass"
          placeholder="e.g 10%" :disabled="requestProcessing" class="w-11/12"
        />
      </div>

      <div>
        <label class="order-form-label">Override: Min $ Amount to Frontrun</label>
        <input
          v-model="writeOrderStrategy.minFrontrunAmount" type="text" :class="orderFormClass"
          placeholder="e.g. 10000 (ignore smaller amounts)" :disabled="requestProcessing" class="w-11/12"
        />
      </div>

      <div>
        <label class="order-form-label">Override: Max $ Amount to Frontrun</label>
        <input
          v-model="writeOrderStrategy.maxFrontrunAmount" type="text" :class="orderFormClass"
          placeholder="e.g. 50000 (frontrun larger amounts)" :disabled="requestProcessing" class="w-11/12"
        />
      </div>
    </div>

    <div
      v-if="writeOrderStrategy.type != ORDER_STRATEGY_TYPE.NONE
        && writeOrderStrategy.type != ORDER_STRATEGY_TYPE.DEFAULT"
    >
      <div>
        <label class="order-form-label">Side</label>
        <select
          v-model="writeOrderStrategy.side" :class="orderFormClass"
          :disabled="requestProcessing" class="w-11/12"
        >
          <option :value="SIDE.UNKNOWN" default disabled>
            Select Side
          </option>
          <option :value="SIDE.BUY">
            Buy
          </option>
          <option :value="SIDE.SELL">
            Sell
          </option>
        </select>
      </div>

      <div>
        <label class="order-form-label">Move By Type</label>
        <select
          v-model="writeOrderStrategy.moveBy" :class="orderFormClass"
          :disabled="requestProcessing" class="w-11/12"
        >
          <option :value="ORDER_MOVE_BY.DEFAULT" default disabled>
            Select Move By Type
          </option>
          <option :value="ORDER_MOVE_BY.MARGIN">
            Margin
          </option>
          <option :value="ORDER_MOVE_BY.QUANTITY">
            Quantity
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import {
  OrderStrategyType, ORDER_STRATEGY_TYPE, ORDER_MOVE_BY,
} from '@/types/order_strategies';
import { SIDE } from '@/types/account';

const emit = defineEmits(['setOrderStrategy']);

// Props
const props = defineProps({
  requestProcessing: Boolean,
  orderStrategy: {
    type: OrderStrategyType,
    default: () => new OrderStrategyType(),
  },
});

// Computed
const orderFormClass = computed(() => {
  return props.requestProcessing ? 'order-form-input-disabled' : 'order-form-input';
});

// Reactive
const writeOrderStrategy = ref<OrderStrategyType>(new OrderStrategyType());

//
watch(() => writeOrderStrategy.value.name, () => {
  setOrderStrategy(writeOrderStrategy.value);
});

watch(() => writeOrderStrategy.value.pushAwayPercent, (newVal, oldVal) => {
  if (isNaN(Number(newVal))) {
    writeOrderStrategy.value.pushAwayPercent = oldVal;
    return;
  }
  setOrderStrategy(writeOrderStrategy.value);
});

watch(() => writeOrderStrategy.value.pushAwayDelta, (newVal, oldVal) => {
  if (isNaN(Number(newVal))) {
    writeOrderStrategy.value.pushAwayDelta = oldVal;
    return;
  }
  setOrderStrategy(writeOrderStrategy.value);
});

watch(() => writeOrderStrategy.value.frontrunLowBound, (newVal, oldVal) => {
  if (isNaN(Number(newVal))) {
    writeOrderStrategy.value.frontrunLowBound = oldVal;
    return;
  }
  setOrderStrategy(writeOrderStrategy.value);
});

watch(() => writeOrderStrategy.value.frontrunHighBound, (newVal, oldVal) => {
  if (isNaN(Number(newVal))) {
    writeOrderStrategy.value.frontrunHighBound = oldVal;
    return;
  }
  setOrderStrategy(writeOrderStrategy.value);
});

watch(() => writeOrderStrategy.value.minFrontrunAmount, (newVal, oldVal) => {
  if (isNaN(Number(newVal))) {
    writeOrderStrategy.value.minFrontrunAmount = oldVal;
    return;
  }
  setOrderStrategy(writeOrderStrategy.value);
});

watch(() => writeOrderStrategy.value.maxFrontrunAmount, (newVal, oldVal) => {
  if (isNaN(Number(newVal))) {
    writeOrderStrategy.value.maxFrontrunAmount = oldVal;
    return;
  }
  setOrderStrategy(writeOrderStrategy.value);
});

watch(() => writeOrderStrategy.value.makeupPercentage, (newVal, oldVal) => {
  if (isNaN(Number(newVal))) {
    writeOrderStrategy.value.makeupPercentage = oldVal;
    return;
  }
  setOrderStrategy(writeOrderStrategy.value);
});

watch(() => writeOrderStrategy.value.side, () => {
  setOrderStrategy(writeOrderStrategy.value);
});

watch(() => writeOrderStrategy.value.moveBy, () => {
  setOrderStrategy(writeOrderStrategy.value);
});

// Lifecycle
onMounted(() => {
  writeOrderStrategy.value = JSON.parse(JSON.stringify(props.orderStrategy)) as OrderStrategyType;
});

const setOrderStrategy = (orderStrategy: OrderStrategyType) => {
  emit('setOrderStrategy', orderStrategy);
};

const typeUpdate = () => {
  writeOrderStrategy.value.pushAwayPercent = '';
  writeOrderStrategy.value.pushAwayDelta = '';
  writeOrderStrategy.value.frontrunLowBound = '';
  writeOrderStrategy.value.frontrunHighBound = '';
  writeOrderStrategy.value.minFrontrunAmount = '';
  writeOrderStrategy.value.maxFrontrunAmount = '';
  writeOrderStrategy.value.makeupPercentage = '';
  setOrderStrategy(writeOrderStrategy.value);
};
</script>

<style lang="scss" scoped>

</style>
