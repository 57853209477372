import { defineStore } from 'pinia';
import {
  FundingAnalysisState, FundingCyclesServerResp, FundingCycle, FundingCycleServer,
} from '@/types/fundingAnalysis';

function convertFundingCycleServer(fundingCycle: FundingCycleServer): FundingCycle {
  const newFundingCycle = new FundingCycle(
    fundingCycle.fundingInterval, fundingCycle.fundingRate, fundingCycle.fundingRateDaily,
    fundingCycle.symbol, fundingCycle.timestamp,
  );

  return newFundingCycle;
}

export const useFundingAnalysisStore = defineStore('fundingAnalysis', {
  state: (): FundingAnalysisState => ({
    fundingCycles: {},
  }),
  actions: {
    setFundingCycles(resp: FundingCyclesServerResp): void {
      const symbol = resp.fundingCycles.length > 0 ? resp.fundingCycles[0].symbol : '';
      if (symbol != '') {
        const newFundingCyles: FundingCycle[] = [];

        resp.fundingCycles.forEach((fundingCycle: FundingCycleServer) => {
          newFundingCyles.push(convertFundingCycleServer(fundingCycle));
        });

        this.fundingCycles[symbol] = newFundingCyles;
      }
    },
    resetFundingCycles(): void {
      this.fundingCycles = {};
    },
  },
});
