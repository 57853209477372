<template>
  <div class="vtable__m-overview">
    <div class="vtable__m-overview__item">
      <label style="text-transform: uppercase;" for="name">NAME</label>
      <input
        id="name" v-model="writeOrderStrategy.name"
        :disabled="requestProcessing || !editing" class="vtable__m-overview__item__input"
      />
    </div>
    <div class="vtable__m-overview__item">
      <label>TYPE</label>
      <div style="text-transform: capitalize;">
        {{ writeOrderStrategy.type }}
      </div>
    </div>

    <div v-if="showInput('pushAwayPercent', writeOrderStrategy.type)" class="vtable__m-overview__item">
      <label style="text-transform: uppercase;" for="pushAwayPercent">Push Away Percent</label>
      <input
        id="pushAwayPercent" v-model="writeOrderStrategy.pushAwayPercent"
        :disabled="requestProcessing || !editing" class="vtable__m-overview__item__input"
      />
    </div>

    <div v-if="showInput('pushAwayDelta', writeOrderStrategy.type)" class="vtable__m-overview__item">
      <label style="text-transform: uppercase;" for="pushAwayDelta">Push Away Delta</label>
      <input
        id="pushAwayDelta" v-model="writeOrderStrategy.pushAwayDelta"
        :disabled="requestProcessing || !editing" class="vtable__m-overview__item__input"
      />
    </div>

    <div v-if="showInput('frontrunLowBound', writeOrderStrategy.type)" class="vtable__m-overview__item">
      <label style="text-transform: uppercase;" for="frontrunLowBound">Frontrun Low Bound</label>
      <input
        id="frontrunLowBound" v-model="writeOrderStrategy.frontrunLowBound"
        :disabled="requestProcessing || !editing" class="vtable__m-overview__item__input"
      />
    </div>

    <div v-if="showInput('frontrunHighBound', writeOrderStrategy.type)" class="vtable__m-overview__item">
      <label style="text-transform: uppercase;" for="frontrunHighBound">Frontrun High Bound</label>
      <input
        id="frontrunHighBound" v-model="writeOrderStrategy.frontrunHighBound"
        :disabled="requestProcessing || !editing" class="vtable__m-overview__item__input"
      />
    </div>

    <div v-if="showInput('minFrontrunAmount', writeOrderStrategy.type)" class="vtable__m-overview__item">
      <label style="text-transform: uppercase;" for="minFrontrunAmount">Min Frontrun Amount</label>
      <input
        id="minFrontrunAmount" v-model="writeOrderStrategy.minFrontrunAmount"
        :disabled="requestProcessing || !editing" class="vtable__m-overview__item__input"
      />
    </div>

    <div v-if="showInput('maxFrontrunAmount', writeOrderStrategy.type)" class="vtable__m-overview__item">
      <label style="text-transform: uppercase;" for="maxFrontrunAmount">Max Frontrun Amount</label>
      <input
        id="maxFrontrunAmount" v-model="writeOrderStrategy.maxFrontrunAmount"
        :disabled="requestProcessing || !editing" class="vtable__m-overview__item__input"
      />
    </div>

    <div v-if="showInput('makeupPercentage', writeOrderStrategy.type)" class="vtable__m-overview__item">
      <label style="text-transform: uppercase;" for="makeupPercentage">Makeup Percentage</label>
      <input
        id="makeupPercentage" v-model="writeOrderStrategy.makeupPercentage"
        :disabled="requestProcessing || !editing" class="vtable__m-overview__item__input"
      />
    </div>

    <div v-if="editing" class="vtable__m-overview__item vtable__m-overview__item--full">
      <div v-if="requestProcessing || !editing">
        <div class="text-center">
          <img class="spin-animation" :src="spinningCircle" />
        </div>
      </div>
      <div v-else class="vtable__m-overview__buttons">
        <button @click="update()">
          <i class="ti-save-alt" />
          <span>Save Changes</span>
        </button>
        <button class="danger" @click="deleteStrategy(writeOrderStrategy)">
          <i class="ti-trash" />
          <span>Delete Strategy</span>
        </button>
      </div>
    </div>
    <div v-else class="vtable__m-overview__item vtable__m-overview__item--full">
      <div class="vtable__m-overview__buttons">
        <button class="grey" @click="editing = !editing">
          <i class="ti-pencil" />
          <span>Edit Strategy</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { useOrderStrategiesStore } from '@/stores/exchanges/order_strategies';
import { OrderStrategyType, ORDER_STRATEGY_TYPE } from '@/types/order_strategies';
import { useRequestStore } from '@/stores/request';
import { REQUEST_STATUS } from '@/types/general';
import spinningCircle from '@/assets/spinning-circle.svg';

// Stores
const orderStrategiesStore = useOrderStrategiesStore();
const requestStore = useRequestStore();

// Parent properties
const props = withDefaults(defineProps<{
  orderStrategy: OrderStrategyType, // Adjust the type as needed for WalletType
}>(), {
  orderStrategy: null,
});

// Computed variables
const orderStrategiesLookup = computed((): Record<string, Record<string, boolean>> => {
  // TODO do accounting for { stategyId => OrderType[] } on the clien
  // return orderStrategiesStore.getOrderStrategiesLookup();
  return {};
});

// Variables
const requestProcessing = ref(false);
const writeOrderStrategy = ref(new OrderStrategyType());
const statusId = ref('');
const editing = ref(false);

// Watchers
// TODO can we import this?
watch(() => requestStore.requestStatus, (newVal) => {
  if (newVal && statusId.value in newVal && newVal[statusId.value] !== REQUEST_STATUS.PENDING) {
    requestStore.removeRequestStatus(statusId.value);
    statusId.value = '';
    requestProcessing.value = false;
  }
}, { deep: true });

// Vue Lifecycle Functions
onMounted(() => {
  // Make copy of parent prop as shouldn't mutate directly
  writeOrderStrategy.value = props.orderStrategy.clone();
});

// Functions
const update = (): void => {
  void orderStrategiesStore.updateOrderStrategyHttp(writeOrderStrategy.value);
};

const deleteStrategy = (orderStrategy: OrderStrategyType): void => {
  const id = orderStrategy.id;
  const attachedOrders = orderStrategiesLookup.value[id];

  if (attachedOrders && Object.keys(attachedOrders).length > 0) {
    alert(
      'Can\'t delete order strategy as attached to following orders ' +
      Object.keys(attachedOrders).join(', '),
    );
    return;
  }

  const confirmation = window.confirm(`Are you sure you want to delete order ${orderStrategy.getName()}?`);
  if (!confirmation) return;

  void orderStrategiesStore.deleteOrderStrategyHttp(orderStrategy);
};

const showInput = (property: string, type: string): boolean => {
  switch(type) {
  case ORDER_STRATEGY_TYPE.NONE:
    switch(property) {
    case 'name':
      return true;
    }
    break;
  case ORDER_STRATEGY_TYPE.PUSH_AWAY:
    switch(property) {
    case 'name':
    case 'pushAwayPercent':
    case 'pushAwayDelta':
      return true;
    }
    break;
  case ORDER_STRATEGY_TYPE.FRONTRUN_PRICE:
  case ORDER_STRATEGY_TYPE.FRONTRUN_PERCENT:
    switch(property) {
    case 'name':
    case 'frontrunLowBound':
    case 'frontrunHighBound':
    case 'minFrontrunAmount':
    case 'maxFrontrunAmount':
    case 'makeupPercentage':
      return true;
    }
    break;
  }

  return false;
};
</script>

<style lang="scss" scoped>
</style>
