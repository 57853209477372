<template>
  <div class="col-remaining panel panel--margin-right">
    <header class="panel__header">
      <h3 class="panel__header__title" :class="{ 'text-red-500': fsPwTsExpired }">
        Update Firestore Password
      </h3>
      <span v-if="fsPwDate != ''" class="panel__header__subtitle" :class="{ 'text-red-500': fsPwTsExpired }">
        Updated: {{ fsPwDate }}
      </span>
    </header>

    <div class="form">
      <div class="form__row">
        <label for="oldFsPw">Old Password:</label>
        <input
          id="oldFsPw" v-model="fsPws.oldPw" type="password" :disabled="requestProcessing"
          autocomplete="current-password" placeholder="Current password..."
        />
      </div>

      <div v-if="user2AuthEnabled" class="form__row">
        <label for="fsVerificationCode">TOTP</label>
        <input
          id="fsVerificationCode" v-model="fsPws.verificationCode" type="text" :disabled="requestProcessing"
          autocomplete="verification-code" placeholder="Verification Code"
        />
      </div>

      <div class="form__row">
        <label for="newFsPw1">New Password:</label>
        <input
          id="newFsPw1" v-model="fsPws.newPw1" type="password" :disabled="requestProcessing"
          autocomplete="new-password" placeholder="New password..."
        />
      </div>

      <div class="form__row">
        <label for="newFsPw2">New Password Again:</label>
        <input
          id="newFsPw2" v-model="fsPws.newPw2" type="password" :disabled="requestProcessing"
          autocomplete="new-password" placeholder="Re-enter new password..."
        />
      </div>

      <div class="form__row">
        <button
          class="form__row__button form__row__button form__row__button--right" :disabled="requestProcessing"
          @click="changeFirestorePassword()"
        >
          Change Password
        </button>
      </div>

      <div v-if="requestProcessing" class="text-center">
        <img class="spin-animation" :src="spinningCircle" />
      </div>

      <div v-if="warning != ''" class="form__row">
        <p class="warning-text">
          *{{ warning }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useNotificationsStore } from '@/stores/user/notifications';
import { useUserStore } from '@/stores/user/user';
import { NOTIFICATION_TYPE, NotificationType } from '@/types/user';
import { checkPwExpired } from '@/utilities';
import { Auth } from '@/firebaseAuth';
import spinningCircle from '@/assets/spinning-circle.svg';
import { RespError } from '@/types/general';

// Stores
const notificationsStore = useNotificationsStore();
const userStore = useUserStore();

// Computed
const userPasswordsTs = computed(() => userStore.passwordsTs);
const fsPwDate = computed(() => {
  const timestamp = userPasswordsTs.value.firestore;
  if (!timestamp) return '';
  return new Date(timestamp * 1000).toLocaleString();
});
const fsPwTsExpired = computed(() => {
  return checkPwExpired(userPasswordsTs.value.firestore || 0);
});
const user2AuthEnabled = computed(() => userStore.user2AuthEnabled);

// Reactive
const fsPws = ref({ oldPw: '', newPw1: '', newPw2: '', verificationCode: '' });
const warning = ref('');
const requestProcessing = ref(false);

// Functions
const changeFirestorePassword = async () => {
  requestProcessing.value = true;

  try {
    if (fsPws.value.oldPw === '') {
      throw new Error('The old password is required');
    }

    if (fsPws.value.newPw1 === '') {
      throw new Error('The new password is required');
    }

    if (fsPws.value.newPw1 !== fsPws.value.newPw2) {
      throw new Error('New passwords do not match');
    }

    await Auth.updatePassword(fsPws.value.oldPw, fsPws.value.newPw1, fsPws.value.verificationCode);
    resetForm();

    const message = 'Successfully updated firebase password';
    notificationsStore.addNotification(new NotificationType(NOTIFICATION_TYPE.SUCCESS, message));
  } catch (error: unknown) {
    const typedError = error as RespError;
    warning.value = typedError.message;
  }

  requestProcessing.value = false;
};

const resetForm = () => {
  fsPws.value = { oldPw: '', newPw1: '', newPw2: '', verificationCode: '' };
  warning.value = '';
};
</script>
