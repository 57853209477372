<template>
  <div class="card">
    <p>TradingView Analysis</p>
    <section>
      Sign in (required for chart data fetching):
      <input v-model="username" class="table__header__input-wrap__input" type="text" placeholder="Username..." />
      <input v-model="password" class="table__header__input-wrap__input" type="text" placeholder="Password..." />
      OR token login (enter "window.user.auth_token" in the browser console on a chart)
      <input v-model="token" class="table__header__input-wrap__input" type="text" placeholder="Token..." />
      <button class="btn-tw" @click="tradingViewLogin()">
        TradingView Login
      </button>
    </section>

    <section>
      <input
        v-model="symbolSearch" class="table__header__input-wrap__input" type="text" placeholder="Search symbols..."
      />
      <input
        v-model="exchangeSearch" class="table__header__input-wrap__input" type="text" placeholder="Search exchanges..."
      />
      <button class="btn-tw" @click="tradingViewSymbolsFetching()">
        Fetch TradingView Symbols
      </button>
    </section>

    <section v-if="symbols.size">
      Symbol count: {{ symbols.size }}
      <div class="panel">
        <table class="table-tw">
          <thead class="table-thead-tw">
            <tr>
              <th name="selected" class="table-th-tw">
                <input v-model="toggleSelectAll" type="checkbox" />
                Selected
              </th>
              <th name="exchangeName" class="table-th-tw">Exchange Name</th>
              <th name="symbolName" class="table-th-tw">Symbol Name</th>
              <th name="exchangeType" class="table-th-tw">Type</th>
              <th name="exchangeType" class="table-th-tw">
                <button class="btn-tw" @click="tradingViewSymbolFatFingerAnlaysis()">
                  Anlayse for Fat Fingers
                </button>
                Filter for fatfingers only: <input v-model="fatFingeredOnly" type="checkbox" />
              </th>
            </tr>
          </thead>
          <tbody class="table-tbody-tw">
            <tr v-for="(symbol, key) in getSymbols()" :key="key" class="table-tr-tw">
              <td class="table-td-tw">
                <input
                  v-model="symbol.selected" type="checkbox"
                  @change="symbolSelect(($event.target as HTMLInputElement).checked, symbol)"
                />
              </td>
              <td class="table-td-tw hand" @click="tradingViewSymbolDataFetching(symbol)">{{ symbol.exchange }}</td>
              <td class="table-td-tw hand" @click="tradingViewSymbolDataFetching(symbol)">{{ symbol.symbol }}</td>
              <td class="table-td-tw hand" @click="tradingViewSymbolDataFetching(symbol)">{{ symbol.type }}</td>
              <td class="table-td-tw hand" @click="tradingViewSymbolDataFetching(symbol)">
                <span v-if="symbol.fatFingerAnlaysis">
                  <span v-if="symbol.fatFingerErrorInfo">
                    Error: {{ symbol.fatFingerErrorInfo }}
                  </span>
                  <span v-else>
                    <span v-if="symbol.fatFingerInfo.length == 0">
                      No
                    </span>
                    <span v-else>
                      Yes:
                      <ul>
                        <li v-for="(candle, i) in symbol.fatFingerInfo" :key="i">
                          - {{ new Date(candle.startTime * 1000).toISOString().substring(0,10) }}:
                          {{ formatNumber(candle.volume) }}
                        </li>
                      </ul>
                    </span>
                  </span>
                </span>
                <span v-else />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <h3>Chart Data<span v-if="symbolData"> ({{ symbolData.ticker }})</span></h3>
    <div id="chart" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useTradingviewStore } from '@/stores/exchanges/tradingview';
import { useWebSocketStore } from '@/stores/user/ws';
import { SymbolType } from '@/types/tradingview';
import { createChart, IChartApi, ISeriesApi } from 'lightweight-charts-private';
import { getChartStyle, convertCandlesToGraphCandles } from '@/stores/exchanges/pricedata';
import { PriceData, GraphCandle } from '@/types/pricedata';
import { formatNumber } from '@/utilities';

// Store
const tradingviewStore = useTradingviewStore();
const wsStore = useWebSocketStore();

// Computed
const symbols= computed<Map<string, SymbolType>>(() => tradingviewStore.symbols);
const symbolData = computed<PriceData> (() => tradingviewStore.symbolData);

// Variables
const username = ref('');
const password = ref('');
const token = ref('');
const symbolSearch = ref('');
const exchangeSearch = ref('');
const symbolsList = ref<Map<string, SymbolType>>(new Map());
const chart = ref<IChartApi>(null);
const candlestickSeries = ref<ISeriesApi<'Candlestick'>>(null);
const graphData = ref<GraphCandle[]>([]); // leightweight-charts representation of Candlesticks
const toggleSelectAll = ref(true);
const fatFingeredOnly = ref(false);

// Watchers
watch(symbols, () => {
  symbolsList.value = new Map(symbols.value);
});

watch(symbolData, () => {
  graphData.value = convertCandlesToGraphCandles(symbolData.value.candles);

  if (chart.value === null) {
    renderChart();
  } else {
    candlestickSeries.value.setData(graphData.value);
  }

  chart.value.timeScale().fitContent();
});

watch(toggleSelectAll, () => {
  for (const symbol of symbols.value.values()) {
    symbolSelect(toggleSelectAll.value, symbol);
  }
});

// Functions
const renderChart = () => {
  document.getElementById('chart').innerHTML = '';

  chart.value = createChart(
    document.getElementById('chart'),
    getChartStyle(false),
  );

  // TODO: We should be able to figure out something better based up graphData
  candlestickSeries.value = chart.value.addCandlestickSeries({
    priceFormat: {
      type: 'price',
      precision: 8,
      minMove: 0.00000001,
    },
  });

  candlestickSeries.value.setData(graphData.value);
};

const tradingViewLogin = () => {
  if ((username.value === '' || password.value === '') && token.value === '') {
    return;
  }

  wsStore.send({
    category: 'tradingview_login',
    body: JSON.stringify({
      username: username.value,
      password: password.value,
      token: token.value,
    }),
  });
};

const tradingViewSymbolsFetching = () => {
  if (symbolSearch.value === '' && exchangeSearch.value === '') {
    return;
  }

  wsStore.send({
    category: 'tradingview_symbols_fetching',
    body: JSON.stringify({
      symbol: symbolSearch.value,
      exchange: exchangeSearch.value,
    }),
  });
};

const tradingViewSymbolFatFingerAnlaysis = () => {
  if (symbolsList.value.size === 0) {
    return;
  }

  class SymbolList {
    symbol: string;
    exchange: string;

    constructor(symbol: string, exchange: string) {
      this.symbol = symbol;
      this.exchange = exchange;
    }
  }

  const symbolsListNew: SymbolList[] = [];

  for (const symbol of symbolsList.value.values()) {
    symbolsListNew.push(new SymbolList(symbol.symbol, symbol.exchange));
  }

  wsStore.send({
    category: 'tradingview_symbols_fat_finger_analyse',
    body: JSON.stringify(symbolsListNew),
  });
};

const tradingViewSymbolDataFetching = (symbol: SymbolType) => {
  wsStore.send({
    category: 'tradingview_symbol_data_fetching',
    body: JSON.stringify({
      symbol: symbol.symbol,
      exchange: symbol.exchange,
    }),
  });
};

const symbolSelect = (checked: boolean, symbol: SymbolType) => {
  const key = `${symbol.exchange}:${symbol.symbol}`;

  if (checked) {
    symbolsList.value.set(key, symbol);
  } else {
    symbolsList.value.delete(key);
  }

  symbol.selected = checked;
};

const getSymbols = () => {
  if (!fatFingeredOnly.value) {
    return symbols.value.values();
  }

  const symbolsNew: SymbolType[] = [];

  for (const symbol of symbols.value.values()) {
    if (symbol.fatFingerErrorInfo || symbol.fatFingerInfo?.length === 0) {
      continue;
    }

    symbolsNew.push(symbol);
  }

  return symbolsNew;
};
</script>

<style></style>
