import type { User, MultiFactorUser } from 'firebase/auth';
import { generateClientId } from '@/utilities';

export class UserPasswordWrapper {
  public statusId: string;
  public categoryWs: string;
  public oldPassword: string;
  public newPassword: string;

  constructor(categoryWs: string, oldPassword: string, newPassword: string) {
    this.statusId = generateClientId();
    this.categoryWs = categoryWs;
    this.oldPassword = oldPassword;
    this.newPassword = newPassword;
  }
}

export class UserNonceWrapper {
  public statusId: string;
  public categoryWs: string;
  public oldNonce: string;
  public newNonce: string;

  constructor(categoryWs: string, oldNonce: string, newNonce: string) {
    this.statusId = generateClientId();
    this.categoryWs = categoryWs;
    this.oldNonce = oldNonce;
    this.newNonce = newNonce;
  }
}

export interface UserState {
  firebaseUser: null | User
  firebaseMultiFactorUser: MultiFactorUser
  token: string
  accountType: string
  passwordsTs: UserPasswordsTsType
  tradingSessionExpiry: number
}

export interface UserLoginBody {
  email: string
  password: string
}

export class UserPasswordsTsType {
  firestore: number;
  encrypt: number;
  nonce: number;
  trading: number;

  constructor(firestore: number, encrypt: number, nonce: number, trading: number) {
    this.firestore = firestore;
    this.encrypt = encrypt;
    this.nonce = nonce;
    this.trading = trading;
  }
}

export class UserType {
  public token: string;
  public accountType: string;
  public data: UserDataType;
  public passwordsTs: UserPasswordsTsType;
}

export class UserDataType {
  public displayName: string;
  public email: string;

  constructor(displayName: string, email: string) {
    this.displayName = displayName;
    this.email = email;
  }
}

export interface LogFileServerResp {
  logFile: string[];
}

export interface LogFileNamesServerResp {
  logsFileNames: string[];
}

export class NotificationType {
  exchangeName = '';
  accountId = '';
  category = '';
  type: NOTIFICATION_TYPE;
  timestamp = 0;
  message: string;

  constructor(type: NOTIFICATION_TYPE, message: string) {
    this.type = type;
    this.message = message;
  }
}

export class NotificationWrapper {
  public notification: NotificationType;
  public displayClass: string;

  constructor(notification: NotificationType, displayClass: string) {
    this.notification = notification;
    this.displayClass = displayClass;
  }
}

export enum NOTIFICATION_TYPE {
  DEFAULT = '',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum MESSAGE_TYPE {
  NONE = 'None',
  NOTIFICATION = 'Notification',
  ALERT = 'Alert'
}

export interface NotificationState {
  notifications: NotificationType[],
  drawerOpen: boolean
}

export interface WsStateType {
  ws: WsType
}

export interface WsType {
  conn: WebSocket | null;
  status: WS_STATUS;
  authorised: WS_AUTH;
  serverEncrypted: boolean;
  offlineMode: boolean;
  // Client may try to send messages to server before the connection has been successfully performed
  connBuffer: string[];
}

export enum WS_STATUS {
  DISCONNECTED = 'Disconnected',
  CONNECTING = 'Connecting',
  CONNECTED = 'Connected',
  CONNECTION_ERROR = 'Connection Error',
}

export enum WS_AUTH {
  YES = 'yes',
  NO = 'no',
  AUTHORSING = 'authorising',
  FAILED = 'failed',
}

export interface AccountTypeServerResp {
  accountType: string;
}

export interface TradingSessionResp {
  expiresAt: number;
}

export interface UserPasswordsTsTypeServer {
  firestore: number;
  encrypt: number;
  nonce: number;
  trading: number;
}

export interface PasswordsTsServerResp {
  passwordsTs: UserPasswordsTsTypeServer;
}
