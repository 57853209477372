<template>
  <div class="text-center">
    <div v-if="file.body.length > 0">
      <div class="text-left dark:bg-black">
        <MdPreview editor-id="preview-only" :model-value="file.body" :theme="theme" language="en-US" />
      </div>
    </div>

    <div v-if="orderedSelectedTags.length > 0" class="tags-container">
      <span v-for="(name, index) in orderedSelectedTags" :key="index" class="tag">
        {{ name }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue';
import { File } from '@/types/resources';
import { useUserSettingsStore } from '@/stores/user/settings';

// Lazy load
const MdPreview = defineAsyncComponent(() => import('md-editor-v3').then(module => module.MdPreview));

// Store
const userSettingsStore = useUserSettingsStore();

// Parent variables
const props = withDefaults(defineProps<{
  file: File,
}>(), {
  file: null,
});

// Computed
const orderedSelectedTags = computed<string[]>(() => {
  if (!props.file.tags) return [];
  // Create copy
  const tags = [...props.file.tags];
  return tags.sort();
});
const theme = computed(() => {
  const t = userSettingsStore.getTheme;
  return t.includes('dark') ? 'dark' : 'light';
});
</script>

<style lang="scss" scoped></style>
