<template>
  <div class="my-1">
    <CategoryTags />

    <div class="panel">
      <div class="panel__header" :class="{ 'panel__header--active': !collasped }">
        <h3 class="panel__header__title">
          {{ categoryTitle }} {{ fileType }} <span>{{ filesFilteredDisplayLen }} / {{ filesFiltered.length }}</span>
        </h3>
      </div>

      <div v-if="!collasped" class="panel__body vtable table-div">
        <div class="vtable-header" style="padding-left: 1px;">
          <div class="vtable-header__item vtable__size--m-20 vtable__size--20">Title</div>
          <div class="vtable-header__item vtable__size--m-60 vtable__size--60">Tags</div>
        </div>

        <div
          v-for="(_, i) in filesFilteredDisplayLen" :key="i" class="vtable__row vtable__row--selection"
          @click="goToContent(filesFiltered[i].id)"
        >
          <div class="vtable__row__item vtable__size--m-20 vtable__size--20 text-overflow">
            {{ filesFiltered[i].title }}
          </div>
          <div class="vtable__row__item vtable__size--m-60 vtable__size--60">
            <span v-for="(name, index) in filesFiltered[i].tags" :key="index" class="tag m-1">{{ name }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center">
      <button v-if="!fullyLoaded" class="button btn-margin" @click="loadMore()">Load More</button>
      <button v-else class="button btn-disabled btn-margin" disabled>Load More</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useCategoriesStore } from '@/stores/resources/categories';
import { useFilesStore } from '@/stores/resources/files';
import { File, Category } from '@/types/resources';
import CategoryTags from '@/components/resources/sidebar/CategoryTags.vue';

// Store
const categoriesStore = useCategoriesStore();
const filesStore = useFilesStore();
const route = useRoute();
const router = useRouter();

// Computed
const files = computed<Record<string, File>>(() => filesStore.files);
const tags = computed<Record<string, boolean>>(() => {
  const queryTags = route.query.tags ? String(route.query.tags).split(',') : [];
  const retTags: Record<string, boolean> = {};

  for(let i = 0; i < queryTags.length; i++) {
    const tag = queryTags[i];
    retTags[tag] = true;
  }

  return retTags;
});
const direction = computed(() => route.query.direction ? String(route.query.direction) : 'desc');
const categoryId = computed(() => route.params.categoryId ? String(route.params.categoryId) : '');
const fileType = computed(() => route.params.fileType ? String(route.params.fileType) : '');
const category = computed<Category> (() => categoriesStore.getCategory(categoryId.value));
const categoryTitle = computed(() => category.value ? category.value.title : '');
const filesFiltered = computed<File[]>(() => {
  const filesNew = [];
  for(const key in files.value) {
    const file = files.value[key];
    if (file.type != fileType.value) continue;

    if (Object.keys(tags.value).length == 0) {
      filesNew.push(file);
    } else {
      for(let i = 0; i < file.tags.length; i++) {
        const tag = file.tags[i];
        if (tag in tags.value) {
          filesNew.push(file);
          break;
        }
      }
    }
  }

  const sortedFiles = filesNew.sort((a, b) => {
    return direction.value === 'asc' ? a.created.localeCompare(b.created) : b.created.localeCompare(a.created);
  });

  return sortedFiles;
});
const filesFilteredDisplayLen = computed(() => Math.min(limit.value + offset.value, filesFiltered.value.length));
const fullyLoaded = computed(() => filesFiltered.value.length == filesFilteredDisplayLen.value);

// Variables
const collasped = ref(false);
const limit = ref(10);
const offset = ref(0);

// Watchers
watch(() => route.query, () => {
  offset.value = 0;
}, { deep: true });

watch(() => route.params.fileType, () => {
  offset.value = 0;
  getFiles();
}, { deep: true });

// Vue Lifecycle Functions
onMounted(() => {
  getFiles();
});

// Methods
const getFiles = () => {
  // Get files
  void filesStore.getFiles(categoryId.value, fileType.value);
};

const goToContent = (id: string): void => {
  // Use router.push to navigate to the same route with the added contentId query parameter
  void router.push({
    path: route.path, // Keep the current path
    query: {
      ...route.query, // Preserve existing query parameters
      fileId: id, // Add or update the contentId parameter
    },
  });
};

const loadMore = (): void => {
  offset.value += limit.value;
};
</script>

<style lang="scss" scoped>
.table-div {
  max-height: 450px;
  overflow-y: auto;
}
</style>
