<template>
  <div class="text-center">
    <div>
      <MdEditor v-model="body" :theme="theme" language="en-US" placeholder="Text here..." style="height: 300px" />
    </div>

    <div class="my-2">
      <h2 class="sub-heading mt-0 underline">Tags</h2>
      <SelectTags :file-tags="file.tags" @update-selected-tags="updateSelectedTags" />
    </div>

    <div v-if="!requestProcessing" class="my-2">
      <button class="btn-tw mx-2" type="button" @click="exitUpdateFile()">Cancel</button>
      <button v-if="file.type == FILE_TYPE.ARTICLE" class="btn-tw mx-2" type="button" @click="deleteFile()">
        Delete
      </button>
      <button class="btn-tw mx-2" type="button" @click="updateFile()">
        Update
      </button>
    </div>
    <div v-else>
      <p>
        Processing Request...
        <img class="spin-animation" :src="spinningCircle" />
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, ref, computed, defineAsyncComponent } from 'vue';
import SelectTags from '@/components/resources/content/SelectTags.vue';
import { File, FILE_TYPE } from '@/types/resources';
import { useFilesStore } from '@/stores/resources/files';
import { useUserSettingsStore } from '@/stores/user/settings';
import { useRoute } from 'vue-router';
import spinningCircle from '@/assets/spinning-circle.svg';

// Lazy load
const MdEditor = defineAsyncComponent(() => import('md-editor-v3').then(module => module.MdEditor));

// Store
const filesStore = useFilesStore();
const userSettingsStore = useUserSettingsStore();
const route = useRoute();
const emit = defineEmits(['updateEdit']);

// Parent variables
const props = withDefaults(defineProps<{
  file: File,
}>(), {
  file: null,
});

// Computed
const categoryId = computed(() => route.params.categoryId ? String(route.params.categoryId): '');
const fileType = computed(() => route.params.fileType ? String(route.params.fileType): '');
const fileId = computed(() => route.query.fileId ? String(route.query.fileId) : '');
const theme = computed(() => {
  const t = userSettingsStore.getTheme;
  return t.includes('dark') ? 'dark' : 'light';
});

// Variables
const body = ref('');
const selectedTags = ref<string[]>([]);
const requestProcessing = ref(false);

// Watchers
watch(() => props.file, (newFile) => {
  if (newFile) {
    body.value = props.file.body;
    selectedTags.value = props.file.tags;
  }
}, { deep: true, immediate: true });

// Vue Lifecycle Functions

// Functions
const updateFile = async (): Promise<void> => {
  const file = { ...props.file };
  file.body = body.value;
  file.tags = selectedTags.value;

  requestProcessing.value = true;
  await filesStore.updateFile(categoryId.value, fileType.value, fileId.value, file);
  requestProcessing.value = false;
  exitUpdateFile();
};

const exitUpdateFile = (): void => {
  emit('updateEdit');
};

const deleteFile = async (): Promise<void> => {
  const msg = `Are you sure you want to delete file ${props.file.id}?`,
    confirmation = window.confirm(msg);

  if (!confirmation) return;

  requestProcessing.value = true;
  await filesStore.deleteFile(categoryId.value, fileType.value, fileId.value);
  requestProcessing.value = false;
  exitUpdateFile();
};

const updateSelectedTags = (selectedTagsNew: string[]): void => {
  selectedTags.value = selectedTagsNew;
};
</script>

<style lang="scss"></style>
