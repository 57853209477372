import { OrderType } from '@/types/orders';
import { PositionType } from '@/types/positions';
import { PriceData } from '@/types/pricedata';


export class IgMarketState {
  public ordersOpen: Map<string, OrderType> = new Map<string, OrderType>();
  public ordersClosed: Map<string, OrderType> = new Map<string, OrderType>();
  public positionsOpen: Map<string, PositionType> = new Map<string, PositionType>();
  public positionsClosed: Map<string, PositionType> = new Map<string, PositionType>();

  public ToString(): string {
    let ret = 'Orders:\n\tOpen:\n';

    for (const [id, order] of this.ordersOpen) {
      ret += `\t\t${id}: ${order.ToString()}\n`;
    }

    ret += '\tClosed:\n';

    for (const [id, order] of this.ordersClosed) {
      ret += `\t\t${id}: ${order.ToString()}\n`;
    }

    ret += 'Positions:\n\tOpen:\n';

    for (const [id, position] of this.positionsOpen) {
      ret += `\t\t${id}: ${position.ToString()}\n`;
    }

    ret += '\tClosed:\n';

    for (const [id, position] of this.positionsClosed) {
      ret += `\t\t${id}: ${position.ToString()}\n`;
    }

    return ret;
  }
}

export interface IgState {
  priceData: PriceData;
}
