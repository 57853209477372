import { defineStore } from 'pinia';
import { NotificationState, NOTIFICATION_TYPE, NotificationType } from '@/types/user';


export const createNotification = (msg: string, type: NOTIFICATION_TYPE): void => {
  const notificationStore = useNotificationsStore();
  const notification = new NotificationType(type, msg);
  notificationStore.addNotification(notification);
};

export const useNotificationsStore = defineStore('notifications', {
  state: (): NotificationState => ({
    notifications: [],
    drawerOpen: localStorage.getItem('drawerOpen') ? localStorage.getItem('drawerOpen') === 'true' : false,
  }),
  actions: {
    addNotification(notification: NotificationType) {
      if (!notification.timestamp) {
        notification.timestamp = Date.now();
      }

      this.notifications.push(notification);
      this.notifications.sort((a: NotificationType, b: NotificationType) => a.timestamp - b.timestamp);
    },
    toggleDrawerOpen() {
      this.drawerOpen = !this.drawerOpen;
      localStorage.setItem('drawerOpen', String(this.drawerOpen));
    },
  },
  getters: {
    getNotifications: (state): Array<NotificationType> => state.notifications,
    getDrawerOpen: (state) => state.drawerOpen,
  },
});
