import { useUserStore } from '@/stores/user/user';
import { auth } from '@/fire';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  getMultiFactorResolver,
  TotpMultiFactorGenerator,
  TotpSecret,
  UserCredential,
  MultiFactorError,
  signInWithEmailAndPassword,
  signOut,
  updatePassword as firebaseUpdatePassword,
} from 'firebase/auth';

class TwoAuthQrCodeType {
  totpSecret: TotpSecret;
  url: string;

  constructor(totpSecret: TotpSecret, url: string) {
    this.totpSecret = totpSecret;
    this.url = url;
  }
}

class FirebaseAuth {
  private authReadyPromise: Promise<void>;
  private resolveAuthReady: () => void;

  constructor() {
    this.authReadyPromise = new Promise(resolve => {
      this.resolveAuthReady = resolve;
    });

    if (import.meta.env.VITE_APP_OFFLINE) {
      this.resolveAuthReady();
      return;
    }

    auth.onAuthStateChanged(user => {
      void (async () => {
        try {
          const userStore = useUserStore();
          if (user) {
            const token = await user.getIdToken();
            userStore.setUser(user);
            userStore.setUserToken(token);
          } else {
            userStore.setUser(null);
            userStore.setUserToken('');
          }
          this.resolveAuthReady();
        } catch (error) {
          // Handle any errors that occur in the async function
          console.error('Error in onAuthStateChanged handler:', error);
        }
      })();
    });
  }

  setUser(): void {
    const userStore = useUserStore();
    const user = auth.currentUser || null;
    userStore.setUser(user);
  }

  async generate2AuthQrCode(password: string): Promise<TwoAuthQrCodeType> {
    const userStore = useUserStore();
    const user = userStore.firebaseUser;

    // Check if the user is already authenticated
    if (!user) {
      throw new Error('User is not authenticated.');
    }

    const email = user.email;
    if (!email) {
      throw new Error('User email is not available.');
    }

    const credential = EmailAuthProvider.credential(email, password);
    // Reauthenticate the user with the provided credentials
    await reauthenticateWithCredential(user, credential);

    const multiFactorSession = await userStore.firebaseMultiFactorUser.getSession();
    const totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
    const url = totpSecret.generateQrCodeUrl(user.displayName || 'User', 'TradingClient');

    return new TwoAuthQrCodeType(totpSecret, url);
  }

  async remove2Auth(password: string, verificationCode: string) {
    const userStore = useUserStore();
    const user = userStore.firebaseUser;
    if (!user) {
      throw new Error('User is not signed in.');
    }

    const email = user.email;
    if (!email) {
      throw new Error('User email is not available.');
    }

    const credential = EmailAuthProvider.credential(email, password);

    // Reauthenticate the user with the provided credentials
    try {
      await reauthenticateWithCredential(user, credential);
    } catch (error) {
      const typedError = error as MultiFactorError;
      if (typedError.code === 'auth/multi-factor-auth-required') {
        // Handle the multi-factor authentication requirement
        const resolver = getMultiFactorResolver(auth, typedError);

        const totpHint = resolver.hints.find((hint) => hint.factorId === TotpMultiFactorGenerator.FACTOR_ID);

        if (!totpHint) {
          throw new Error('TOTP 2FA type not found');
        }

        // Create a TOTP assertion using the verification code
        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
          totpHint.uid,
          verificationCode,
        );

        // Complete the sign-in with the TOTP assertion
        await resolver.resolveSignIn(multiFactorAssertion);

        const multiFactorUser = userStore.firebaseMultiFactorUser;

        if (multiFactorUser == null) {
          throw new Error('Error no multi factor user');
        }

        const totpFactor = multiFactorUser.enrolledFactors.find(
          (factor) => factor.factorId === TotpMultiFactorGenerator.FACTOR_ID,
        );

        if (!totpFactor) {
          throw new Error('TOTP 2FA type not found');
        }

        await multiFactorUser.unenroll(totpFactor);
      } else {
        console.error(error);
      }
    }
  }

  async add2Auth(totpSecret: TotpSecret, verificationCode: string): Promise<void> {
    const userStore = useUserStore();
    const user = userStore.firebaseUser;

    // Check if the user is already authenticated
    if (!user) {
      throw new Error('User is not authenticated.');
    }

    const displayName = user.displayName || 'User';

    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(totpSecret, verificationCode);

    // Finalize the enrollment.
    await userStore.firebaseMultiFactorUser.enroll(multiFactorAssertion, displayName);
  }

  async login2Auth( error: MultiFactorError, verificationCode: string): Promise<UserCredential> {
    const resolver = getMultiFactorResolver(auth, error);

    const totpHint = resolver.hints.find((hint) => hint.factorId === TotpMultiFactorGenerator.FACTOR_ID);

    if (!totpHint) {
      throw new Error('TOTP 2FA type not found');
    }

    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(totpHint.uid, verificationCode);

    return await resolver.resolveSignIn(multiFactorAssertion);
  }

  login(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth, email, password);
  }

  logout(): Promise<void> {
    return signOut(auth);
  }

  async updatePassword(oldPassword: string, newPassword: string, verificationCode: string): Promise<void> {
    const userStore = useUserStore();
    const user = userStore.firebaseUser;

    if (!user) {
      throw new Error('No authenticated user found.');
    }

    const email = user.email;
    if (!email) {
      throw new Error('Unable to retrieve user email for re-authentication.');
    }

    const credential = EmailAuthProvider.credential(email, oldPassword);

    try {
      // Reauthenticate the user with their credential
      await reauthenticateWithCredential(user, credential);
    } catch (error) {
      const typedError = error as MultiFactorError;

      if (typedError.code === 'auth/multi-factor-auth-required') {
        // Handle the multi-factor authentication requirement
        const resolver = getMultiFactorResolver(auth, typedError);

        const totpHintIndex = resolver.hints.findIndex(
          (hint) => hint.factorId === TotpMultiFactorGenerator.FACTOR_ID,
        );

        if (totpHintIndex === -1) {
          throw new Error('TOTP 2FA type not found');
        }

        // Get the TOTP hint
        const totpHint = resolver.hints[totpHintIndex];

        // Create a TOTP assertion using the verification code
        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(totpHint.uid, verificationCode);

        // Complete the sign-in with the TOTP assertion
        await resolver.resolveSignIn(multiFactorAssertion);
      } else {
        // Rethrow any other errors
        throw error;
      }
    }

    // Update the user's password
    await firebaseUpdatePassword(user, newPassword);
  }

  // Call this function in your route guards or during app initialization
  // to make sure auth state is known before proceeding
  waitUntilAuthReady(): Promise<void> {
    return this.authReadyPromise;
  }
}

export const Auth = new FirebaseAuth();
