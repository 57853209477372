<template>
  <div class="container-fluid sub-content">
    <router-view />
  </div>
</template>

<script setup lang="ts">
</script>

<style>
</style>
