<script setup lang="ts">
import { inject } from 'vue';
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
});
const selectedTitle = inject('selectedTitle');
</script>

<template>
  <div v-show="selectedTitle === props.title" class="tabs__content">
    <slot />
  </div>
</template>
