import { defineStore } from 'pinia';
import { PriceType, PricesState, PricesServerResp } from '@/types/prices';

export const usePricesStore = defineStore('prices', {
  state: (): PricesState => ({
    prices: {},
  }),
  actions: {
    setAllPrices(body: PricesServerResp) {
      if (!(body.exchangeName in this.prices)) {
        this.prices[body.exchangeName] = {};
      }

      this.prices[body.exchangeName][body.exchangeType] = {};
      this.setPrices(body);
    },
    setPrices(body: PricesServerResp) {
      for (const symbol in body.prices) {
        if (!(body.exchangeName in this.prices)) {
          this.prices[body.exchangeName] = {};
        }

        if (!(body.exchangeType in this.prices[body.exchangeName])) {
          this.prices[body.exchangeName][body.exchangeType] = {};
        }

        const price = body.prices[symbol];
        const newPrice = new PriceType(
          price.symbol,
          price.bid,
          price.ask,
          price.lastPrice,
          price.markPrice,
          price.baseVolume,
          price.quoteVolume,
          price.exchangeType,
        );

        this.prices[body.exchangeName][body.exchangeType][price.symbol] = newPrice;
      }
    },
  },
  getters: {
    getPrices: (state) => () => state.prices,
    getSymbolPrice: (state) => (exchangeName: string, exchangeType: string, symbol: string) => {
      return state.prices?.[exchangeName]?.[exchangeType]?.[symbol];
    },
  },
});
