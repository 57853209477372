function getServerAddress() {
  const address = import.meta.env.VITE_APP_ADDRESS ? String(import.meta.env.VITE_APP_ADDRESS) : '',
    port = import.meta.env.VITE_SERVER_APP_PORT ? String(import.meta.env.VITE_SERVER_APP_PORT) : '';
  return port != '' ? `localhost:${port}` : address;
}

export default {
  serverAppHttpPrefix: (): string => String(import.meta.env.VITE_APP_HTTP_PREFIX),
  serverAppWsPrefix: (): string => String(import.meta.env.VITE_APP_WS_PREFIX),
  serverAppAddress: (): string => getServerAddress(),
  apiKey: (): string => String(import.meta.env.VITE_APP_FS_API_KEY),
  authDomain: (): string => String(import.meta.env.VITE_APP_FS_AUTH_DOMAIN),
  projectId: (): string => String(import.meta.env.VITE_APP_FS_PROJECT_ID),
  storageBucket: (): string => String(import.meta.env.VITE_APP_FS_STORAGE_BUCKET),
  messagingSenderId: (): string => String(import.meta.env.VITE_APP_FS_MESSAGING_SENDER_ID),
  appId: (): string => String(import.meta.env.VITE_APP_FS_APP_ID),
  apiEndpoint: (): string => `${String(import.meta.env.VITE_APP_HTTP_PREFIX)}://` + `${getServerAddress()}/api/v1`,
};
