import { defineStore } from 'pinia';
import { TranscriptionsState, HitsMeta, Hit, TranscriptionsServerResp } from '@/types/resources';
import Config from '@/config';
import { createRequestData } from '@/utilities';
import { useUserStore } from '@/stores/user/user';
import { createNotification } from '@/stores/user/notifications';
import { NOTIFICATION_TYPE } from '@/types/user';
import { RespError } from '@/types/general';

export const useTranscriptionsStore = defineStore('transcriptions', {
  state: (): TranscriptionsState => ({
    hitsMeta: {
      totalHits: 0,
      limit: 100,
      offset: 0,
    },
    hits: [],
    totalHits: 0,
  }),
  actions: {
    // Seatch definitions https://blevesearch.com/docs/Query/
    async getTranscriptions(
      categoryId: string, fileType: string, query: string, searchType: string, limit: number, offset: number,
    ) {
      try {
        const searchQuery = `query=${query}&searchType=${searchType}&limit=${limit}&offset=${offset}`;
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/files/${categoryId}/${fileType}/transcriptions?${searchQuery}`,
          createRequestData('GET', useUserStore().token, null),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        const resp = await response.json() as TranscriptionsServerResp;
        this.addHits(resp);
        this.addHitsMeta(resp);
        createNotification('success getTranscriptions', NOTIFICATION_TYPE.SUCCESS);
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error getTranscriptions ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    resetHits() {
      this.hits = [];
    },
    addHits(resp: TranscriptionsServerResp) {
      const hits: Hit[] = [];

      resp.transcriptions_search.hits.forEach(hit => {
        hits.push(new Hit(hit.id, hit.index, hit.score, hit.fields, hit.fragments, hit.sort));
      });

      this.setHits(hits);
    },
    addHitsMeta(resp: TranscriptionsServerResp) {
      const search = resp.transcriptions_search,
        hitsMeta = new HitsMeta(search.total_hits, search.request.size, search.request.from);

      this.setHitsMeta(hitsMeta);
    },
    setHits(body: Hit[]) {
      this.hits.push(...body);
    },
    setHitsMeta(body: HitsMeta) {
      this.hitsMeta = body;
    },
  },
  getters: {
    getHits: (state): Hit[] => {
      return state.hits;
    },
  },
});
