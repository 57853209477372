<template>
  <div>
    <div v-if="file">
      <div class="grid">
        <div class="col col-remaining border border-gray-600">
          <div v-if="file.type == FILE_TYPE.VIDEO" class="video-container">
            <iframe
              id="player" class="video rounded" target="_parent" :src="webViewLink" allowfullscreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            />
          </div>
          <div>
            <div class="flex flex-row justify-between">
              <h2 class="file-title">{{ file.title }}</h2>

              <i class="ti-pencil-alt table-icon-tw hand text-2xl p-2" @click="updateEdit()" />
            </div>
            <ViewFile v-if="!edit" :file="file" />
            <EditFile v-else :file="file" @update-edit="updateEdit()" />
          </div>
        </div>

        <CommentSection v-if="file.type == FILE_TYPE.VIDEO" class="col col-2 border border-gray-600" />
      </div>
    </div>
    <div v-else>
      <h1 class="text-center m-0">
        Post doesn't exist
      </h1>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useFilesStore } from '@/stores/resources/files';
import { useRoute } from 'vue-router';
import { useCommentsStore } from '@/stores/resources/comments';
import CommentSection from '@/components/resources/content/CommentSection.vue';
import ViewFile from '@/components/resources/content/ViewFile.vue';
import EditFile from '@/components/resources/content/EditFile.vue';
import { File, FILE_TYPE } from '@/types/resources';

// Store
const commentsStore = useCommentsStore();
const filesStore = useFilesStore();
const route = useRoute();

// Computed
const webViewLink = computed(() => {
  let baseUrl = `https://www.youtube.com/embed/${fileId.value}?rel=0&cc_load_policy=1`;
  if ('start' in route.query) baseUrl += `&start=${String(route.query.start)}`;
  return baseUrl;
});
const categoryId = computed(() => route.params.categoryId ? String(route.params.categoryId) : '');
const fileType = computed(() => route.params.fileType ? String(route.params.fileType) : '');
const fileId = computed(() => route.query.fileId ? String(route.query.fileId) : '');
const file = computed<File>(() => filesStore.files[fileId.value]);

// Watch
watch(fileId, async (newFileId) => {
  if (newFileId != '') {
    commentsStore.reset();
    await filesStore.getFile(categoryId.value, fileType.value, fileId.value);

    if (file.value.type == FILE_TYPE.VIDEO) {
      await commentsStore.getComments(categoryId.value, fileType.value, fileId.value);
    }
  }
}, { immediate: true });

// Variables
const edit = ref(false);

// Vue Lifecycle Functions

// Methods
const updateEdit = () => {
  edit.value = !edit.value;
};
</script>

<style lang="scss" scoped>
.edit-icon {
  font-size: 30px;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio (9/16 * 100) */
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.file-title {
  font-size: 18px;
  font-weight: bold;
  color: #cccccc; /* White text */
  padding: 10px;
  margin-bottom: 0px;
  text-align: left;
}
</style>
