import { EXCHANGE_TYPE, EXCHANGE_NAME } from '@/types/exchange';
import { InstrumentType } from '@/types/instruments';
import { ORDER_STRATEGY_TYPE, OrderStrategyType } from '@/types/order_strategies';
import { SIDE } from '@/types/account';
import { RequestDataI } from '@/types/general';
import { NOTIFICATION_TYPE } from '@/types/user';
import { ErrorServerResp } from '@/types/general';
import { createNotification } from '@/stores/user/notifications';

// Static version is required when importing roundValueForAsset() into Vue HTML templates
function staticRoundNumber(n: number, dp: number) {
  const shiftedNumber = +`${Number(n).toFixed(20)}e+${dp}`;
  const roundedShiftedNumber = Math.round(shiftedNumber);
  const result = +`${roundedShiftedNumber}e-${dp}`;
  return result;
}

function staticAlignNumber(n: number, value: number) {
  const precision = 10; // 10 is a bit arbitrary, but prevents issues like: 0.9 % 0.3
  const remainder = Number((n % value).toFixed(precision));

  if (remainder == 0) { // Already aligned
    return n;
  }

  return Number((n - remainder).toFixed(precision));
}

export function tidyNumber(n: string): string {
  if (!n.includes('.')) {
    return n;
  }

  return n.replace(/\.?0+$/, '');
}

export const formatNumber = (n: string): string => {
  return new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 15 }).format(Number(n));
};

export const formatTimeRemaining = (seconds: number): string => {
  let output = '';

  seconds = Math.round(seconds);

  if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);

    output = `${minutes}m `;
    seconds -= minutes * 60;
  }

  output += `${seconds}s`;

  return output;
};

export const roundNumber = (n: number, dp: number): number => staticRoundNumber(n, dp);

export const alignNumber = (n: number, value: number): number => staticAlignNumber(n, value);

export const volumeFormatter = (n: number, precision: number): string => {
  if (n < 1_000) {
    return String(n);
  }

  if (n < 1_000_000) {
    return String(staticRoundNumber(n / 1_000, precision)) + 'K';
  }

  if (n < 1_000_000_000) {
    return String(staticRoundNumber(n / 1_000_000, precision)) + 'M';
  }

  if (n < 1_000_000_000_000) {
    return String(staticRoundNumber(n / 1_000_000_000, precision)) + 'B';
  }

  return String(staticRoundNumber(n / 1_000_000_000_000, precision)) + 'T';
};

export const currencyFormatter = (n: number, precision: number, currency: string): string => {
  const value = staticRoundNumber(n, precision);

  if (value < 0) {
    return `-${currency}${Math.abs(value)}`;
  }

  return `${currency}${value}`;
};

export const randomId = (): string => { // Generates a random string ID that is 10 characters long
  const ran = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

  return ran() + ran() + ran() + ran() + ran() + ran() + ran() + ran() + ran() + ran();
};

export const multiplyNumericStrings = (n1: string, n2: string): string => {
  const sum = +n1 * +n2;
  return String(sum);
};

export const addNumericStrings = (n1: string, n2: string): string => {
  const sum = +n1 + +n2;
  return String(sum);
};

export const subtractNumericStrings = (n1: string, n2: string): string => {
  const sum = +n1 - +n2;
  return String(sum);
};

export const divideNumericStrings = (n1: string, n2: string): string => {
  const sum = +n1 / +n2;
  return String(sum);
};

export const lessThanNumericStrings = (n1: string, n2: string): boolean => {
  return +n1 < +n2;
};

export const greaterThanNumericStrings = (n1: string, n2: string): boolean => {
  return +n1 > +n2;
};

export const precisionNumericString = (num: string, precision: string): string => {
  const n = +num,
    p = +precision;
  return n.toFixed(p);
};

export const sideBg = (side: string): string => {
  return side === 'buy' ? 'greenBg' : 'redBg';
};

function capitaliseFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const getBitmexMargin = (
  instrument: InstrumentType, quantity: string, limitPrice: string, marginFixed: string,
): string => {
  // 0 is cross
  const leverage = instrument.leverage == '0' ? instrument.maxLeverage.toString() : instrument.leverage,
    // TODO: this is not fully correct. BTC margin can be more decimals
    valueMultiplier = instrument.valueMultiplier,
    takerFee = instrument.takerFee;

  let orderValue;
  /*
    Inverse: multiplier / price * contract quantity
    Non-inverse: multiplier * price * contract quantity
  */
  if (instrument.isInverse) {
    const d = divideNumericStrings(valueMultiplier, limitPrice);
    orderValue = multiplyNumericStrings(d, quantity);
  } else if (instrument.isQuanto) {
    const m = multiplyNumericStrings(valueMultiplier, limitPrice);
    orderValue = multiplyNumericStrings(m, quantity);
  } else {
    orderValue = multiplyNumericStrings(limitPrice, quantity);
  }

  const d = divideNumericStrings('1', leverage),
    m1 = multiplyNumericStrings(d, orderValue),
    m2 = multiplyNumericStrings(orderValue, '2'),
    m3 = multiplyNumericStrings(m2, takerFee);

  const margin = addNumericStrings(m1, m3);
  return precisionNumericString(margin, marginFixed);
};

const getBitfinexMargin = (quantity: string, limitPrice: string, marginFixed: string, leverage: string): string => {
  const m = multiplyNumericStrings(limitPrice, quantity),
    margin = divideNumericStrings(m, leverage);

  return precisionNumericString(margin, marginFixed);
};

// Calculate current cost via quantity / limit price
export const calculateMargin = (
  instrument: InstrumentType, exchangeName: string, exchangeType: string, quantity: string,
  limitPrice: string, marginFixed: string, leverage: string, side: string,
): string => {
  if (exchangeType === EXCHANGE_TYPE.SPOT && side === SIDE.SELL) {
    return quantity;
  }

  if (exchangeType == EXCHANGE_TYPE.DERIVATIVES) {
    switch (exchangeName) {
    case EXCHANGE_NAME.BITMEX:
      return getBitmexMargin(instrument, quantity, limitPrice, marginFixed);
    case EXCHANGE_NAME.BITFINEX:
      return getBitfinexMargin(quantity, limitPrice, marginFixed, leverage);
    }
  }

  const sum = multiplyNumericStrings(quantity, limitPrice),
    margin = precisionNumericString(sum, marginFixed);

  return margin;
};

// Calculate current quantity via cost / limit price
export const calculateQuantity = (
  cost: string, price: string, quantityFixed: string, exchangeType: string, side: string,
): string => {
  if (exchangeType === EXCHANGE_TYPE.SPOT && side === SIDE.SELL) {
    return cost;
  }

  const sum = divideNumericStrings(cost, price),
    quantity = precisionNumericString(sum, quantityFixed);

  return quantity;
};

export const getDistance = (orderPriceStr: string, priceStr: string, fixed: number): number => {
  const orderPrice = parseFloat(orderPriceStr),
    price = parseFloat(priceStr);

  if (orderPrice == 0 || price == 0) {
    return null;
  }

  const sum = (orderPrice / price) * 100;
  return parseFloat(Math.abs(sum - 100).toFixed(fixed));
};

export const generateClientId = (): string => {
  return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, c =>
    (Number(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> Number(c) / 4).toString(16),
  );
};

export const validOrderStrategy = (strategy: OrderStrategyType): boolean => {
  if (strategy.id != '') {
    return true;
  }

  switch (strategy.type) {
  case ORDER_STRATEGY_TYPE.NONE:
    return true;
    break;
  case ORDER_STRATEGY_TYPE.PUSH_AWAY:
    if (strategy.pushAwayPercent && strategy.pushAwayDelta && strategy.moveBy) {
      if (!isNaNStr(strategy.pushAwayPercent) && !isNaNStr(strategy.pushAwayDelta)) {
        return true;
      }
    }
    break;
  case ORDER_STRATEGY_TYPE.FRONTRUN_PRICE:
    if (strategy.frontrunLowBound && strategy.frontrunHighBound &&
      strategy.makeupPercentage && strategy.side && strategy.moveBy
    ) {
      if (
        !isNaNStr(strategy.frontrunLowBound) && !isNaNStr(strategy.frontrunHighBound) &&
        !isNaNStr(strategy.makeupPercentage)
      ) {
        switch (strategy.side) {
        case SIDE.BUY:
          if (greaterThanNumericStrings(strategy.frontrunLowBound, strategy.frontrunHighBound)) return true;
          break;
        case SIDE.SELL:
          if (lessThanNumericStrings(strategy.frontrunLowBound, strategy.frontrunHighBound)) return true;
          break;
        }
      }
    }
    break;
  case ORDER_STRATEGY_TYPE.FRONTRUN_PERCENT:
    if (
      strategy.frontrunLowBound && strategy.frontrunHighBound &&
      strategy.makeupPercentage && strategy.moveBy
    ) {
      if (!isNaNStr(strategy.frontrunLowBound) && !isNaNStr(strategy.makeupPercentage)) {
        return true;
      }
    }
    break;
  }

  return false;
};

const isNaNStr = (num: string): boolean => {
  return isNaN(Number(num));
};

export const convertMsToTime = (duration: number): string => {
  const seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const hoursStr = (hours < 10) ? `0${hours}` : hours,
    minutesStr = (minutes < 10) ? `0${minutes}` : minutes,
    secondsStr = (seconds < 10) ? `0${seconds}` : seconds;

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
};

export const splitLastOccurrence = (str: string, substring: string): string[] => {
  const lastIndex = str.lastIndexOf(substring),
    before = str.slice(0, lastIndex),
    after = str.slice(lastIndex + 1);

  return [before, after];
};

export const checkPwExpired = (timestampSeconds: number): boolean => {
  if (timestampSeconds == 0) {
    // If it is not set, then don't consider it expired
    return false;
  }

  const timestamp = timestampSeconds * 1000;
  // Convert the given timestamp to a Date object (assuming the timestamp is in milliseconds)
  const timestampDate = new Date(timestamp);
  // Get the current date and time
  const currentDate = new Date();
  // Calculate the date 90 days ago
  const ninetyDaysAgo = new Date(currentDate.getTime() - (90 * 24 * 60 * 60 * 1000));
  // Check if the timestamp is older than 90 days
  return timestampDate < ninetyDaysAgo;
};

export const formatDateTime = (epoch: number, withMs = false): string => {
  const date = new Date(epoch);
  const month = `${date.getUTCMonth() + 1}`.padStart(2, '0');
  const day = `${date.getUTCDate()}`.padStart(2, '0');
  let retval = `${date.getUTCFullYear()}/${month}/${day} ` + date.toLocaleTimeString('en-GB');

  if (withMs) {
    retval += `.${epoch % 1000}`.padEnd(4, '0');
  }

  return retval;
};

const createDefaultHeaders = (token: string): Record < string, string > => {
  return {
    'Content-Type': 'application/json',
    'Authorization': `bearer ${token}`,
  };
};

// Ignore linting as any is valid
// eslint-disable-next-line
export const createRequestData = (method: string, token: string, body: any): RequestInit => {
  const headers = createDefaultHeaders(token);
  const requestData: RequestDataI = {
    method: method,
    headers: headers,
  };

  if (body) {
    requestData['body'] = JSON.stringify(body);
  }

  return requestData as RequestInit;
};

export function performHttpRequest(
  endpoint: string, requestInfo: RequestInit, updateType: string, entity: string,
): Promise<string> {
  return fetch(endpoint, requestInfo).then(response => {
    // Only resolve the outer promise once the inner promise has resolved
    return response.text().then(jsonResp => {
      if (!response.ok) {
        const serverResp = JSON.parse(jsonResp) as ErrorServerResp;

        return Promise.reject(serverResp.error || `HTTP error: ${jsonResp}`);
      }

      createNotification(`${capitaliseFirstLetter(entity)} successfully ${updateType}d`, NOTIFICATION_TYPE.SUCCESS);

      return Promise.resolve(jsonResp);
    }).catch(err => {
      return Promise.reject(err);
    });
  }).catch(err => {
    createNotification(`Failed to ${updateType} ${entity}. Error: ${String(err)}`, NOTIFICATION_TYPE.ERROR);

    return Promise.reject(err);
  });
}
