<template>
  <div>
    <div>
      <input v-model="newTag" class="vtable-header__item__input-wrap__input" placeholder="Add Tag..." />
      <i class="ti-plus p-4 hand" @click="addTag()" />
    </div>

    <div class="tags-container">
      <div v-for="(name, index) in selectedTags" :key="index" class="tag" @click="removeTag(index)">
        {{ name }}
        <i class="ti-close" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const emit = defineEmits(['updateSelectedTags']);

// Parent variables
const props = withDefaults(defineProps<{
  fileTags: string[],
}>(), {
  fileTags: null,
});

// Variables
const selectedTags = ref<string[]>([]);
const newTag = ref('');

// Vue Lifecycle Functions
onMounted(() => {
  selectedTags.value.push(...props.fileTags);
});

// Functions
const addTag = (): void => {
  selectedTags.value.push(newTag.value);
  newTag.value = '';
  updateSelectedTags();
};

const removeTag = (index: number): void => {
  selectedTags.value.splice(index, 1);
  updateSelectedTags();
};

const updateSelectedTags = (): void => {
  emit('updateSelectedTags', selectedTags.value);
};
</script>


<style lang="scss" scoped>
.edit-tag:hover {
  border-radius: 1em;
}
</style>
