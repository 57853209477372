import { defineStore } from 'pinia';
import { EXCHANGE_NAME } from '@/types/exchange';
import { useUserSettingsStore } from '@/stores/user/settings';
import { useWebSocketStore } from '@/stores/user/ws';
import { SIDE } from '@/types/account';
import { ChosenSymbol } from '@/types/settings';
import {
  OrderbookLiquidity, OrdersbooksState, OrderbookType, OrderbookRowChange, OrderbookServerResp,
  OrderbookChangesServerResp, OrderbookTypeServer, OrderbookLiquidityServerResp,
} from '@/types/orderbooks';

function convertOrderbookTypeServer(
  orderbookServer: OrderbookTypeServer, exchangeName: string, exchangeType: string, symbol: string,
): OrderbookType {
  if (exchangeName === EXCHANGE_NAME.BITFINEX) {
    symbol = symbol.substring(1);
  }

  const orderbook = new OrderbookType(
    exchangeName,
    exchangeType,
    symbol,
    true,
    orderbookServer.incompleteBuys,
    orderbookServer.incompleteSells,
  );

  for (const [price, quantity] of Object.entries(orderbookServer.buys)) {
    orderbook.setBuy(price, quantity);
  }

  for (const [price, quantity] of Object.entries(orderbookServer.sells)) {
    orderbook.setSell(price, quantity);
  }

  return orderbook;
}


export const useOrderbooksStore = defineStore('orderbooks', {
  state: (): OrdersbooksState => ({
    orderbooks: {}, // Full orderbooks
    orderbooksLiquidity: {}, // Abstracted orderbooks (for bulk liquidity checks)
  }),
  actions: {
    setOrderbookLiquidity(body: OrderbookLiquidityServerResp) {
      const liquidity = new OrderbookLiquidity(
        body.exchangeName, body.exchangeType, body.symbol, body.consistentSymbol, body.buysTotal, body.sellsTotal,
      );

      this.orderbooksLiquidity[liquidity.exchangeName + liquidity.exchangeType + liquidity.symbol] = liquidity;
    },
    addRealtimeOrderbooks(
      body: {
        exchangeName: string,
        exchangeType: string,
        realtimeOrderbooks: Record<string, OrderbookType>
      },
    ) {
      for (const symbol in body.realtimeOrderbooks) {
        const orderbook = body.realtimeOrderbooks[symbol];
        const newOrderbook = new OrderbookType(
          body.exchangeName,
          body.exchangeType,
          symbol,
          true,
          orderbook.incompleteBuys,
          orderbook.incompleteSells,
        );

        for (const [price, quantity] of Object.entries(orderbook.buys)) {
          newOrderbook.setBuy(price, quantity.quantity);
        }

        for (const [price, quantity] of Object.entries(orderbook.sells)) {
          newOrderbook.setSell(price, quantity.quantity);
        }

        this.setOrderbookState(newOrderbook);
      }
    },
    setOrderbook(body: OrderbookServerResp) {
      const newOrderbookType = convertOrderbookTypeServer(
        body.orderbook, body.exchangeName, body.exchangeType, body.symbol,
      );
      this.setOrderbookState(newOrderbookType);
    },
    setOrderbookState(orderbook: OrderbookType) {
      if (this.orderbooks[orderbook.exchangeName] === undefined) {
        this.orderbooks[orderbook.exchangeName] = {};
      }

      if (this.orderbooks[orderbook.exchangeName][orderbook.exchangeType] === undefined) {
        this.orderbooks[orderbook.exchangeName][orderbook.exchangeType] = {};
      }

      this.orderbooks[orderbook.exchangeName][orderbook.exchangeType][orderbook.symbol] = orderbook;
    },
    deleteOrderbook(body: OrderbookServerResp) {
      if (this.orderbooks?.[body.exchangeName]?.[body.exchangeType]?.[body.symbol] !== undefined) {
        delete this.orderbooks[body.exchangeName][body.exchangeType][body.symbol];
      } else {
        const wsStore = useWebSocketStore();
        wsStore.send({
          category: 'unsubscribe_from_symbol',
          body: JSON.stringify({
            exchangeName: body.exchangeName,
            exchangeType: body.exchangeType,
            symbol: body.symbol,
          }),
        });
      }
    },
    changes(body: OrderbookChangesServerResp) {
      const settingsStore = useUserSettingsStore();
      const orderbook = this.orderbooks?.[body.exchangeName]?.[body.exchangeType]?.[body.symbol];

      if (orderbook !== undefined) {
        const orderbookRowChanges: OrderbookRowChange[] = [];

        for (const change of body.updates) {
          let chosenAssetQuantity = '0';

          if (change.quantity !== '0') {
            chosenAssetQuantity = String(settingsStore.convertSymbolQuantityToChosenAsset(
              parseFloat(change.quantity),
              parseFloat(change.price),
              new ChosenSymbol(body.exchangeName, body.exchangeType, body.symbol),
            ));
          }

          const newChange = new OrderbookRowChange(change.side, change.price, change.quantity, chosenAssetQuantity);

          orderbookRowChanges.push(newChange);
        }

        this.setOrderbookValues({ orderbook, changes: orderbookRowChanges });
      } else {
        const wsStore = useWebSocketStore();
        wsStore.send({
          category: 'unsubscribe_from_symbol',
          body: JSON.stringify({
            exchangeName: body.exchangeName,
            exchangeType: body.exchangeType,
            symbol: body.symbol,
          }),
        });
      }
    },
    setOrderbookValues({ orderbook, changes }: { orderbook: OrderbookType, changes: OrderbookRowChange[] }) {
      for (const { side, price, quantity, chosenAssetQuantity } of changes) {
        let rowChange: OrderbookRowChange = null;

        if (side === SIDE.BUY) {
          rowChange = orderbook.setBuy(price, quantity);
        } else {
          rowChange = orderbook.setSell(price, quantity);
        }

        if (!orderbook.recentChanges.has(rowChange.price)) {
          orderbook.recentChanges.set(
            rowChange.price,
            rowChange,
          );
        }

        // Rather than setting quantity, we simply set chosenAssetQuantity, since that
        // is what lightweight-charts uses.
        const orderbookChange = orderbook.recentChanges.get(rowChange.price);
        if (side == SIDE.BUY) {
          orderbookChange.buyQuantity = chosenAssetQuantity;
        } else {
          orderbookChange.sellQuantity = chosenAssetQuantity;
        }
      }
    },
    clearRecentChanges(orderbook: OrderbookType) {
      if (orderbook !== undefined) {
        orderbook.recentChanges = new Map();
      }
    },
  },
});
