<template>
  <div class="grid">
    <div class="col" :class="sidebarOpen ? 'col-2' : 'col-closed'">
      <FundingFilter :open="sidebarOpen" :request-processing="requestProcessing" @set-filter="setFilter" />
    </div>

    <div class="panel col col-remaining">
      <div class="panel__header" :class="{ 'panel__header--active': !collasped }">
        <div class="flex flex-row">
          <h3 class="panel__header__title">
            Funding Analysis Orignial Investment ($):
          </h3>
          <input v-model="orgInvestment" type="number" class="sidebar__setting__input" />
        </div>

        <i
          :class="{ 'ti-angle-up': !collasped, 'ti-angle-down': collasped }" class="panel__header__icon"
          @click="collasped = !collasped"
        />
      </div>
      <div v-if="!collasped" class="panel__body vtable">
        <div class="vtable-header">
          <div class="vtable-header__item vtable__size--m-20 vtable__size--20">
            Symbol
          </div>
          <div class="vtable-header__item vtable__size--m-16 vtable__size--16">
            Start Date
          </div>
          <div class="vtable-header__item vtable__size--m-16 vtable__size--16">
            Total cycles
          </div>
          <div class="vtable-header__item vtable__size--m-16 vtable__size--16">
            Total %
          </div>
          <div class="vtable-header__item vtable__size--m-16 vtable__size--16">
            Average 8 hour %
          </div>
          <div class="vtable-header__item vtable__size--m-16 vtable__size--16">
            $ ROI on investment X
          </div>
        </div>
        <div class="panel__body__table vtable panel__body__table--large">
          <div v-for="(fundingOverview, index) in symbolsFundingOverview" :key="index" class="vtable__row">
            <div class="vtable__row__item vtable__size--m-20 vtable__size--20">
              {{ fundingOverview.symbol }}
            </div>
            <div class="vtable__row__item vtable__size--m-16 vtable__size--16">
              {{ startDate }}
            </div>
            <div class="vtable__row__item vtable__size--m-16 vtable__size--16">
              {{ fundingOverview.cycles }}
            </div>
            <div class="vtable__row__item vtable__size--m-16 vtable__size--16">
              {{ fundingOverview.percentTotal }}
            </div>
            <div class="vtable__row__item vtable__size--m-16 vtable__size--16">
              {{ fundingOverview.avg8HourPercent }}
            </div>
            <div class="vtable__row__item vtable__size--m-16 vtable__size--16">
              {{ fundingOverview.rOI }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, ref, computed } from 'vue';
import { useWebSocketStore } from '@/stores/user/ws';
import { useFundingAnalysisStore } from '@/stores/exchanges/fundingAnalysis';
import FundingFilter from '@/components/analysis/fundinganalysis/FundingFilter.vue';
import { FundingWrapper, FundingOverview, FundingCycle } from '@/types/fundingAnalysis';
import { useRequestStore } from '@/stores/request';
import { REQUEST_STATUS } from '@/types/general';
import { useSidebarStore } from '@/stores/sidebar';

// Stores
const fundingAnalysisStore = useFundingAnalysisStore();
const sidebarStore = useSidebarStore();
const requestStore = useRequestStore();
const wsStore = useWebSocketStore();

// Computed
const fundingCycles = computed<Record<string, FundingCycle[]>>(() => fundingAnalysisStore.fundingCycles);
const symbolsFundingOverview = computed<FundingOverview[]>(() => {
  const rv = [];

  for (const key in fundingCycles.value) {
    const symbolcycles = fundingCycles.value[key],
      symbolFundingOverview = new FundingOverview(key, symbolcycles.length + 1);
    let percentTotal = 0, roi = orgInvestment.value;

    for (let i = 0; i < symbolcycles.length; i++) {
      const fundingCycle = symbolcycles[i];
      percentTotal += fundingCycle.fundingRate;
      roi += (orgInvestment.value / 100) * fundingCycle.fundingRate;
    }

    symbolFundingOverview.percentTotal = Number(percentTotal.toFixed(6));
    symbolFundingOverview.avg8HourPercent = Number((percentTotal / (symbolFundingOverview.cycles)).toFixed(6));
    symbolFundingOverview.rOI = Number((roi - orgInvestment.value).toFixed(2));
    rv.push(symbolFundingOverview);
  }

  return rv;
});
const sidebarOpen = computed(() => sidebarStore.getSidebarOpen);

// variables
const statusId = ref('');
const requestProcessing = ref(false);
const collasped = ref(false);
const orgInvestment = ref(100000);
const exchangeName = ref('');
const symbol = ref('');
const startDate = ref('');
const endDate = ref('');

// Watchers
watch(() => requestStore.requestStatus, (newVal) => {
  if (newVal && statusId.value in newVal && newVal[statusId.value] !== REQUEST_STATUS.PENDING) {
    requestStore.removeRequestStatus(statusId.value);
    statusId.value = '';
    requestProcessing.value = false;
  }
}, { deep: true });

// Functions
const getSymbolFunding = (): void => {
  if(exchangeName.value == '' || symbol.value == '' || startDate.value == '' || endDate.value == '') {
    return;
  }

  const data = new FundingWrapper(
    'get_symbol_funding', exchangeName.value, symbol.value, startDate.value, endDate.value,
  );
  statusId.value = data.statusId;
  requestProcessing.value = true;
  fundingAnalysisStore.resetFundingCycles();
  wsStore.sendWithRequestStatus(data);
};

const setFilter = (filterType: string, filterValue: string): void => {
  switch (filterType) {
  case 'exchangeName':
    exchangeName.value = filterValue;
    break;
  case 'symbol':
    symbol.value = filterValue;
    break;
  case 'startDate':
    startDate.value = filterValue;
    break;
  case 'endDate':
    endDate.value = filterValue;
    break;
  }

  getSymbolFunding();
};
</script>

<style lang="scss" scoped>

</style>
