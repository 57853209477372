import { defineStore } from 'pinia';
import { CategoriesState, Category, CategoryServer, CategoriesServerResp } from '@/types/resources';

function convertCategoryServer(category: CategoryServer): Category {
  const newCategory = new Category(category.id, category.title, category.body);
  return newCategory;
}

export const useCategoriesStore = defineStore('categories', {
  state: (): CategoriesState => ({
    categories: {
      'PLg1BMlV9f9NE4HSBL12CdaCTqyAEkCt4f': {
        'id': 'PLg1BMlV9f9NE4HSBL12CdaCTqyAEkCt4f',
        'title': 'Trading',
        'body': '',
      },
    },
  }),
  actions: {
    setCategories(resp: CategoriesServerResp): void {
      const newCategories: Record<string, Category> = {};

      for (const key in resp.categories) {
        const newCategory = convertCategoryServer(resp.categories[key]);
        newCategories[newCategory.id] = newCategory;
      }

      this.categories = resp.categories;
    },
  },
  getters: {
    getCategory: (state) => (id: string): Category | null => {
      return id in state.categories ? state.categories[id] : null;
    },
  },
});
