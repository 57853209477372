import { defineStore } from 'pinia';
import { SIDE } from '@/types/account';
import { OrderType } from '@/types/orders';
import Config from '@/config';
import {
  OrderStrategiesState,
  OrderStrategyTypeServer,
  OrderStrategyType,
  OrderStrategiesServerResp,
  OrderStrategyServerResp,
  ORDER_STRATEGY_TYPE,
  ORDER_MOVE_BY,
} from '@/types/order_strategies';
import { NOTIFICATION_TYPE } from '@/types/user';
import { RespError } from '@/types/general';
import { useUserStore } from '@/stores/user/user';
import { createRequestData } from '@/utilities';
import { createNotification } from '@/stores/user/notifications';
import { useClientLogsStore } from '@/stores/user/clientLogs';


function performHttpRequest(endpoint: string, requestInfo: RequestInit, updateType: string): Promise<void> {
  return fetch(endpoint, requestInfo).then(response => {
    // Only resolve the outer promise once the inner promise has resolved
    return response.json().then(jsonResp => {
      if (!response.ok) {
        const serverErrorResp = jsonResp as RespError;

        createNotification(
          `Failed to ${updateType} order strategy. Error: ${serverErrorResp.error}`, NOTIFICATION_TYPE.ERROR);
        return Promise.reject();
      }

      createNotification(`Order strategy successfully ${updateType}d`, NOTIFICATION_TYPE.SUCCESS);
      return Promise.resolve();
    }).catch(() => {
      return Promise.reject();
    });
  }).catch(() => {
    return Promise.reject();
  });
}

function convertOrderStrategyTypeServer(strategyServer: OrderStrategyTypeServer): OrderStrategyType {
  // Validate strategyServer
  if (!(Object.values(ORDER_STRATEGY_TYPE).includes(strategyServer.type as ORDER_STRATEGY_TYPE))) {
    useClientLogsStore().errorLog(`[*] Order strategy type '${strategyServer.type}' invalid`);
    return null;
  }

  if (!(Object.values(SIDE).includes(strategyServer.side as SIDE))) {
    useClientLogsStore().errorLog(`[*] Order strategy side '${strategyServer.side}' invalid`);
    return null;
  }

  if (!(Object.values(ORDER_MOVE_BY).includes(strategyServer.moveBy as ORDER_MOVE_BY))) {
    useClientLogsStore().errorLog(`[*] Order strategy moveBy '${strategyServer.moveBy}' invalid`);
    return null;
  }

  // Set client strategy
  const strategy = new OrderStrategyType();

  strategy.id = strategyServer.id;
  strategy.name = strategyServer.name;
  strategy.type = strategyServer.type as ORDER_STRATEGY_TYPE;
  strategy.side = strategyServer.side as SIDE;
  strategy.moveBy = strategyServer.moveBy as ORDER_MOVE_BY;
  strategy.pushAwayPercent = strategyServer.pushAwayPercent;
  strategy.pushAwayDelta = strategyServer.pushAwayDelta;
  strategy.frontrunLowBound = strategyServer.frontrunLowBound;
  strategy.frontrunHighBound = strategyServer.frontrunHighBound;
  strategy.minFrontrunAmount = strategyServer.minFrontrunAmount;
  strategy.maxFrontrunAmount = strategyServer.maxFrontrunAmount;
  strategy.makeupPercentage = strategyServer.makeupPercentage;

  return strategy;
}

export const useOrderStrategiesStore = defineStore('orderStrategies', {
  state: (): OrderStrategiesState => ({
    strategies: {},
    orderLookup: {},
  }),
  actions: {
    setOrderStrategy(strategyServer: OrderStrategyTypeServer) {
      const newStrategy = convertOrderStrategyTypeServer(strategyServer);

      if (newStrategy == null) return;

      this.strategies[newStrategy.id] = newStrategy;

      if (!this.orderLookup[newStrategy.id]) {
        this.orderLookup[newStrategy.id] = {};
      }
    },
    addOrderToStrategiesLookup(strategyId: string, order: OrderType) {
      if (!this.orderLookup[strategyId]) {
        this.orderLookup[strategyId] = {};
      }

      this.orderLookup[strategyId][order.id] = order;
    },
    deleteOrderFromStrategiesLookup(strategyId: string, order: OrderType) {
      if (!this.orderLookup[strategyId]) {
        return;
      }

      delete this.orderLookup[strategyId][order.id];
    },
    addOrderStrategies(resp: OrderStrategiesServerResp) {
      for (const id in resp.orderStrategies) {
        this.setOrderStrategy(resp.orderStrategies[id]);
      }
    },
    addOrderStrategy(resp: OrderStrategyServerResp) {
      this.setOrderStrategy(resp.orderStrategy);
    },
    deleteOrderStrategy(resp: OrderStrategyServerResp) {
      delete this.strategies[resp.orderStrategy.id];
    },
    createOrderStrategyHttp(body: OrderStrategyType): Promise<void> {
      return performHttpRequest(
        `${Config.apiEndpoint()}/users/orderStrategies`,
        createRequestData('POST', useUserStore().token, body),
        'create',
      );
    },
    updateOrderStrategyHttp(body: OrderStrategyType): Promise<void> {
      return performHttpRequest(
        `${Config.apiEndpoint()}/users/orderStrategies/${body.id}`,
        createRequestData('PATCH', useUserStore().token, body),
        'update',
      );
    },
    deleteOrderStrategyHttp(body: OrderStrategyType): Promise<void> {
      return performHttpRequest(
        `${Config.apiEndpoint()}/users/orderStrategies/${body.id}`,
        createRequestData('DELETE', useUserStore().token, body),
        'delete',
      );
    },
  },
  getters: {
    getStrategies: (state) => () => {
      return state.strategies;
    },
  },
});
