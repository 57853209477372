import { defineStore } from 'pinia';
import { useClientLogsStore } from '@/stores/user/clientLogs';


type MonitoringWsActivities = Record<string, Record<string, number>>;

export interface MonitoringServerResp {
  data: MonitoringWsActivities;
  subcategory: string;
}

interface MonitoringState {
  data: {
    wsActivities: MonitoringWsActivities;
  }
}

export const useMonitoringStore = defineStore('monitoring', {
  state: (): MonitoringState => ({
    data: {
      wsActivities: {},
    },
  }),
  actions: {
    setMonitoring({ subcategory, data }: MonitoringServerResp) {
      switch (subcategory) {
      case 'ws_activity':
        this.setMonitoringWs(data);
        break;
      default:
        useClientLogsStore().errorLog(`[*] Unknown monitoring subcategory '${subcategory}'`);
      }
    },
    setMonitoringWs(wsActivities: MonitoringWsActivities) {
      this.data.wsActivities = wsActivities;
    },
  },
});
