<template>
  <section class="panel priceAction-chart-wrapper">
    <ChartView
      ref="chartRef" :price-data="priceData" :customisable="true" :enable-history-fetching="true"
      :disable-t-t-c-c="false" @set-session-id="setSessionId" @set-ticker-name="setTickerName"
      @set-ticker-category="setTickerCategory" @select-price="selectPrice"
    />
    <section class="chart-sidebar">
      <nav class="chart-sidebar__nav">
        <router-link
          :class="$route.path.includes('/analysis/priceaction') ?
            'chart-sidebar__nav__item chart-sidebar__nav__item--active' :
            'chart-sidebar__nav__item'"
          to="/analysis/priceaction"
        >
          DSL View
        </router-link>
        <router-link
          :class="$route.path.includes('/analysis/priceaction/test') ?
            'chart-sidebar__nav__item chart-sidebar__nav__item--active' :
            'chart-sidebar__nav__item'"
          to="/analysis/priceaction/test"
        >
          Test View
        </router-link>
      </nav>

      <textarea v-model="dsl" type="text" class="chart-sidebar__textarea" />
      <button class="chart-sidebar__btn" @click="analysePriceDataFromDsl()">
        Evaluate Price Data
      </button>

      <section v-if="showDebugData">
        <section v-if="priceDataMeta.priceDataDebug" class="debugData">
          Candles:
          <ul style="font-size: 0.4em;">
            <li
              v-for="(candle, i) in priceDataMeta.priceData.candles" :key="i" @mouseover="highlightCandle(i)"
              @mouseleave="unhighlightCandles()"
            >
              {Open:"{{ candle.open }}", Close:"{{ candle.close }}", High:"{{ candle.high }}", Low:"{{
                candle.low
              }}"},
            </li>
          </ul>
        </section>
        <section v-if="priceDataMeta.swingsDebug" class="debugData">
          Swings:
          <ul style="font-size: 0.8em;">
            <li
              v-for="(swing, i) in priceDataMeta.swings" :key="i" :title="stringifySwing(swing)"
              @mouseover="highlightSwing(swing)" @mouseleave="unhighlightCandles()"
            >
              {{ i }}: {{ swing.direction }}
            </li>
          </ul>
        </section>
        <section v-if="priceDataMeta.trendsDebug" class="debugData">
          Trends:
          <ul style="font-size: 0.8em;">
            <li
              v-for="(trendMeta, i) in flattenTrends(priceData.candles, priceDataMeta.trends)" :key="i"
              :title="stringifyTrend(trendMeta.trend)" @mouseover="highlightTrend(trendMeta)"
              @mouseleave="unhighlightTrend(trendMeta)"
            >
              {{ trendMeta.prefix }} {{ trendMeta.trend.direction }} {{ trendMeta.suffix }}
            </li>
          </ul>
        </section>
      </section>
    </section>
  </section>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, defineAsyncComponent } from 'vue';
import { useQueryStringStore } from '@/stores/queryString';
import { useNotificationsStore } from '@/stores/user/notifications';
import { useWebSocketStore } from '@/stores/user/ws';
import { useRoute } from 'vue-router';
import { NOTIFICATION_TYPE, NotificationType } from '@/types/user';
import {
  stringifySwing, stringifyTrend, usePricedataStore,
  flattenTrends, getFullTickerName, DirectionUp,
} from '@/stores/exchanges/pricedata';
import { PriceData, PriceDataMeta, Swing, DisplayTrend, ChartViewI } from '@/types/pricedata';
import mitt from 'mitt';
import { Emitter } from 'mitt';
import { Event, SelectPrice } from '@/types/general';

const ChartView = defineAsyncComponent(() =>
  import('@/components/exchanges/symbol/ChartView.vue'),
);

// Store
const queryStringStore = useQueryStringStore();
const pricedataStore = usePricedataStore();
const notificationsStore = useNotificationsStore();
const wsStore = useWebSocketStore();
const route = useRoute();

// Computed
const priceDataMeta = computed<PriceDataMeta>(() => {
  return pricedataStore.meta[getFullTickerName(tickerName.value, tickerCategory.value)];
});
const priceData = computed<PriceData>(() => {
  const ticker = getFullTickerName(tickerName.value, tickerCategory.value);
  if (pricedataStore.data[ticker] === undefined) {
    return null;
  }

  return pricedataStore.data[ticker];
});

// Variables
const showDebugData = ref(false);
const dsl = ref('');
const tradingBus = ref<Emitter<Event>> (mitt());
const sessionId = ref('');
const tickerName = ref('');
const tickerCategory = ref('');
const chartRef = ref<ChartViewI>(null);

// Watchers
watch(priceDataMeta, () => {
  if (priceDataMeta.value === undefined) {
    showDebugData.value = false;
    return;
  }

  // TODO: bring back this check?
  // if (this.candlestickSeries === null) {
  // This is hit when Node reloads the page
  // return;
  // }

  // TODO: calculate diff of old versus new data

  showDebugData.value = priceDataMeta.value.priceDataDebug ||
    priceDataMeta.value.swingsDebug ||
    priceDataMeta.value.trendsDebug;

  chartRef.value.addBoxesToGraph(priceDataMeta.value.swingBoxes, '#00f');
  chartRef.value.addBoxesToGraph(priceDataMeta.value.trendBoxes, '#00f');
}, { deep: true });

watch(dsl, (val: string) => {
  if (val !== route.query.dsl as string) {
    queryStringStore.update({ 'dsl': val || null });
  }
});

// Vue Lifecycle Functions
onMounted(() => {
  dsl.value = route.query.dsl as string || dsl.value;
});

// Functions
const analysePriceDataFromDsl = () => {
  if (!priceData.value) {
    // if (this.sessionId === '') { // TODO: is this replacement check ok?
    const msg = 'Load price data first before trying to analyse it.';
    notificationsStore.addNotification(new NotificationType(NOTIFICATION_TYPE.ERROR, msg));
    return;
  }

  wsStore.send({
    category: 'analyse_raw_price_data_from_dsl',
    body: JSON.stringify({
      ticker: getFullTickerName(tickerName.value, tickerCategory.value),
      dsl: dsl.value,
      sessionId: sessionId.value,
    }),
  });

  chartRef.value.removeAllRenderedBoxes();
};

const highlightCandle = (candleNumber: number) => {
  const arrowBelow = false;

  chartRef.value.setMarkers([
    {
      time: String(priceData.value.candles[candleNumber].startTime),
      position: arrowBelow ? 'belowBar' : 'aboveBar',
      color: 'orange',
      shape: arrowBelow ? 'arrowUp' : 'arrowDown',
      text: `${candleNumber}`,
    },
  ]);
};

const highlightSwing = (swing: Swing) => {
  // TODO: convert swing to box and display it (requires also keeping track of it)
  // const boxes = [convertSwingToBox(this.priceData.candles, swing)];

  const arrowBelow = (swing.direction === DirectionUp);

  chartRef.value.setMarkers([
    {
      time: String(priceData.value.candles[swing.fromIndex].startTime),
      position: arrowBelow ? 'belowBar' : 'aboveBar',
      color: 'orange',
      shape: arrowBelow ? 'arrowUp' : 'arrowDown',
      text: `${swing.fromIndex}`,
    },
    {
      time: String(priceData.value.candles[swing.toIndex].startTime),
      position: arrowBelow ? 'belowBar' : 'aboveBar',
      color: 'orange',
      shape: arrowBelow ? 'arrowUp' : 'arrowDown',
      text: `${swing.toIndex}`,
    },
  ]);
};

const highlightTrend = (displayTrend: DisplayTrend) => {
  const trend = displayTrend.trend;
  const arrowBelow = (trend.direction === DirectionUp);

  chartRef.value.addBoxesToGraph(displayTrend.boxes, '#f00', displayTrend);
  chartRef.value.setMarkers([
    {
      time: String(priceData.value.candles[trend.fromIndex].startTime),
      position: arrowBelow ? 'belowBar' : 'aboveBar',
      color: 'orange',
      shape: arrowBelow ? 'arrowUp' : 'arrowDown',
      text: `${trend.fromIndex}`,
    },
    {
      // Invert the second arrow (via !arrowBelow) for better readability
      time: String(priceData.value.candles[trend.toIndex].startTime),
      position: !arrowBelow ? 'belowBar' : 'aboveBar',
      color: 'orange',
      shape: !arrowBelow ? 'arrowUp' : 'arrowDown',
      text: `${trend.toIndex}`,
    },
  ]);
};

const unhighlightCandles = () => {
  chartRef.value.setMarkers([]);
};

const unhighlightTrend = (displayTrend: DisplayTrend) => {
  chartRef.value.removeRenderedBoxes(displayTrend.createdBoxes);
  chartRef.value.setMarkers([]);
};

const setSessionId = (sessionIdNew: string): void => {
  sessionId.value = sessionIdNew;
};

const setTickerName = (tickerNameNew: string): void => {
  tickerName.value = tickerNameNew;
};

const setTickerCategory = (tickerCategoryNew: string): void => {
  tickerCategory.value = tickerCategoryNew;
};

const selectPrice = (selectPriceNew: SelectPrice): void => {
  tradingBus.value.emit('selectPrice', selectPriceNew);
};
</script>

<style></style>
