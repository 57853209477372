<template>
  <div class="panel">
    <div class="panel__header" :class="{ 'panel__header--active': !collasped }">
      <h3 class="panel__header__title">Order Strategies</h3>

      <i
        :class="{ 'ti-angle-up ': !collasped, 'ti-angle-down': collasped }" class="panel__header__icon"
        @click="collasped = !collasped"
      />
    </div>
    <div v-if="!collasped" class="panel__body">
      <div class="vtable-header" style="padding-left: 4px;">
        <div class="vtable-header__item vtable__size--m-25 vtable__size--20" name="name">
          Name
        </div>
        <div class="vtable-header__item vtable__size--m-25 vtable__size--8" name="type">
          Type
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--8" name="pushAwayPercent">
          Push Away Percent
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--8" name="pushAwayDelta">
          Push Away Delta
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--8" name="frontrunLowBound">
          Frontrun <br />Low Bound
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--8" name="frontrunHighBound">
          Frontrun <br />High Bound
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--8" name="minFrontrunAmount">
          Min Frontrun<br /> Amount
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--8" name="maxFrontrunAmount">
          Max Frontrun<br /> Amount
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--8" name="makeupPercentage">
          Makeup Percentage
        </div>
        <div class="vtable-header__item vtable__size--m-25 vtable__size--4" name="actions" />
      </div>
      <DynamicScroller
        :items="Object.values(orderStrategies)" :min-item-size="36"
        class="panel__body__table vtable  panel__body__table--large"
      >
        <template #default="{ item, index, active }">
          <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.id]" :data-index="index">
            <div class="vtable__row">
              <div class="vtable__row__item vtable__size--m-25 vtable__size--20">
                {{ item.name }}
              </div>
              <div class="vtable__row__item vtable__size--m-25 vtable__size--8">
                {{ item.type }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--8">
                {{ item.pushAwayPercent }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--8">
                {{ item.pushAwayDelta }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--8">
                {{ item.frontrunLowBound }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--8">
                {{ item.frontrunHighBound }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--8">
                {{ item.minFrontrunAmount }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--8">
                {{ item.maxFrontrunAmount }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--8">
                {{ item.makeupPercentage }}
              </div>
              <div class="vtable__row__item vtable__row__item--open-btn vtable__size--4 vtable__size--m-8">
                <button class="vtable__row__button" @click="item.showOverview = !item.showOverview">
                  <i :class="!item.showOverview ? 'ti-angle-down' : 'ti-angle-up'" />
                </button>
              </div>
            </div>
            <div class="vtable__row__orders">
              <div v-if="item.showOverview" class="vtable__row__overview">
                <UpdateRowStrategies :order-strategy="item" />
              </div>
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useOrderStrategiesStore } from '@/stores/exchanges/order_strategies';
import { OrderStrategyType } from '@/types/order_strategies';
import UpdateRowStrategies from '@/components/exchanges/strategies/UpdateRowStrategies.vue';

// Store
const orderStrategiesStore = useOrderStrategiesStore();

// Computed variables
const orderStrategies = computed((): Record<string, OrderStrategyType> => {
  return orderStrategiesStore.getStrategies();
});

// Variables
const collasped = ref(false);
</script>

<style lang="scss" scoped>
</style>
