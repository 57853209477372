import { defineStore } from 'pinia';

import Config from '@/config';
import { createRequestData, performHttpRequest } from '@/utilities';

import { SIDE } from '@/types/account';
import {
  PositionType, FillType, PositionsState, PositionServerResp, PositionsServerResp,
  PositionTypeServer, POSITION_STATUS,
} from '@/types/positions';
import { AccountMeta, ORDER_TYPE, ActiveAction } from '@/types/orders';

import { useExchangesSettingsStore } from '@/stores/exchanges/settings';
import { useClientLogsStore } from '@/stores/user/clientLogs';
import { useUserStore } from '@/stores/user/user';


function convertFillTypesServer(exchangeName: string, position: PositionTypeServer): FillType[] {
  const fills = position.fills;
  const fillsRet: FillType[] = [];

  if (!fills) return fillsRet;

  for (const fill of fills) {
    if (!(Object.values(ORDER_TYPE).includes(fill.type as ORDER_TYPE))) {
      useClientLogsStore().errorLog(
        `[${exchangeName}] Invalid position fill type '${fill.type}' ` +
        `(valid: ${Object.values(ORDER_TYPE).join(', ')}). Fill: ${JSON.stringify(fill)}. ` +
        `Position: ${JSON.stringify(position)}`,
      );
      continue;
    }

    if (!(Object.values(SIDE).includes(fill.side as SIDE))) {
      useClientLogsStore().errorLog(
        `[${exchangeName}] Invalid position fill side '${fill.side}' ` +
        `(valid: ${Object.values(SIDE).join(', ')}). Fill: ${JSON.stringify(fill)}. ` +
        `Position: ${JSON.stringify(position)}`,
      );
      continue;
    }

    const newFill = new FillType();

    newFill.quantity = fill.quantity;
    newFill.created = fill.created;
    newFill.price = fill.price;
    newFill.type = fill.type as ORDER_TYPE;
    newFill.fee = fill.fee;
    newFill.pnl = '0';
    newFill.side = fill.side as SIDE;

    fillsRet.push(newFill);
  }

  return fillsRet;
}

export function convertPositionsTypeServer(
  positions: Record<string, PositionTypeServer>, exchangeName: string, accountId: string,
): Record<string, PositionType> {
  const newPositions: Record<string, PositionType> = {};

  for (const positionId in positions) {
    const newPosition = convertPositionTypeServer(positions[positionId], exchangeName, accountId);

    newPositions[newPosition.id] = newPosition;
  }

  return newPositions;
}

export function convertPositionTypeServer(
  position: PositionTypeServer, exchangeName: string, accountId: string,
): PositionType {
  if (!position) {
    return null;
  }

  if (!(Object.values(SIDE).includes(position.side as SIDE))) {
    useClientLogsStore().errorLog(
      `[${exchangeName}] Invalid position side '${position.side}' ` +
      `(valid: ${Object.values(SIDE).join(', ')}). Position: ${JSON.stringify(position)}`,
    );
    return null;
  }

  if (!(Object.values(POSITION_STATUS).includes(position.status as POSITION_STATUS))) {
    useClientLogsStore().errorLog(
      `[${exchangeName}] Invalid position status '${position.status}' ` +
      `(valid: ${Object.values(POSITION_STATUS).join(', ')}). Position: ${JSON.stringify(position)}`,
    );
    return null;
  }

  const newPosition = new PositionType(position.id);
  const fills = convertFillTypesServer(exchangeName, position);
  const exchangeTypeSettings = useExchangesSettingsStore().getExchangeInfoSettings(
    exchangeName,
    position.exchangeType,
  );

  newPosition.symbol = position.symbol;
  newPosition.created = position.created;
  newPosition.updated = position.updated;
  newPosition.setPrice(position.price);
  newPosition.setStopLoss(position.stopLossPrice);
  newPosition.setProfitTarget(position.profitTargetPrice);
  newPosition.setQuantity(position.quantity);
  newPosition.margin = position.margin;
  newPosition.marginAsset = position.marginAsset;
  newPosition.side = position.side as SIDE;
  newPosition.status = position.status as POSITION_STATUS;
  newPosition.leverage = position.leverage;
  newPosition.liquidation = position.liquidation;
  newPosition.accountId = accountId;
  newPosition.calculatePnLInternal = exchangeTypeSettings.pnlCalc;
  newPosition.activeAction = new ActiveAction();
  newPosition.activeAction.lockTime = position.activeAction.lockTime;
  newPosition.activeAction.operation = position.activeAction.operation;

  for (const fill of fills) {
    newPosition.addFill(fill);
  }

  newPosition.exchangeName = exchangeName;
  newPosition.exchangeType = position.exchangeType;

  return newPosition;
}

export const usePositionsStore = defineStore('positions', {
  state: (): PositionsState => ({
    exchanges: {},
  }),
  actions: {
    setPosition({ exchangeName, accountId, position }: PositionServerResp) {
      const newPosition = convertPositionTypeServer(position, exchangeName, accountId);

      if (newPosition == null) return;

      if (!(exchangeName in this.exchanges)) {
        this.exchanges[exchangeName] = {
          accounts: {},
        };
      }

      if (!(accountId in this.exchanges[exchangeName].accounts)) {
        this.exchanges[exchangeName].accounts[accountId] = {
          positions: {},
        };
      }

      this.exchanges[exchangeName].accounts[accountId].positions[position.id] = newPosition;
    },
    addPositions(body: PositionsServerResp) {
      for (const positionId in body.positions) {
        this.setPosition({
          exchangeName: body.exchangeName,
          accountId: body.accountId,
          position: body.positions[positionId],
        });
      }
    },
    closePosition(body: PositionServerResp) {
      delete this.exchanges[body.exchangeName]?.accounts[body.accountId]?.positions[body.position.id];
    },
    updatePositionHttp(body: PositionType, accountMeta: AccountMeta): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('PATCH', token, body);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${accountMeta.exchangeName}/accounts/`
        + `${accountMeta.accountId}/positions/${body.id}`,
        requestInfo,
        'update',
        'position',
      );
    },
    deletePositionHttp(body: PositionType, accountMeta: AccountMeta): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('DELETE', token, body);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${accountMeta.exchangeName}/accounts/`
        + `${accountMeta.accountId}/positions/${body.id}`,
        requestInfo,
        'delete',
        'position',
      );
    },
  },
  getters: {
    getPositions: (state) => (exchangeName: string, exchangeType: string, accountId: string) => {
      // Why is this called so much on load?
      const positions: Record<string, PositionType> = {};

      if (!exchangeName) {
        // Get all positionss
        for (const exchangeName in state.exchanges) {
          const accounts = state.exchanges[exchangeName].accounts;
          for (const accountId in accounts) {
            const account = accounts[accountId];

            for (const positionId in account.positions) {
              const position = account.positions[positionId];

              if (exchangeType === '' || position.exchangeType === exchangeType) {
                positions[positionId] = position;
              }
            }
          }
        }

        return positions;
      }

      if (!(exchangeName in state.exchanges)) {
        return positions;
      }

      const accounts = state.exchanges[exchangeName].accounts;

      if (!accountId) {
        // Get all positions for exchange
        for (const accountId in accounts) {
          const account = accounts[accountId];

          for (const positionId in account.positions) {
            const position = account.positions[positionId];

            if (position.exchangeType === exchangeType) {
              positions[positionId] = position;
            }
          }
        }

        return positions;
      }

      const account = accounts[accountId] || { positions: {} };

      // Get all positions for account
      for (const positionId in account.positions) {
        const position = account.positions[positionId];

        if (position.exchangeType === exchangeType) {
          positions[positionId] = position;
        }
      }

      return positions;
    },
    getPosition: (state) => (positionId: string): PositionType | null => {
      for (const exchangeName in state.exchanges) {
        const accounts = state.exchanges[exchangeName].accounts;

        for (const accountId in accounts) {
          const account = accounts[accountId];

          if (account.positions[positionId]) {
            return account.positions[positionId];
          }
        }
      }

      return null;
    },
  },
});
