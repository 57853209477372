import { defineStore } from 'pinia';
import { useRequestStore } from '@/stores/request';
import { REQUEST_STATUS } from '@/types/general';
import { WS_STATUS, WS_AUTH, WsStateType } from '@/types/user';

export const useWebSocketStore = defineStore('ws', {
  state: (): WsStateType => ({
    ws: {
      conn: null,
      status: WS_STATUS.DISCONNECTED,
      authorised: WS_AUTH.NO,
      serverEncrypted: false,
      offlineMode: false,
      connBuffer: [],
    },
  }),
  actions: {
    setStatus(status: WS_STATUS) {
      this.ws.status = status;
    },
    setConn(conn: WebSocket) {
      this.ws.conn = conn;
    },
    setAuthorised(authorised: WS_AUTH) {
      this.ws.authorised = authorised;
    },
    setOfflineMode(offlineMode: boolean) {
      this.ws.offlineMode = offlineMode;
    },
    setServerEncrypted(serverEncrypted: boolean) {
      this.ws.serverEncrypted = serverEncrypted;
    },
    send(body: { category: string,  body: string }) {
      if (this.ws.conn && this.ws.authorised === WS_AUTH.YES) {
        this.flushConnBuffer();
        this.ws.conn.send(JSON.stringify(body));
      } else {
        this.ws.connBuffer.push(JSON.stringify(body));
        setTimeout(() => this.flushConnBuffer(), 250); // retry sending after a delay
      }
    },
    flushConnBuffer() {
      if (this.ws.connBuffer.length === 0 || !this.ws.conn || this.ws.authorised !== WS_AUTH.YES) {
        return;
      }

      for (const message of this.ws.connBuffer) {
        this.ws.conn.send(message);
      }

      this.ws.connBuffer = [];
    },
    // Ignore linting as any is valid
    // eslint-disable-next-line
    sendWithRequestStatus(body: Record<string, any>) {
      const requestStore = useRequestStore(),
        statusId = body['statusId'] as string,
        categoryWs = body['categoryWs'] as string;
      requestStore.setRequestStatus(statusId, REQUEST_STATUS.PENDING);
      this.send({ category: categoryWs, body: JSON.stringify(body) });
    },
  },
});

