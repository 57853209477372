import { defineStore } from 'pinia';
import { User, multiFactor } from 'firebase/auth';
import { Auth } from '@/firebaseAuth';
import Config from '@/config';
import {
  UserState, PasswordsTsServerResp, UserLoginBody, AccountTypeServerResp, UserPasswordsTsType,
} from '@/types/user';
import { createRequestData, performHttpRequest } from '@/utilities';

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    firebaseUser: null,
    firebaseMultiFactorUser: null, // For 2auth
    token: '',
    accountType: 'Demo',
    passwordsTs: new UserPasswordsTsType(0, 0, 0, 0),
    tradingSessionExpiry: 0,
  }),
  actions: {
    async loginUser(body: UserLoginBody) {
      if (import.meta.env.VITE_APP_OFFLINE) {
        return null;
      }

      try {
        await Auth.login(body.email, body.password);
        return null; // Indicates success, no error
      } catch (err) {
        return err; // Return an error
      }
    },
    async logoutUser() {
      if (import.meta.env.VITE_APP_OFFLINE) {
        return null;
      }

      try {
        await Auth.logout();
        return null;
      } catch (err) {
        console.error(err);
        return err;
      }
    },
    setUser(firebaseUser: User) {
      this.firebaseUser = firebaseUser;
      this.firebaseMultiFactorUser = firebaseUser ? multiFactor(firebaseUser) : null;
    },
    setUserToken(token: string) {
      this.token = token;
    },
    setPasswordsTs({ passwordsTs }: PasswordsTsServerResp) {
      this.passwordsTs = new UserPasswordsTsType(
        passwordsTs.firestore,
        passwordsTs.encrypt,
        passwordsTs.nonce,
        passwordsTs.trading,
      );
    },
    setAccountType(resp: AccountTypeServerResp) {
      this.accountType = resp.accountType;
    },
    setTradingSessionPasswordHttp(oldPassword: string, newPassword: string): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const body = { oldPassword: oldPassword, password: newPassword };
      const requestInfo = createRequestData('PUT', token, body);

      return performHttpRequest(
        `${Config.apiEndpoint()}/users/tradingSessionPassword`,
        requestInfo,
        'create',
        'trading session password',
      );
    },
    setTradingSessionHttp(password: string, ttl: number): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const body = { password: password, sessionLength: ttl };
      const requestInfo = createRequestData('PUT', token, body);

      return performHttpRequest(
        `${Config.apiEndpoint()}/users/tradingSession`,
        requestInfo,
        'create',
        'trading session',
      );
    },
    deleteTradingSessionHttp(): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('DELETE', token, '');

      return performHttpRequest(
        `${Config.apiEndpoint()}/users/tradingSession`,
        requestInfo,
        'close',
        'trading session',
      );
    },
  },
  getters: {
    getUserEmail: (state) => state.firebaseUser ? state.firebaseUser.email : '',
    getAccountType: (state) => state.accountType,
    isLoggedIn: (state) => state.token !== '' || import.meta.env.VITE_APP_OFFLINE == 1,
    user2AuthEnabled: (state) => {
      if (state.firebaseMultiFactorUser == null) return false;
      return state.firebaseMultiFactorUser.enrolledFactors.length > 0;
    },
  },
});
