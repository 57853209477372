import { defineStore } from 'pinia';
import Config from '@/config';
import { useUserSettingsStore } from '@/stores/user/settings';
import { ChosenSymbol } from '@/types/settings';
import { EXCHANGE_NAME } from '@/types/exchange';
import { PriceScaleMode, CrosshairMode, UTCTimestamp } from 'lightweight-charts-private';
import {
  PriceDataMeta, Corner, Box, PriceData, Candle, PriceDataSwingTestResultServer, PriceDataTrendTestResultServer,
  Swing, Trend, DebuggedState, DisplayTrend, State, PriceDataState, PriceDataTestResultsServerResp,
  PriceDataTestResultsDebuggerServerResp, PriceDataServerResp, PriceDataServer, PriceDataTrendTestResult,
  PriceDataSwingTestResult, PriceDataMetaServerResp, GraphCandle, ChartOptions,
} from '@/types/pricedata';
import { useClientLogsStore } from '@/stores/user/clientLogs';
import { useUserStore } from '@/stores/user/user';
import { createRequestData, performHttpRequest } from '@/utilities';


const weeklyMultiplier = 60 * 60 * 24 * 7;
const dailyMultiplier = 60 * 60 * 24;
const hourlyMultiplier = 60 * 60;
const minuteMultiplier = 60;

export function convertTimeframeToTimeframeDisplay(timeframe: number): string {
  const val = [];

  while (timeframe !== 0) {
    if (timeframe >= weeklyMultiplier) {
      const n = Math.floor(timeframe / weeklyMultiplier);

      val.push(`${n}w`);
      timeframe -= n * weeklyMultiplier;
    } else if (timeframe >= dailyMultiplier) {
      const n = Math.floor(timeframe / dailyMultiplier);

      val.push(`${n}d`);
      timeframe -= n * dailyMultiplier;
    } else if (timeframe >= hourlyMultiplier) {
      const n = Math.floor(timeframe / hourlyMultiplier);

      val.push(`${n}h`);
      timeframe -= n * hourlyMultiplier;
    } else if (timeframe >= minuteMultiplier) {
      const n = Math.floor(timeframe / minuteMultiplier);

      val.push(`${n}m`);
      timeframe -= n * minuteMultiplier;
    } else {
      val.push(`${timeframe}s`);
      timeframe = 0;
    }
  }

  return val.join(' ');
}

export function convertTimeframeDisplayToSeconds(timeframeDisplay: string): number {
  const unit = timeframeDisplay[timeframeDisplay.length - 1];
  const window = timeframeDisplay.substring(0, timeframeDisplay.length - 1);
  let multiplier = 0; // time in seconds

  switch (unit) {
  case 'm':
    multiplier = minuteMultiplier;
    break;
  case 'h':
    multiplier = hourlyMultiplier;
    break;
  case 'd':
    multiplier = dailyMultiplier;
    break;
  case 'w':
    multiplier = weeklyMultiplier;
    break;
  default:
    useClientLogsStore().errorLog(`[*] Unknown timeframe unit '${unit}'`, 1);
  }

  return multiplier * parseFloat(window);
}

const swingTestCategory = 'Swing Identification';
const trendTestCategory = 'Trend Identification';

export const DirectionDown = 'down';
export const DirectionUp = 'up';
export const DirectionNeutral = 'neutral';

const defaultSwingColour = '#f00';
const defaultTrendColour = '#0f0';

export function parsePriceData(priceData: PriceDataServer, milliseconds = false): PriceData {
  const candles = [];

  for (const priceDataItem of priceData.priceDataItems) {
    candles.push(new Candle(
      milliseconds ? priceDataItem.startTime / 1000 : priceDataItem.startTime,
      milliseconds ? priceDataItem.endTime / 1000 : priceDataItem.endTime,
      priceDataItem.open,
      priceDataItem.close,
      priceDataItem.high,
      priceDataItem.low,
      priceDataItem.volume,
      priceDataItem.chosenAssetVolume || priceDataItem.volume,
    ));
  }

  return new PriceData(
    priceData.ticker,
    priceData.timeframe,
    milliseconds ? priceData.fromDate / 1000 : priceData.fromDate,
    milliseconds ? priceData.toDate / 1000 : priceData.toDate,
    candles,
  );
}

function parseSwing(swing: Swing): Swing {
  return new Swing(
    swing.fromIndex,
    swing.toIndex,
    swing.fromPriceWick,
    swing.fromPriceBody,
    swing.toPriceBody,
    swing.toPriceWick,
    swing.direction,
  );
}

function parseSwings(rawSwings: Swing[]): Swing[] {
  const swings = [];

  for (const swing of rawSwings) {
    swings.push(parseSwing(swing));
  }

  return swings;
}

function parseTrend(rawTrend: Trend): Trend {
  const trend = new Trend(
    rawTrend.fromIndex,
    rawTrend.toIndex,
    rawTrend.fromPriceWick,
    rawTrend.fromPriceBody,
    rawTrend.toPriceBody,
    rawTrend.toPriceWick,
    rawTrend.direction,
  );

  if (rawTrend.trends.length > 0) {
    for (const subtrend of rawTrend.trends) {
      trend.trends.push(parseTrend(subtrend));
    }
  } else if (rawTrend.swings.length > 0) {
    for (const swing of rawTrend.swings) {
      trend.swings.push(parseSwing(swing));
    }
  }

  return trend;
}

function parseTrends(rawTrends: Trend[]): Trend[] {
  const trends = [];

  for (const subtrend of rawTrends) {
    trends.push(parseTrend(subtrend));
  }

  return trends;
}

function convertSwingToBox(candles: Candle[], swing: Swing, swingColour: string = defaultSwingColour): Box {
  const fromCandle = candles[swing.fromIndex];
  const toCandle = candles[swing.toIndex];

  return new Box(
    fromCandle.startTime,
    toCandle.startTime,
    // TODO: this is an incorrect way to calculate the swing high/low
    swing.fromPriceWick,
    swing.toPriceWick,
    swingColour,
  );
}

function convertSwingsToBoxes(candles: Candle[], swings: Swing[], swingColour: string = defaultSwingColour): Box[] {
  const boxes = [];

  for (const swing of swings) {
    boxes.push(convertSwingToBox(candles, swing, swingColour));
  }

  return boxes;
}

function convertTrendToBox(candles: Candle[], trend: Trend, trendColour: string = defaultTrendColour): Box {
  let startSwing: Swing | Trend;
  let endSwing: Swing | Trend;

  if (trend.swings?.length) {
    startSwing = trend.swings[0];
    endSwing = trend.swings[trend.swings.length - 1];
  } else {
    // A lower timeframe trend is just a higher timeframe swing. Plus, they
    // share the same necessary fields too
    startSwing = trend.trends[0];
    endSwing = trend.trends[trend.trends.length - 1];
  }

  // TODO: should never happen
  if (!startSwing || !endSwing) {
    useClientLogsStore().errorLog(
      `[*] Start swing (${String(!!startSwing)} and/or end swing (${String(!!endSwing)} is not set. ` +
      `Trend: ${JSON.stringify(trend)}`,
    );
    return null;
  }

  const corners = [];

  if (trend.direction == DirectionDown) {
    corners.push(
      new Corner(
        candles[startSwing.fromIndex].startTime,
        startSwing.toPriceWick,
      ),
      new Corner(
        candles[startSwing.toIndex].startTime,
        startSwing.toPriceWick,
      ),
      new Corner(
        candles[endSwing.toIndex].startTime,
        endSwing.fromPriceWick,
      ),
      new Corner(
        candles[endSwing.fromIndex].startTime,
        endSwing.fromPriceWick,
      ),
    );
  } else {
    corners.push(
      new Corner(
        candles[startSwing.fromIndex].startTime,
        startSwing.fromPriceWick,
      ),
      new Corner(
        candles[startSwing.toIndex].startTime,
        startSwing.fromPriceWick,
      ),
      new Corner(
        candles[endSwing.toIndex].startTime,
        endSwing.toPriceWick,
      ),
      new Corner(
        candles[endSwing.fromIndex].startTime,
        endSwing.toPriceWick,
      ),
    );
  }

  // TODO: Accurately highlight the whole trend (e.g. a curved trend)

  return new Box(
    0, 0, '', '',
    trendColour,
    corners,
  );
}

function convertTrendsToBoxes(candles: Candle[], trends: Trend[], trendColour: string = defaultTrendColour): Box[] {
  const boxes = [];

  for (const trend of trends) {
    boxes.push(convertTrendToBox(candles, trend, trendColour));
  }

  return boxes;
}

function convertTrendToAllBoxes(candles: Candle[], trend: Trend, trendColour: string = defaultTrendColour): Box[] {
  const boxes = [
    convertTrendToBox(candles, trend, trendColour),
  ];

  for (const subtrend of trend.trends) {
    boxes.push(...convertTrendToAllBoxes(candles, subtrend, trendColour));
  }

  return boxes;
}

function convertTrendsToAllBoxes(candles: Candle[], trends: Trend[], trendColour: string = defaultTrendColour): Box[] {
  const boxes = [];

  for (const trend of trends) {
    boxes.push(...convertTrendToAllBoxes(candles, trend, trendColour));
  }

  return boxes;
}

export function convertSwingsToText(swings: Swing[]): string {
  let text = '[';

  for (let i = 0; i < swings.length; i++) {
    const swing = swings[i];

    text += '{\n';
    text += `${i}: Direction=${swing.direction}\n`;
    text += `${i}: FromIndex=${swing.fromIndex}, ToIndex=${swing.toIndex}\n`;
    text += `${i}: FromWick=${swing.fromPriceWick}, ToWick=${swing.toPriceWick}\n`;
    text += `${i}: FromBody=${swing.fromPriceBody}, ToBody=${swing.toPriceBody}\n`;
    text += '}';
  }

  text += ']';

  return text;
}

export function stringifySwing(swing: Swing, depth = 0): string {
  return (' '.repeat(depth)) + `${swing.direction} (${swing.fromIndex}..${swing.toIndex}): `
    + `(${swing.fromPriceWick}, ${swing.fromPriceBody}) `
    + `=> (${swing.toPriceBody}, ${swing.toPriceWick})\n`;
}

export function stringifyTrend(trend: Trend, depth = 0): string {
  const depthMultiplier = 2;
  let indent = ' '.repeat(depth);
  let text = `${indent}Trend:\n`;

  depth += depthMultiplier;
  indent = ' '.repeat(depth); // Indent the trend body

  text += `${indent}${trend.direction} (${trend.fromIndex}..${trend.toIndex}): `
    + `(${trend.fromPriceWick}, ${trend.fromPriceBody}) `
    + `=> (${trend.toPriceBody}, ${trend.toPriceWick})\n`;

  if (trend.swings.length > 0) {
    text += `${indent}Swings:\n`;

    for (const swing of trend.swings) {
      text += `${stringifySwing(swing, depth + depthMultiplier)}`;
    }
  } else {
    text += `${indent}Subtrends:\n`;

    for (const subtrend of trend.trends) {
      text += `${stringifyTrend(subtrend, depth + depthMultiplier)}\n`;
    }
  }

  return text;
}

export function convertTrendsToText(trends: Trend[]): string {
  let text = '';

  for (let i = 0; i < trends.length; ++i) {
    text += `Trend (${i}):\n${stringifyTrend(trends[i])}`;
  }

  return text;
}

export function flattenTrend(candles: Candle[], trend: Trend, prefix = ''): DisplayTrend[] {
  const newTrends = [];
  const trendBoxes: Box[] = [];

  // Highlight the current trend (in yellow) and its immediate subtrends/swings in orange
  trendBoxes.push(
    convertTrendToBox(candles, trend, '#ff0'),
    ...trend.trends.length ?
      convertTrendsToBoxes(candles, trend.trends, '#c50') :
      convertSwingsToBoxes(candles, trend.swings, '#c50'),
  );

  newTrends.push({
    prefix: prefix,
    trend: trend,
    boxes: trendBoxes,
    createdBoxes: [],
    suffix: trend.trends.length ? `(${trend.trends.length} subtrends)` : `(${trend.swings.length} swings)`,
  });

  if (!trend.trends.length && !trend.swings.length) {
    useClientLogsStore().errorLog(`Bad trend encounted (no sub trends or swings): Trend: ${JSON.stringify(trend)}`);
  }

  if (trend.trends.length) {
    for (const subtrend of trend.trends) {
      newTrends.push(...flattenTrend(candles, subtrend, prefix + '|-'));
    }
  }

  return newTrends;
}

export function flattenTrends(candles: Candle[], trends: Trend[]): DisplayTrend[] {
  const newTrends = [];

  for (const trend of trends) {
    newTrends.push(...flattenTrend(candles, trend));
  }

  return newTrends;
}

export function getFullTickerName(tickerName: string, tickerCategory: string): string {
  switch (tickerCategory) {
  case 'Stock':
    return tickerName;
  case 'Forex':
    return `C:${tickerName}`;
  case 'Index':
    return `I:${tickerName}`;
  case 'Crypto':
    return `X:${tickerName}`;
  default:
    return '';
  }
}

function parseState(priceData: PriceData, rawState: State): State {
  const state = new State(
    rawState.direction,
    parseSwings(rawState.swings || []),
    rawState.stage,
    rawState.breakingRangeDirection,
    parseTrends(rawState.trends || []),
    parseTrends(rawState.trendStack || []),
    rawState.toIndex,
  );

  if (rawState.currentSwing !== null) {
    state.currentSwing = parseSwing(rawState.currentSwing);
    state.currentBox = convertSwingToBox(priceData.candles, state.currentSwing);
  }

  return state;
}

function parseStates(priceData: PriceData, rawStates: State[]): State[] {
  const states = [];

  for (const rawState of rawStates) {
    states.push(parseState(priceData, rawState));
  }

  return states;
}

export function getChartStyle(inverted: boolean): ChartOptions {
  return {
    autoSize: true,
    rightPriceScale: {
      mode: PriceScaleMode.Logarithmic,
      invertScale: inverted,
    },
    layout: {
      background: {
        color: '#000000',
      },
      textColor: '#D9D9D9',
    },
    grid: {
      vertLines: {
        color: '#363C4E',
      },
      horzLines: {
        color: '#363C4E',
      },
    },
    crosshair: {
      mode: CrosshairMode.Normal,
    },
    timeScale: {
      timeVisible: true,
    },
  };
}

export function convertCandleToGraphCandleVolume(candle: Candle): GraphCandle {
  return {
    time: candle.startTime as UTCTimestamp,
    value: parseFloat(candle.chosenAssetVolume),
    color: parseFloat(candle.open) < parseFloat(candle.close) ? '#26a69a' : '#ef5350',
  };
}

export function convertCandlesToGraphCandleVolumes(candles: Candle[]): GraphCandle[] {
  const graphCandleVolumes = [];

  for (const candle of candles) {
    graphCandleVolumes.push(convertCandleToGraphCandleVolume(candle));
  }

  return graphCandleVolumes;
}

export function convertCandleToGraphCandle(candle: Candle): GraphCandle {
  return {
    time: candle.startTime as UTCTimestamp,
    open: parseFloat(candle.open),
    close: parseFloat(candle.close),
    high: parseFloat(candle.high),
    low: parseFloat(candle.low),
  };
}

export function convertCandlesToGraphCandles(candles: Candle[]): GraphCandle[] {
  const graphCandles = [];

  for (const candle of candles) {
    graphCandles.push(convertCandleToGraphCandle(candle));
  }

  return graphCandles;
}

// TODO this is wrong because mutating server state?
// Server resp shouldn't have chosenAssetVolume
function addChosenAssetVolume(resp: PriceDataServerResp) {
  for (const priceDataItem of resp.body.priceDataItems) {
    priceDataItem.chosenAssetVolume = String(useUserSettingsStore().convertSymbolQuantityToChosenAsset(
      parseFloat(priceDataItem.volume),
      // Taking the average of open/close is not accurate, but is good enough
      Math.abs(parseFloat(priceDataItem.open) + parseFloat(priceDataItem.close)) / 2,
      new ChosenSymbol(resp.exchangeName, resp.exchangeType, resp.symbol),
    ));
  }
}

export const usePricedataStore = defineStore('pricedata', {
  state: (): PriceDataState => ({
    data: {},
    meta: {},
    testResults: {},
    testResultsDebug: null,
  }),
  actions: {
    setPriceData(resp: PriceDataServerResp) {
      addChosenAssetVolume(resp);

      const priceData = parsePriceData(resp.body, !('exchangeName' in resp));

      let key = priceData.ticker;

      if ('exchangeName' in resp) {
        if (resp.exchangeName === EXCHANGE_NAME.BITFINEX) {
          priceData.ticker = priceData.ticker.substring(1);
        }

        key = `${resp.exchangeName}${resp.exchangeType}${priceData.ticker}`;
      }

      this.data[key] = priceData;
      delete this.meta[key]; // Need to erase the debug data, since candlesticks may not match swing/trend state
    },
    amendPriceData(resp: PriceDataServerResp, pushCandleChanges = true): PriceData {
      addChosenAssetVolume(resp);

      const priceData = parsePriceData(resp.body, !('exchangeName' in resp));
      let key = priceData.ticker;

      if ('exchangeName' in resp) {
        if (resp.exchangeName === EXCHANGE_NAME.BITFINEX) {
          priceData.ticker = priceData.ticker.substring(1);
        }

        key = resp.exchangeName + resp.exchangeType + priceData.ticker;
      }

      if (this.data[key] === undefined) {
        useClientLogsStore().warningLog(
          `[${resp.exchangeName}][${resp.exchangeType}][${priceData.ticker}] No price data found to amend`);
        return priceData;
      }

      if (pushCandleChanges) {
        this.data[key].candleChanges.push(...priceData.candles);
      }

      return priceData;
    },
    removeLastCandle(resp: { body: string }) {
      this.data[resp.body].candles.pop();
      this.data[resp.body].candleChanges.push(new Candle(0, 0, '', '', '', '', '', ''));
    },
    setPriceDataMeta(resp: PriceDataMetaServerResp) {
      const body = resp.body;
      const meta = new PriceDataMeta(body.ticker);

      meta.swingColour = body.swingColour || '';
      meta.trendColour = body.trendColour || '';
      meta.priceDataDebug = body.priceDataDebug || false;
      meta.swingsDebug = body.swingsDebug || false;
      meta.trendsDebug = body.trendsDebug || false;

      if (body.priceData) {
        meta.priceData = parsePriceData(body.priceData);
      }

      if (body.swings) {
        meta.swings = parseSwings(body.swings);
      }

      if (meta.swingColour !== '') { // A set colour means highlight them, which requires them to be boxes
        meta.swingBoxes = convertSwingsToBoxes(this.data[meta.ticker].candles, meta.swings, meta.swingColour);
      }

      if (body.trends) {
        meta.trends = parseTrends(body.trends);
      }

      if (meta.trendColour !== '') { // A set colour means highlight them, which requires them to be boxes
        meta.trendBoxes = convertTrendsToBoxes(this.data[meta.ticker].candles, meta.trends, meta.trendColour);
      }

      this.meta[body.ticker] = meta;
    },
    setPriceDataTestResults(resp: PriceDataTestResultsServerResp) {
      const categoryResps = resp.body as { category: string }[];
      const newResults: Record<string, PriceDataSwingTestResult[] | PriceDataTrendTestResult[]> = {};

      for (const categoryResp of categoryResps) {
        if (newResults[categoryResp.category] === undefined) {
          newResults[categoryResp.category] = [];
        }

        switch (categoryResp.category) {
        case swingTestCategory: {
          const result = categoryResp as PriceDataSwingTestResultServer;
          const priceData = parsePriceData(result.priceData);
          const expectedSwings = parseSwings(result.expectedResults);
          const actualSwings = parseSwings(result.actualResults);
          const newResultsCategory = newResults[result.category] as PriceDataSwingTestResult[];

          newResultsCategory.push(
            new PriceDataSwingTestResult(
              result.testName,
              expectedSwings,
              convertSwingsToBoxes(priceData.candles, expectedSwings),
              actualSwings,
              convertSwingsToBoxes(priceData.candles, actualSwings),
              priceData,
              result.error,
              result.category,
            ),
          );
          break;
        }
        case trendTestCategory: {
          const result = categoryResp as PriceDataTrendTestResultServer;
          const priceData = parsePriceData(result.priceData);
          const expectedTrends = parseTrends(result.expectedResults);
          const actualTrends = parseTrends(result.actualResults);
          const newResultsCategory = newResults[result.category] as PriceDataTrendTestResult[];

          newResultsCategory.push(
            new PriceDataTrendTestResult(
              result.testName,
              expectedTrends,
              // flattenTrends(priceData.candles, expectedTrends)[0].boxes,
              convertTrendsToAllBoxes(priceData.candles, expectedTrends),
              actualTrends,
              // flattenTrends(priceData.candles, actualTrends)[0].boxes,
              convertTrendsToAllBoxes(priceData.candles, actualTrends),
              priceData,
              result.error,
              result.category,
            ),
          );
          break;
        }
        default:
          useClientLogsStore().errorLog(`[*] Unknown test category '${categoryResp.category}' encountered`);
        }
      }

      this.testResults = newResults;
    },
    setPriceDataTestResultsDebugger(resp: PriceDataTestResultsDebuggerServerResp) {
      const debuggedResults = resp.body;
      const priceData = parsePriceData(debuggedResults.priceData);

      this.testResultsDebug = new DebuggedState(
        priceData,
        parseStates(priceData, debuggedResults.states),
      );
    },
    fetchMorePricedataHttp(
      exchangeName: string, exchangeType: string, symbol: string, endTime: number, timeframe: number,
    ): Promise<string> {
      const token = useUserStore().token;
      const requestInfo = createRequestData('GET', token, '');

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${exchangeName}/exchangeTypes/${exchangeType}/pricedata`
        + `?symbol=${symbol}&endTime=${endTime}&timeframe=${timeframe}`,
        requestInfo,
        'receive',
        'price data history',
      );
    },
  },
});
