<template>
  <div class="grid">
    <div class="col col-12">
      <header class="page-header">
        <h1 class="page-header__title">
          Portfolio
        </h1>
        <div v-if="$route.query.exchangeName" class="page-header__settings page-header__settings--first">
          <router-link class="button" :to="{ path: $route.fullPath }">
            Clear Filter
          </router-link>
        </div>
        <div
          v-if="Object.keys(priceLookupCacheNotFound).length > 0"
          class="page-header__settings"
          style="max-width: 20em;"
        >
          <h3 class="page-header__settings__title">
            WARNING (unconverted assets)
          </h3>

          {{ Object.keys(priceLookupCacheNotFound).join(', ') }}
        </div>
        <div class="page-header__settings">
          <h3 class="page-header__settings__title">
            Wallet Aggregation
          </h3>

          <div class="page-header__settings__row form ">
            <div class="form__group toggle">
              <input id="entity" v-model="aggr['entity']" type="checkbox" />
              <label for="entity">Entity</label>
            </div>
            <div class="form__group toggle">
              <input id="account" v-model="aggr['account']" type="checkbox" />
              <label for="account">Account</label>
            </div>
          </div>
          <div class="page-header__settings__row form ">
            <div class="form__group toggle">
              <input id="wallet" v-model="aggr['wallet']" type="checkbox" />
              <label for="wallet">Wallet</label>
            </div>
            <div class="form__group toggle">
              <input id="asset" v-model="aggr['asset']" type="checkbox" />
              <label for="asset">Asset</label>
            </div>
          </div>
        </div>
        <div class="page-header__settings">
          <h3 class="page-header__settings__title">
            Wallet Filtering
          </h3>
          <div class="page-header__settings__row form ">
            <div class="page-header__settings__row__item form__group">
              <label class="form__group__label--large" for="entityType">Entity type:</label>
              <select id="entityType" v-model="selectedEntityType" class="chart__header__select">
                <option value="">All</option>
                <option v-for="(entityType, i) in entityTypes" :key="i">
                  {{ entityType }}
                </option>
              </select>
            </div>
            <div class="page-header__settings__row__item form__group">
              <label class="form__group__label--large" for="customEntity">Custom entity?</label>
              <select id="customEntity" v-model="selectedCustomEntity" class="chart__header__select">
                <option value="">All</option>
                <option>true</option>
                <option>false</option>
              </select>
            </div>
          </div>
          <div class="page-header__settings__row form ">
            <div class="page-header__settings__row__item form__group">
              <label class="form__group__label--large" for="minBalance">Min ({{ chosenAsset }}) balance:</label>
              <input
                id="minBalance" v-model="minBalance" type="number"
                class="form__group__input form__group__input--small"
              />
            </div>
            <div class="page-header__settings__row__item form__group">
              <label class="form__group__label--large" for="minAvailableBalance">
                Min ({{ chosenAsset }}) available balance:
              </label>
              <input
                id="minAvailableBalance" v-model="minAvailableBalance" type="number"
                class="form__group__input form__group__input--small"
              />
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="col col-12">
      <WalletView
        v-if="walletsOverviewData" title="Overview" :wallet="walletsOverviewData" :depth="0"
        :min-balance="minBalance" :min-available-balance="minAvailableBalance" :asset-aggr="true"
      />

      <section v-for="(wallet, i) in walletsData" :key="i" style="margin-top: .5rem">
        <WalletView
          :wallet="wallet" :depth="0" :min-balance="minBalance" title=""
          :min-available-balance="minAvailableBalance" :asset-aggr="aggr['asset']"
        />
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useUserSettingsStore, priceLookupCacheNotFound } from '@/stores/user/settings';
import { useRoute } from 'vue-router';
import WalletView from '@/components/exchanges/portfolio/WalletView.vue';
import { useWalletsStore } from '@/stores/exchanges/wallets';
import { AssetBalanceType, WalletType } from '@/types/wallets';

// Stores
const walletsStore = useWalletsStore();
const accountManagementStore = useAccountManagementStore();
const userSettingsStore = useUserSettingsStore();
const route = useRoute();

const wallets = computed(() => walletsStore.wallets);
const currentChosenAsset = computed(() => userSettingsStore.getCurrentChosenAsset);
const chosenAsset = computed(() => userSettingsStore.getChosenAssetSymbol);
const minWalletDisplayAmounts = computed(() => userSettingsStore.getMinWalletDisplayAmounts);
const entityTypes = computed(() => {
  const types: Record<string, boolean> = {};

  for (const entityName in accountManagementStore.exchanges) {
    const entity = accountManagementStore.exchanges[entityName];

    types[entity.category] = true;
  }

  if (!types[userSettingsStore.portfolio.defaultSelectedCategory]) {
    types[userSettingsStore.portfolio.defaultSelectedCategory] = true;
  }

  return Object.keys(types);
});

// Properties
const selectedEntityType = ref(userSettingsStore.portfolio.defaultSelectedCategory);
const selectedCustomEntity = ref('');
const minBalance = ref(0);
const minAvailableBalance = ref(0);
const walletsData = ref([]);
const walletsOverviewData = ref(null); // Adjust with an appropriate default value if needed
const selectedExchangeName = ref('');
const selectedAccountId = ref('');
const aggrKeys = ref(['Exchanges', 'Accounts', 'Wallets', 'Assets']);
const aggr = ref({
  'entity': true,
  'account': true,
  // TODO: Wallet aggregation by name doesn't work. Wallets need IDs, perhaps
  // formed from {exchangeType, accountID}. This will require changing the
  // datastore too
  'wallet': true,
  'asset': true,
});

let walletsDataCacheTime = 0;
let walletsDataInterval = 0;
const tableRefreshRateMs = 3000;

// Watching for changes if needed
// Example: watch(wallets, (newVal, oldVal) => { /* reaction */ });
watch(minWalletDisplayAmounts, () => {
  if (minBalance.value !== minWalletDisplayAmounts.value.minBalance) {
    minBalance.value = minWalletDisplayAmounts.value.minBalance;
  }
  if (minAvailableBalance.value !== minWalletDisplayAmounts.value.minAvailableBalance) {
    minAvailableBalance.value = minWalletDisplayAmounts.value.minAvailableBalance;
  }
}, { deep: true, immediate: true });

watch(wallets, () => {
  computeWalletsData();
}, { deep: true });

watch(currentChosenAsset, () => {
  computeWalletsData();
});

watch(aggr, () => {
  computeWalletsData();
}, { deep: true });

watch(selectedEntityType, () => {
  computeWalletsData();
});

watch(selectedCustomEntity, () => {
  computeWalletsData();
});

watch(minBalance, (newVal) => {
  userSettingsStore.setMinBalance(newVal);
});

watch(minAvailableBalance, (newVal) => {
  userSettingsStore.setMinAvailableBalance(newVal);
});

watch(() => route.query.exchangeName, (val) => {
  selectedExchangeName.value = String(val);
  computeWalletsData();
});

watch(() => route.query.accountId, (val) => {
  selectedAccountId.value = String(val);
  computeWalletsData();
});

onMounted(() => {
  selectedExchangeName.value = String(route.query.exchangeName || '');
  selectedAccountId.value = String(route.query.accountId || '');
  computeWalletsData();

  walletsDataInterval = window.setInterval(() => computeWalletsData(false), tableRefreshRateMs);
});

onUnmounted(() => {
  clearInterval(walletsDataInterval);
});

// Flatten everything into an array of wallets, where each asset is its own wallet
const computeWalletsData = (hardRefresh = true) => {
  if (!hardRefresh && new Date().getTime() - walletsDataCacheTime < tableRefreshRateMs) {
    return;
  }

  const newWallets: WalletType[] = [];

  // Flatten everything into an array of wallets, where each asset is its own wallet
  for (const accountId in wallets.value) {
    for (const walletId in wallets.value[accountId]) {
      const wallet = wallets.value[accountId][walletId];

      if (selectedEntityType.value !== '') {
        const entity = accountManagementStore.exchanges[wallet.exchangeName];

        if (selectedEntityType.value !== entity.category) {
          continue;
        }
      }

      if (selectedCustomEntity.value !== '') {
        const entity = accountManagementStore.exchanges[wallet.exchangeName];
        const custom = (selectedCustomEntity.value === 'true');

        if (custom !== entity.custom) {
          continue;
        }
      }

      for (const asset in wallet.balances) {
        const newBalances: Record<string, AssetBalanceType> = {};
        const balance = wallet.balances[asset];
        const total = parseFloat(balance.total);
        const available = parseFloat(balance.available);

        if (total === 0) continue;

        newBalances[asset] = new AssetBalanceType(balance.available, balance.total);

        const chosenAssetAvailable = userSettingsStore.getChosenAssetValue(
          wallet.exchangeName, asset, available, false,
        );
        const chosenAssetTotal = userSettingsStore.getChosenAssetValue(wallet.exchangeName, asset, total, false);

        newBalances[asset].chosenAssetAvailable = String(chosenAssetAvailable);
        newBalances[asset].chosenAssetTotal = String(chosenAssetTotal);

        const newWallet = new WalletType(
          walletId,
          wallet.name,
          newBalances,
          wallet.exchangeName,
          accountId,
        );
        newWallet.chosenAssetAvailable = chosenAssetAvailable;
        newWallet.chosenAssetTotal = chosenAssetTotal;
        newWallets.push(newWallet);
      }
    }
  }

  let aggregationKeys = [
    'All Exchanges',
    'All Accounts',
    'All Wallets',
    '', // Only aggregate overview wallet by asset name
  ];

  const walletsOverviewDataTemp = aggregateWalletsData(newWallets, aggregationKeys);

  if (walletsOverviewDataTemp.length === 1) {
    walletsOverviewData.value = walletsOverviewDataTemp[0];
  }

  aggregationKeys = [
    aggr.value['entity'] ? '' : aggregationKeys[0],
    aggr.value['account'] ? '' : aggregationKeys[1],
    aggr.value['wallet'] ? '' : aggregationKeys[2],
    aggr.value['asset'] ? '' : 'All Assets',
  ];
  walletsData.value = aggregateWalletsData(newWallets, aggregationKeys);

  walletsDataCacheTime = new Date().getTime();
};

const aggregateWalletsData = (wallets: WalletType[], aggregationKeys: string[]): WalletType[] => {
  const newWalletsData: Record<string, WalletType> = {};
  const newWallets: WalletType[] = [];
  let rowName = '';
  let tableHeading = '';

  for (const wallet of wallets) {
    const keys = [wallet.exchangeName, wallet.accountId, wallet.id, Object.keys(wallet.balances)[0]];
    const newKeys = aggregationKeys.map((value, i) => value || keys[i]);

    for (let i = aggregationKeys.length - 1; i > -1; --i) {
      if (aggregationKeys[i] === '') {
        rowName = keys[i];
        newKeys[i] = '';
        tableHeading = aggrKeys.value[i];
        break;
      }
    }

    const lookup = newKeys.join('***');

    if (newWalletsData[lookup] === undefined) {
      newWalletsData[lookup] = new WalletType(wallet.id, newKeys[2], {}, wallet.exchangeName, newKeys[2]);
      newWalletsData[lookup].aggregationKeys = aggregationKeys.map(
        (key, i) => {
          if (key !== '') {
            return null;
          }

          return aggrKeys.value[i] === 'Accounts'
            ? { [aggrKeys.value[i]]: accountManagementStore.getAccount(wallet.exchangeName, keys[i])?.name }
            : { [aggrKeys.value[i]]: keys[i] };
        },
      ).filter(key => key !== null)
        .slice(0, -1);
      newWalletsData[lookup].tableHeading = tableHeading;
      newWallets.push(newWalletsData[lookup]);
    }

    if (wallet.exchangeName !== newWalletsData[lookup].exchangeName) {
      newWalletsData[lookup].exchangeName = '';
    }

    const newWallet = newWalletsData[lookup];
    const currentBalance = Object.values(wallet.balances)[0]; // Should only be 1 row anyway

    if (newWallet.balances[rowName] === undefined) {
      newWallet.balances[rowName] = new AssetBalanceType('0', '0');
    }

    const newBalance = newWallet.balances[rowName];

    if (aggregationKeys[3] === '') { // Aggregate by asset - cannot use this.assetAggr due to Overview table
      newBalance.available = String(parseFloat(newBalance.available) + parseFloat(currentBalance.available));
      newBalance.total = String(parseFloat(newBalance.total) + parseFloat(currentBalance.total));
    }

    newBalance.chosenAssetAvailable = String(
      parseFloat(newBalance.chosenAssetAvailable) + parseFloat(currentBalance.chosenAssetAvailable),
    );
    newBalance.chosenAssetTotal = String(
      parseFloat(newBalance.chosenAssetTotal) + parseFloat(currentBalance.chosenAssetTotal),
    );

    newWallet.chosenAssetAvailable += parseFloat(currentBalance.chosenAssetAvailable);
    newWallet.chosenAssetTotal += parseFloat(currentBalance.chosenAssetTotal);
  }

  newWallets.sort((a, b) => {
    if (a.exchangeName < b.exchangeName) {
      return -1;
    }

    if (a.exchangeName > b.exchangeName) {
      return 1;
    }

    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });

  return newWallets;
};
</script>

<style lang="scss" scoped>

</style>
