<template>
  <section class="panel priceAction-chart-wrapper">
    <ChartView
      ref="chartRef" :price-data="priceData" :customisable="true"
      :disable-t-t-c-c="true"
    />
    <section class="chart-sidebar">
      <nav class="chart-sidebar__nav">
        <router-link
          :class="$route.path.includes('/analysis/priceaction') ?
            'chart-sidebar__nav__item chart-sidebar__nav__item--active' :
            'chart-sidebar__nav__item'"
          to="/analysis/priceaction"
        >
          DSL View
        </router-link>
        <router-link
          :class="$route.path.includes('/analysis/priceaction/test') ?
            'chart-sidebar__nav__item chart-sidebar__nav__item--active' :
            'chart-sidebar__nav__item'"
          to="/analysis/priceaction/test"
        >
          Test View
        </router-link>
      </nav>

      <button class="chart-sidebar__btn" @click="runPriceDataTests()">
        Run Tests
      </button>

      <hr />

      <button v-for="(category, i) in testCategories" :key="i" class="btn-tw" @click="testCategory = category">
        {{ category }}
      </button>

      <section v-if="priceDataTests && priceDataTests.length > 0">
        <section
          v-for="(testResult, i) in priceDataTests" :key="i" class="test-card"
          :class="testResult.error === '' ? 'test-card-pass' : 'test-card-fail'" @click="testNumber = i"
        >
          {{ testResult.testName }} <span>({{ testResult.error === '' ? 'success' : 'failure' }})</span>
          <section>
            <section v-if="testResult.error !== ''">
              <button class="btn" @click="showExpectedResults()">
                Wanted boxes: {{ testResult.expectedResultsMeta.length }}
              </button>
              <button class="btn" @click="showActualResults()">
                Have boxes: {{ testResult.actualResultsMeta.length }}
              </button>
              <button class="btn" @click="debugResults(i)">
                Debug
              </button>
            </section>
            <section v-else>
              <button class="btn" @click="showActualResults()">
                Have boxes: {{ testResult.actualResultsMeta.length }}
              </button>
              <button class="btn" @click="clearBoxes()">
                Clear boxes
              </button>
              <button class="btn" @click="debugResults(i)">
                Debug
              </button>
            </section>
          </section>

          <section v-if="debugTestResults && testNumber == i">
            <section v-if="testCategory === 'Swing Identification'">
              <section v-if="testResult.error !== ''">
                Diff (wanted versus have):
              </section>
              <section id="diffedContent" />
              Candles:
              <ul style="margin-left: 1em">
                <li
                  v-for="(debugInfo, i2) in priceDataTestDebug?.states"
                  :key="i2"
                  @mouseover="highlightDebugInfo(
                    priceDataTestDebug?.priceData.candles, i2, debugInfo.currentBox ? [debugInfo.currentBox] : [])"
                  @mouseleave="unhighlightDebugInfo()"
                >
                  {{ i2 + 1 }}) state={{ debugInfo.stage }}, direction={{ debugInfo.direction }}
                </li>
              </ul>
            </section>
            <section v-else>
              <section v-if="testResult.error !== ''">
                Diff (wanted versus have):
              </section>
              <section id="diffedContent" />

              <section v-if="priceDataTestDebug?.states?.length">
                Trends:
                <ul style="font-size: 0.8em; margin-left: 1em">
                  <li
                    v-for="(trendMeta, j) in flattenTrends(
                      priceDataTestDebug?.priceData.candles, priceDataTestDebug?.states[0].trends)"
                    :key="j"
                    :title="stringifyTrend(trendMeta.trend)"
                    @mouseover="highlightDebugInfo(
                      priceDataTestDebug?.priceData.candles, trendMeta.trend, trendMeta.boxes, trendMeta)"
                    @mouseleave="unhighlightDebugInfo(true, trendMeta.createdBoxes)"
                  >
                    {{ trendMeta.prefix }} {{ trendMeta.trend.direction }} {{ trendMeta.suffix }}
                  </li>
                </ul>
              </section>

              Trend Hierarchy Parsing:
              <ul style="margin-left: 1em">
                <li v-for="(debugInfo, i2) in priceDataTestDebug?.states" :key="i2">
                  {{ debugInfo.stage }}) Trends:
                  <ul style="font-size: 0.8em; margin-left: 1em">
                    <li
                      v-for="(trendMeta, j) in flattenTrends(
                        priceDataTestDebug?.priceData.candles, debugInfo.trendStack)"
                      :key="j" :title="stringifyTrend(trendMeta.trend)"
                      @mouseover="highlightDebugInfo(
                        priceDataTestDebug?.priceData.candles, trendMeta.trend, trendMeta.boxes,
                        trendMeta, debugInfo.toIndex
                      )"
                      @mouseleave="unhighlightDebugInfo(true, trendMeta.createdBoxes)"
                    >
                      {{ trendMeta.prefix }} {{ trendMeta.trend.direction }} {{ trendMeta.suffix }}
                    </li>
                  </ul>
                </li>
              </ul>
            </section>
          </section>
        </section>
      </section>
    </section>
  </section>
</template>

<script setup lang="ts">
import { ref, computed, watch, defineAsyncComponent } from 'vue';
import { diffLines } from 'diff';
import {
  usePricedataStore, stringifyTrend, flattenTrends, convertSwingsToText, convertTrendsToText,
  convertCandlesToGraphCandles, DirectionUp,
} from '@/stores/exchanges/pricedata';
import {
  Trend, Candle, PriceDataSwingTestResult, PriceDataTrendTestResult, DebuggedState, PriceData,
  DisplayTrend, Box, ChartViewI,
} from '@/types/pricedata';
import { useWebSocketStore } from '@/stores/user/ws';
import { SeriesMarker, Time, IBox } from 'lightweight-charts-private';

const ChartView = defineAsyncComponent(() =>
  import('@/components/exchanges/symbol/ChartView.vue'),
);

// Store
const pricedataStore = usePricedataStore();
const wsStore = useWebSocketStore();

// Computed
const priceDataTests = computed<Array<PriceDataSwingTestResult | PriceDataTrendTestResult>> (
  () => pricedataStore.testResults[testCategory.value] || []);
const testCategories = computed<string[]> (() => Object.keys(pricedataStore.testResults));
const priceDataTestDebug = computed<DebuggedState>(() => pricedataStore.testResultsDebug);

// Variables
const testNumber = ref(-1); // Test number to show from the list
const testCategory = ref('');
const debugTestResults = ref(false);
const priceData = ref<PriceData>(null);
const chartRef = ref<ChartViewI>(null);

// Watchers
watch(testCategory, () => {
  testNumber.value = priceDataTests.value.length ? 0 : -1;
  renderTest();
});

watch(priceDataTests, () => {
  if (testNumber.value >= priceDataTests.value.length || testNumber.value < 0) {
    testNumber.value = 0;
  }
}, { deep: true });

watch(priceDataTestDebug, () => {
  const testResult = priceDataTests.value[testNumber.value];
  const display = document.getElementById('diffedContent');
  let before = '';
  let after = '';

  if (testResult instanceof PriceDataSwingTestResult) {
    before = convertSwingsToText(testResult.expectedResults);
    after = convertSwingsToText(testResult.actualResults);
  } else {
    before = convertTrendsToText(testResult.expectedResults);
    after = convertTrendsToText(testResult.actualResults);
  }

  if (testResult.error === '') {
    const span = document.createElement('span');

    span.innerHTML = after.replaceAll('\n', '<br />');
    span.innerHTML = span.innerHTML.replaceAll(' ', '&nbsp;');
    display.appendChild(span);

    return;
  }

  const diff = diffLines(before, after);

  const fragment = document.createDocumentFragment();

  diff.forEach((part) => {
    // green for additions, red for deletions, grey for common parts
    const color = part.added ? 'red' : (part.removed ? 'green' : 'grey');

    // Show only the diff for swings, and everything for trends (for easier debugging)
    if (testResult instanceof PriceDataTrendTestResult || color !== 'grey') {
      const span = document.createElement('span');

      span.style.color = color;
      span.innerHTML = part.value.replaceAll('\n', '<br />');
      span.innerHTML = span.innerHTML.replaceAll(' ', '&nbsp;');
      fragment.appendChild(span);
    }
  });

  display.appendChild(fragment);
}, { deep: true });

watch(testNumber, () => {
  debugTestResults.value = false;
  renderTest();
});

// Functions
const renderTest = () => {
  if (testNumber.value >= priceDataTests.value.length || testNumber.value < 0) {
    return;
  }

  const testResult = priceDataTests.value[testNumber.value];
  priceData.value = testResult.priceData;

  chartRef.value.setGraphCandles(convertCandlesToGraphCandles(testResult.priceData.candles));
  chartRef.value.setTimeframe(testResult.priceData.timeframe);
  chartRef.value.renderChartFixedScale();

  const boxColour = (testResult.error === '' ? '#0f0' : '#f00');
  showExpectedResults(boxColour);
};

const showExpectedResults = (boxColour = '#0f0') => {
  const testResult = priceDataTests.value[testNumber.value];
  debugTestResults.value = false;
  chartRef.value.removeAllRenderedBoxes();
  chartRef.value.addBoxesToGraph(testResult.expectedResultsMeta, boxColour);
};

const showActualResults = (boxColour = '#f00') => {
  const testResult = priceDataTests.value[testNumber.value];

  debugTestResults.value = false;
  chartRef.value.removeAllRenderedBoxes();
  chartRef.value.addBoxesToGraph(testResult.actualResultsMeta, boxColour);
};

const clearBoxes = () => {
  chartRef.value.removeAllRenderedBoxes();
};

const debugResults = (i: number) => {
  chartRef.value.removeAllRenderedBoxes();

  if (testNumber.value !== i) {
    // May have directly clicked debug when looking at another test. Ignore
    // the click, since testNumber needs to change first
    return;
  }

  if (debugTestResults.value) { // Already debugging these test results
    return;
  }

  wsStore.send({
    category: 'run_price_data_test_debugger',
    body: JSON.stringify({
      testCategory: testCategory.value,
      testNumber: testNumber.value,
    }),
  });

  debugTestResults.value = true;
};

const runPriceDataTests = () => {
  wsStore.send({
    category: 'run_price_data_tests',
    body: '',
  });
};

const highlightDebugInfo = (
  candles: Candle[], val: number | Trend, boxes: Box[] = [], boxesTracker: DisplayTrend = null, toIndex = -1,
) => {
  if (boxes.length > 0) {
    chartRef.value.addBoxesToGraph(boxes, '#f00', boxesTracker);
  }

  let j = 0;
  let arrowBelow = false;
  // TODO is this correct
  const markers: SeriesMarker<Time>[] = [];

  if (typeof val !== 'number') { // trend or swing
    // Find the extreme candle of the from..to indexes
    j = val.toIndex;
    arrowBelow = (val.direction === DirectionUp);
    markers.push({
      time: String(candles[val.fromIndex].startTime),
      position: arrowBelow ? 'belowBar' : 'aboveBar',
      color: 'orange',
      shape: arrowBelow ? 'arrowUp' : 'arrowDown',
      text: `${val.fromIndex}`,
    });

    // If it's a trend, invert the second arrow for better readability
    if (val.swings?.length || val.trends?.length) {
      arrowBelow = !arrowBelow;
    }

    /*
    if (arrowBelow) {
      let min = parseFloat(candles[i].low);

      for (let n = val.fromIndex + 1; n <= val.toIndex; ++n) {
        const cmin = parseFloat(candles[n].low);

        if (cmin < min) {
          min = cmin;
          i = n;
        }
      }
    } else {
      let max = parseFloat(candles[i].high);

      for (let n = val.fromIndex + 1; n <= val.toIndex; ++n) {
        const cmax = parseFloat(candles[n].high);

        if (cmax > max) {
          max = cmax;
          i = n;
        }
      }
    }
    */
  } else {
    j = val;
  }

  if (toIndex !== -1) {
    markers.push({
      time: String(candles[toIndex].startTime),
      position: !arrowBelow ? 'belowBar' : 'aboveBar',
      color: 'orange',
      shape: !arrowBelow ? 'arrowUp' : 'arrowDown',
      text: `Current end index (${toIndex})`,
    });
  }

  markers.push({
    time: String(candles[j].startTime),
    position: arrowBelow ? 'belowBar' : 'aboveBar',
    color: 'orange',
    shape: arrowBelow ? 'arrowUp' : 'arrowDown',
    text: `${j}`,
  });

  markers.sort((a, b) => a.time > b.time ? 1 : -1); // Markers now need to be ordered asc by time
  chartRef.value.setMarkers(markers);
};

const unhighlightDebugInfo = (removeBoxes = true, boxes: IBox[] = null) => {
  if (removeBoxes) {
    if (boxes !== null) {
      chartRef.value.removeRenderedBoxes(boxes);
    } else {
      chartRef.value.removeAllRenderedBoxes();
    }
  }

  chartRef.value.setMarkers([]);
};
</script>

<style lang="scss">
.test-card {
  margin-bottom: 0.1em;
}

.test-card-pass {
  background-color: #0f0;
}

.test-card-fail {
  background-color: #f00;
}

#diffedContent {
  background-color: #ccc;
}

.debugData {
  max-height: 20em;
  overflow-y: scroll;
  background-color: #ddd;
  margin-right: 1em;
  margin-bottom: 1em;
}

.debugData ul {
  margin-left: 1em;
  margin-bottom: 0.2em;
}

.btn {
  padding: 0.2em;
  background-color: #666;
  margin: 0.1em;
}
</style>
