import { defineStore } from 'pinia';
import Config from '@/config';
import { parsePriceData } from '@/stores/exchanges/pricedata';
import { PriceDataServer } from '@/types/pricedata';
import { IgState } from '@/types/ig';
import { useUserStore } from '@/stores/user/user';
import { createRequestData, performHttpRequest } from '@/utilities';
import { AccountMeta } from '@/types/orders';

export const useIgStore = defineStore('ig', {
  state: (): IgState => ({
    priceData: null,
  }),
  actions: {
    setPriceData(priceData: PriceDataServer) {
      this.priceData = parsePriceData(priceData);
    },
    resetPriceData() {
      this.priceData = null;
    },
    fetchIgPriceData(accountMeta: AccountMeta, fromUnixTs: number, toUnixTs: number, market: string): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('GET', token, '');

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${accountMeta.exchangeName}/exchangeTypes/${accountMeta.exchangeType}/`
        + `igpricedata?from=${fromUnixTs}&to=${toUnixTs}&market=${market}`,
        requestInfo,
        'receive',
        'price data',
      );
    },
  },
});
