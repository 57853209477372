import { defineStore } from 'pinia';
import { LogFileServerResp, LogFileNamesServerResp } from '@/types/user';
import { ServerLogsState } from '@/types/serverLogs';

export const useServerLogsStore = defineStore('serverLogs', {
  state: (): ServerLogsState => ({
    fileNames: [],
    file: [],
  }),
  actions: {
    setLogFile(resp: LogFileServerResp) {
      this.file = resp.logFile;
    },
    setLogsFileNames(resp: LogFileNamesServerResp) {
      this.fileNames = resp.logsFileNames;
    },
  },
});
