<template>
  <div class="grid">
    <div class="col" :class="sidebarOpen ? 'col-2' : 'col-closed'">
      <ExchangeFilter :open="sidebarOpen" />
    </div>
    <div class="col col-remaining">
      <OrdersTable class="mb-4" :title="'Orders'" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useSidebarStore } from '@/stores/sidebar';
import OrdersTable from '@/components/exchanges/orders/OrdersTable.vue';
import ExchangeFilter from '@/components/exchanges/sidebar/ExchangeFilter.vue';

// Stores
const sidebarStore = useSidebarStore();

// Computed
const sidebarOpen = computed(() => sidebarStore.getSidebarOpen);
</script>

<style lang="scss">

</style>
