<template>
  <section class="sidebar__setting">
    <SelectTags :file-tags="tags" @update-selected-tags="updateSelectedTags" />
  </section>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SelectTags from '@/components/resources/content/SelectTags.vue';

// Store
const route = useRoute();
const router = useRouter();

// Computed
const tags = computed<string[]>(() => route.query.tags ? String(route.query.tags).split(',') : []);

// Variables
const selectedTags = ref([]);

// Functions
const updateTagsQuery = (): void => {
  const query = Object.assign({}, route.query),
    tags = selectedTags.value.join(',');

  if (tags == '') {
    delete query.tags;
  } else{
    query.tags = tags;
  }

  void router.push({ query: query });
};

const updateSelectedTags = (selectedTagsNew: string[]): void => {
  selectedTags.value = selectedTagsNew;
  updateTagsQuery();
};
</script>

<style lang="scss" scoped>
.tags-div {
  height: 300px;
  overflow: scroll;
}
</style>
