<template>
  <div>
    <MdEditor
      v-model="text" :theme="theme" language="en-US" placeholder="Comments here!" style="height: 200px"
      :preview="false"
    />

    <div class="flex flex-row justify-end">
      <button
        type="button" class="button" :disabled="requestProcessing" :class="{ 'btn-disabled': requestProcessing }"
        @click="createComment()"
      >
        Create Comment
      </button>
    </div>

    <p class="warning-text">{{ invalidInputWarning }}</p>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, defineAsyncComponent } from 'vue';
import { Comment } from '@/types/resources';
import { useUserStore } from '@/stores/user/user';
import { useCommentsStore } from '@/stores/resources/comments';
import { useUserSettingsStore } from '@/stores/user/settings';
import { useRoute } from 'vue-router';

// Lazy load
const MdEditor = defineAsyncComponent(() => import('md-editor-v3').then(module => module.MdEditor));

const warningMsg = '*Comment body can\'t be empty';

// Store
const userSettingsStore = useUserSettingsStore();
const userStore = useUserStore();
const commentsStore = useCommentsStore();
const route = useRoute();

// Computed
const categoryId = computed(() => route.params.categoryId ? String(route.params.categoryId): '');
const fileId = computed(() => route.query.fileId ? String(route.query.fileId): '');
const fileType = computed(() => route.params.fileType ? String(route.params.fileType) : '');
const userEmail = computed(() => userStore.getUserEmail);
const theme = computed(() => {
  const t = userSettingsStore.getTheme;
  return t.includes('dark') ? 'dark' : 'light';
});

// Variables
const text = ref('');
const invalidInputWarning = ref('');
const requestProcessing = ref(false);

// Watchers
watch(text, (newVal) => {
  if (newVal && newVal.length > 0 && invalidInputWarning.value.length > 0) {
    invalidInputWarning.value = '';
  }
}, { deep: true });

// Functions
const createComment = async (): Promise<void> => {
  if (text.value.length == 0) {
    invalidInputWarning.value = warningMsg;
    return;
  }

  const comment = new Comment('', '', userEmail.value, '', text.value, new Date(), new Date(), 0, []);
  requestProcessing.value = true;
  await commentsStore.createComment(categoryId.value, fileType.value, fileId.value, comment);
  requestProcessing.value = false;
};
</script>

<style lang="scss" scoped>
.button {
  margin: 5px 0;
}
</style>
