<template>
  <div class="dark:bg-core-900 h-screen flex content-center items-center">
    <div class="card w-1/2">
      <h2 class="heading m-0 ml-3">Login</h2>

      <div v-if="!multiFactorAuthErr">
        <input v-model="email" class="mx-auto text-black" type="text" name="email" placeholder="Email..." />
        <input
          v-model="password" class="mx-auto text-black" type="password" name="password" placeholder="Password..."
        />

        <div class="text-center">
          <button class="btn-tw" @click="loginUser()">Log in</button>
        </div>
      </div>

      <div v-else>
        <input
          v-model="verificationCode" class="mx-auto text-black"
          type="password" name="text" placeholder="Verification Code"
        />
        <div class="text-center">
          <button class="btn-tw" @click="resetForm()">Cancel</button>
          <button class="btn-tw" @click="loginUser2Auth()">Log in 2 auth</button>
        </div>
      </div>

      <div v-if="errorMsg"><p class="warning-text">*{{ errorMsg }}</p></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Auth } from '@/firebaseAuth';
import { useUserStore } from '@/stores/user/user';
import { MultiFactorError } from 'firebase/auth';

// Stores
const userStore = useUserStore();

// Variables
const email = ref('');
const password = ref('');
const verificationCode = ref('');
const multiFactorAuthErr = ref<MultiFactorError>(null);
const errorMsg = ref('');

// Functions
const loginUser = async () => {
  const err = await userStore.loginUser({ email: email.value, password: password.value });
  const typedErr = err as MultiFactorError;

  if (typedErr != null) {
    if (typedErr.code === 'auth/multi-factor-auth-required') {
      multiFactorAuthErr.value = typedErr;
      errorMsg.value = '';
    } else {
      errorMsg.value = typedErr.message;
    }
  } else {
    location.reload();
  }
};

const loginUser2Auth = async () => {
  try {
    await Auth.login2Auth(multiFactorAuthErr.value, verificationCode.value);
    errorMsg.value = '';
    location.reload();
  } catch(error: unknown) {
    const typedError = error as MultiFactorError;
    errorMsg.value = typedError.message;
  }
};

const resetForm = () => {
  email.value = '';
  password.value = '';
  verificationCode.value = '';
  multiFactorAuthErr.value = null;
};
</script>

<style lang="scss" scoped>
input {
  padding: 5px;
  display: block;
  width: 50%;
  max-width: 500px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
