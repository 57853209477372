<template>
  <section class="sidebar__setting">
    <label class="sidebar__setting__label">Type</label>
    <select v-model="fileType" class="vtable-header__item__input">
      <option :value="FILE_TYPE.VIDEO">{{ FILE_TYPE.VIDEO }}</option>
      <option :value="FILE_TYPE.ARTICLE">{{ FILE_TYPE.ARTICLE }}</option>
    </select>
  </section>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { FILE_TYPE } from '@/types/resources';
import { useRoute, useRouter } from 'vue-router';

// Store
const route = useRoute();
const router = useRouter();

// Variables
const fileType = ref('');

// Watchers
watch(fileType, (newVal: string, oldVal: string) => {
  if (oldVal == '' || newVal == '') return;

  void router.push({
    path: `/resources/categories/${String(route.params.categoryId)}/${fileType.value}`,
  });
});

// Methods
onMounted(() => {
  fileType.value = String(route.params.fileType);
});
</script>

<style lang="scss" scoped></style>
