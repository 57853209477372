<template>
  <template v-if="props.wallet.aggregationKeys.length === props.depth">
    <WalletTable
      :wallet="props.wallet" :min-balance="props.minBalance" :min-available-balance="props.minAvailableBalance"
      :asset-aggr="props.assetAggr" :title="props.title"
    />
  </template>
  <template v-else>
    <WalletView
      :wallet="wallet" :depth="depth + 1" :min-balance="minBalance" :min-available-balance="minAvailableBalance"
      :asset-aggr="assetAggr" :title="props.title"
    />
  </template>
</template>

<script setup lang="ts">
import WalletTable from '@/components/exchanges/portfolio/WalletTable.vue';
import { WalletType } from '@/types/wallets';

const props = withDefaults(defineProps<{
  wallet: WalletType,
  depth: number,
  minBalance: number,
  minAvailableBalance: number,
  assetAggr: boolean,
  title: string
}>(), {
  wallet: null,
  depth: 0,
  minBalance: 0,
  minAvailableBalance: 0,
  assetAggr: false,
  title: '',
});
</script>

<style lang="scss" scoped>
.subwallet {
  background: #333;
  margin-left: 1em;
  right: 1em;
}
.displayInline {
  display: inline-block;
}
</style>
