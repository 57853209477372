<template>
  <div class="dark:bg-core-900 h-screen flex content-center items-center">
    <div class="card w-1/2">
      <h2 class="heading m-0 ml-3">Resources Login</h2>
      <div v-if="!requestProcessing" class="text-center">
        <button
          v-if="resourcesAuthLink" :class="{ 'btn-disabled': requestProcessing }"
          class="btn-tw" :disabled="requestProcessing"
        >
          <p v-if="requestProcessing">Authenticate</p>
          <a v-else :href="resourcesAuthLink">Authenticate</a>
        </button>
        <!-- TODO Why do I hit this? -->
        <p v-else>Missing youtube-cred.json</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useResourcesStore } from '@/stores/resources/resources';
import { ResourceWrapper } from '@/types/resources';

// Store
const resourcesStore = useResourcesStore();
const route = useRoute();
const router = useRouter();

// Computed
const resourcesAuthLink = computed(() => resourcesStore.resourcesAuthLink);

// Variables
const requestProcessing = ref(false);

// Watcher
watch(() => resourcesStore.isLoggedIn, (newVal) => {
  if (newVal) {
    void router.replace({ path: '/resources/' });
  }
}, { deep: true });

// Vue Lifecycle Functions
onMounted(async () => {
  const code = route.query.code ? String(route.query.code) : '';

  if (code != '') {
    requestProcessing.value = true;
    const resourceWrapper = new ResourceWrapper('');
    resourceWrapper.code = code;
    await resourcesStore.createAuthCode(resourceWrapper);
    requestProcessing.value = false;
  } else {
    requestProcessing.value = true;
    await resourcesStore.getAuthLink();
    requestProcessing.value = false;
  }
});
</script>

<style lang="scss" scoped></style>
