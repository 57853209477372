import { defineStore } from 'pinia';
import { useNotificationsStore } from '@/stores/user/notifications';
import { NotificationType, NOTIFICATION_TYPE } from '@/types/user';
import { REQUEST_STATUS, RequestState, RequestStatusServerResp } from '@/types/general';

export const useRequestStore = defineStore('request', {
  state: (): RequestState => ({
    requestStatus: {},
  }),
  actions: {
    setRequestStatus(id: string, status: REQUEST_STATUS) {
      this.requestStatus[id] = status;
    },
    removeRequestStatus(id: string) {
      delete this.requestStatus[id];
    },
    updateRequestStatus(body: RequestStatusServerResp) {
      if (body.statusId in this.requestStatus) {
        const notificationStore = useNotificationsStore();
        const notification = new NotificationType(
          body.statusCode >= 400 ? NOTIFICATION_TYPE.ERROR : NOTIFICATION_TYPE.SUCCESS,
          body.message,
        );
        notificationStore.addNotification(notification);

        const status = body.statusCode >= 400 ? REQUEST_STATUS.UNSUCCESSFUL : REQUEST_STATUS.SUCCESSFUL;
        this.setRequestStatus(body.statusId, status);
      }
    },
  },
});

