<template>
  <div class="flex flex-row">
    <div class="col col-4 text-center panel">
      <StrategyForm :request-processing="requestProcessing" @set-order-strategy="setOrderStrategy" />

      <div class="w-full text-center">
        <button :class="btnClass" :disabled="btnDisabled" @click="create()">
          Create
        </button>
      </div>

      <div v-if="requestProcessing" class="mx-auto text-center my-2">
        <img class="spin-animation" :src="spinningCircle" />
        Creating...
      </div>
    </div>

    <div class="col col-8 text-center panel">
      <StrategiesTable />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import StrategyForm from '@/components/exchanges/strategies/StrategyForm.vue';
import StrategiesTable from '@/components/exchanges/strategies/StrategiesTable.vue';
import { OrderStrategyType } from '@/types/order_strategies';
import { useRequestStore } from '@/stores/request';
import { useOrderStrategiesStore } from '@/stores/exchanges/order_strategies';
import { REQUEST_STATUS } from '@/types/general';
import { validOrderStrategy } from '@/utilities';
import spinningCircle from '@/assets/spinning-circle.svg';

// Stores
const requestStore = useRequestStore();
const orderStrategiesStore = useOrderStrategiesStore();

// Computed
const btnDisabled = computed(() => {
  return !validOrderStrategy(orderStrategyType.value) || requestProcessing.value;
});

const btnClass = computed(() => {
  return !btnDisabled.value ? 'order-form-btn' : 'order-form-btn-disabled';
});

// Reactive
const orderStrategyType = ref(new OrderStrategyType());
const requestProcessing = ref(false);
const statusId = ref('');

// Watchers
watch(() => requestStore.requestStatus, (newVal) => {
  if (newVal && statusId.value in newVal && newVal[statusId.value] !== REQUEST_STATUS.PENDING) {
    requestStore.removeRequestStatus(statusId.value);
    statusId.value = '';
    requestProcessing.value = false;
  }
}, { deep: true });

// Methods
const setOrderStrategy = (orderStrategyTypeParam: OrderStrategyType) => {
  orderStrategyType.value = orderStrategyTypeParam;
};

const create = () => {
  void orderStrategiesStore.createOrderStrategyHttp(orderStrategyType.value);
};
</script>

<style lang="scss" scoped>

</style>
