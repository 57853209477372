<template>
  <div class="grid">
    <div v-if="showSidebar" class="col" :class="sidebarOpen ? 'col-2' : 'col-closed'">
      <ResourcesFilter :open="sidebarOpen" />
    </div>

    <CategoryContent v-if="fileId" class="col col-remaining" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import ResourcesFilter from '@/components/resources/sidebar/ResourcesFilter.vue';
import { useSidebarStore } from '@/stores/sidebar';
import CategoryContent from '@/components/resources/content/CategoryContent.vue';

const sidebarStore = useSidebarStore();
const route = useRoute();

const sidebarOpen = computed(() => sidebarStore.getSidebarOpen);
const showSidebar = computed(() => {
  const excludedRoutes = ['/resources/categories', '/resources/login'];
  return !excludedRoutes.includes(route.path);
});
const fileId = computed(() => route.query.fileId ? String(route.query.fileId) : '');

</script>
