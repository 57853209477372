<template>
  <div class="grid">
    <div class="panel m-2 col col-2 hand" @click="urlRedirect('/exchanges')">
      <div class="panel__header">
        <p class="panel__header__title">New Pairs: {{ newPairsTotal }}</p>
      </div>
    </div>

    <div class="panel m-2 col col-2 hand" @click="urlRedirect('/exchanges/positions')">
      <div class="panel__header">
        <p class="panel__header__title">Positions Open: {{ positionsTotal }}</p>
      </div>
    </div>

    <div class="panel m-2 col col-2 hand" @click="urlRedirect('/exchanges/positions')">
      <div class="panel__header">
        <p class="panel__header__title">Fills Open: {{ fillsTotal }}</p>
      </div>
    </div>

    <div
      v-for="orderCategory in Object.keys(ordersByCategoryByStatus)" :key="orderCategory"
      class="panel m-2 col col-2 hand" @click="urlRedirect('/exchanges/orders')"
    >
      <div
        v-for="(ordersByStatus, j) in ordersByCategoryByStatus[orderCategory] ?
          [ordersByCategoryByStatus[orderCategory]] : []"
        :key="j" class="panel__header"
      >
        <p class="panel__header__title">
          {{ orderCategory }} (
          {{
            ordersByStatus[ORDER_STATUS.UNFILLED]?.length +
              ordersByStatus[ORDER_STATUS.PARTIALLY_FILLED]?.length +
              ordersByStatus[ORDER_STATUS.FILLED]?.length +
              ordersByStatus[ORDER_STATUS.CANCELLED]?.length +
              ordersByStatus[ORDER_STATUS.FAILED]?.length +
              ordersByStatus[ORDER_STATUS.EXPIRED]?.length +
              ordersByStatus[ORDER_STATUS.UNKNOWN]?.length +
              ordersByStatus[ORDER_STATUS.INACTIVE_UNKNOWN]?.length
          }}:
          <span :title="`Unfilled: ${ordersByStatus[ORDER_STATUS.UNFILLED]?.length}`">
            UF: {{ ordersByStatus[ORDER_STATUS.UNFILLED].length }}
          </span>,
          <span :title="`Partially filled: ${ordersByStatus[ORDER_STATUS.PARTIALLY_FILLED].length}`">
            PF: {{ ordersByStatus[ORDER_STATUS.PARTIALLY_FILLED].length }}
          </span>,
          <span :title="`Filled: ${ordersByStatus[ORDER_STATUS.FILLED].length}`">
            FF: {{ ordersByStatus[ORDER_STATUS.FILLED].length }}
          </span>,
          <span :title="`Cancelled: ${ordersByStatus[ORDER_STATUS.CANCELLED].length}`">
            C: {{ ordersByStatus[ORDER_STATUS.CANCELLED].length }}
          </span>
          <span :title="`Failed: ${ordersByStatus[ORDER_STATUS.FAILED].length}`">
            F: {{ ordersByStatus[ORDER_STATUS.FAILED].length }}
          </span>
          <span :title="`Failed: ${ordersByStatus[ORDER_STATUS.EXPIRED].length}`">
            E: {{ ordersByStatus[ORDER_STATUS.EXPIRED].length }}
          </span>
          <span :title="`Failed: ${ordersByStatus[ORDER_STATUS.UNKNOWN].length}`">
            U: {{ ordersByStatus[ORDER_STATUS.UNKNOWN].length }}
          </span>
          <span :title="`Failed: ${ordersByStatus[ORDER_STATUS.INACTIVE_UNKNOWN].length}`">
            IU: {{ ordersByStatus[ORDER_STATUS.INACTIVE_UNKNOWN].length }}
          </span>
          )
        </p>
      </div>
    </div>

    <div class="panel m-2 col col-2 hand" @click="urlRedirect('/exchanges/portfolio')">
      <div class="panel__header">
        <p class="panel__header__title">Balance Free: {{ walletsBalanceFreePercent }}%</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { usePositionsStore } from '@/stores/exchanges/positions';
import { useUserSettingsStore } from '@/stores/user/settings';
import { useOrdersStore } from '@/stores/exchanges/orders';
import { useWalletsStore } from '@/stores/exchanges/wallets';
import { ORDER_STATUS, OrderType } from '@/types/orders';
import { PositionType } from '@/types/positions';
import { useRouter } from 'vue-router';
import { EXCHANGE_TYPE } from '@/types/exchange';

// Stores
const instrumentsStore = useInstrumentsStore();
const positionsStore = usePositionsStore();
const ordersStore = useOrdersStore();
const userSettingsStore = useUserSettingsStore();
const walletsStore = useWalletsStore();
const router = useRouter();

// Computed
const walletsBalanceFreePercent = computed<string>(() => {
  const wallets = walletsStore.wallets;
  let balanceFree = 0;
  let balanceTotal = 0;

  for(const accountId in wallets) {
    const accountwallets = wallets[accountId];
    for(const walletId in accountwallets) {
      const wallet = accountwallets[walletId];
      for(const asset in wallet.balances) {
        const balance = wallet.balances[asset];

        const chosenAssetAvailable = userSettingsStore.getChosenAssetValue(
          wallet.exchangeName, asset, Number(balance.available), false,
        );

        const chosenAssetTotal = userSettingsStore.getChosenAssetValue(
          wallet.exchangeName, asset, Number(balance.total), false,
        );

        balanceFree += Number(chosenAssetAvailable);
        balanceTotal += Number(chosenAssetTotal);
      }
    }
  }

  if (balanceTotal == 0) {
    return '0';
  }

  return ((balanceFree / balanceTotal) * 100).toFixed(2);
});

const ordersByCategoryByStatus = computed<Record<string, Record<string, OrderType[]>>>(() => {
  const orders = ordersStore.getOrders();
  const retOrders: Record<string, Record<string, OrderType[]>> = {};

  for (const exchangeName in orders) {
    const exchangeOrders = orders[exchangeName];

    for (const accountId in exchangeOrders.accounts) {
      const account = exchangeOrders.accounts[accountId];

      for (const orderId in account.orders) {
        const order = account.orders[orderId];

        if (!(order.category in retOrders)) {
          retOrders[order.category] = {
            [ORDER_STATUS.UNFILLED]: [],
            [ORDER_STATUS.PARTIALLY_FILLED]: [],
            [ORDER_STATUS.FILLED]: [],
            [ORDER_STATUS.CANCELLED]: [],
            [ORDER_STATUS.FAILED]: [],
            [ORDER_STATUS.EXPIRED]: [],
            [ORDER_STATUS.UNKNOWN]: [],
            [ORDER_STATUS.INACTIVE_UNKNOWN]: [],
          };
        }

        retOrders[order.category][order.status].push(order);
      }
    }
  }

  return retOrders;
});

const newPairsTotal = computed((): number => {
  const instruments = instrumentsStore.instruments;

  let counter = 0;

  for (const exchangeName in instruments) {
    for (const exchangeType in instruments[exchangeName]) {
      for (const symbol in instruments[exchangeName][exchangeType]) {
        const instrument = instruments[exchangeName][exchangeType][symbol],
          d = new Date(),
          pD = d.getDate() - 7,
          releaseTs = instrument.released * 1000;

        d.setDate(pD);

        if (releaseTs > +d) {
          counter++;
        }
      }
    }
  }

  return counter;
});

const positions = computed((): Record<string, PositionType> => {
  return positionsStore.getPositions('', '', '');
});

const positionsTotal = computed((): number => {
  let counter = 0;

  for(const key in positions.value) {
    const position = positions.value[key];

    if (position.exchangeType == EXCHANGE_TYPE.DERIVATIVES) {
      counter++;
    }
  }

  return counter;
});

const fillsTotal = computed((): number => {
  let counter = 0;

  for(const key in positions.value) {
    const position = positions.value[key];

    if (position.exchangeType == EXCHANGE_TYPE.SPOT) {
      counter++;
    }
  }

  return counter;
});

// Functions
const urlRedirect = (url: string) => {
  void router.push(url);
};
</script>

<style lang="scss">
.content {
  color: #fff;
}
</style>
