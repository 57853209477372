<template>
  <div class="card">
    <p>Pair coverage</p>

    <section class="table-heading-div-tw">
      <h3 class="table-heading-tw">Instruments (results: {{ symbolsData.length }})</h3>
    </section>

    <section>
      <table class="table-tw">
        <thead class="table-thead-tw">
          <tr class="hand">
            <th class="table-th-tw">
              <select v-model="exchangeNameFilter" name="exchangeNameFilter" class="table-input-tw">
                <option value="">Exchange Name</option>
                <option value="" disabled>--</option>
                <option v-for="exchangeName in orderedExchangeNames" :key="exchangeName">{{ exchangeName }}</option>
              </select>
            </th>
            <th class="table-th-tw">
              <select v-model="exchangeTypeFilter" name="exchangeTypeFilter" class="table-input-tw">
                <option value="">Exchange Type</option>
                <option value="" disabled>--</option>
                <option>Spot</option>
                <option>Derivatives</option>
              </select>
            </th>
            <th class="table-th-tw">
              <select v-model="accountFilter" name="accountFilter" class="table-input-tw">
                <option value="">Account</option>
                <option value="" disabled>--</option>
                <option v-for="account in accounts" :key="account.id" :value="account.id">{{ account.name }}</option>
              </select>
            </th>
            <th class="table-th-tw">
              <select v-model="baseAssetFilter" name="baseAssetFilter" class="table-input-tw">
                <option value="">Base Asset</option>
                <option value="" disabled>--</option>
                <option v-for="baseAsset in baseAssets.sort()" :key="baseAsset">{{ baseAsset }}</option>
              </select>
            </th>
            <th class="table-th-tw">
              <select v-model="quoteAssetFilter" name="quoteAssetFilter" class="table-input-tw">
                <option value="">Quote Asset</option>
                <option value="" disabled>--</option>
                <option v-for="quoteAsset in quoteAssets.sort()" :key="quoteAsset">{{ quoteAsset }}</option>
              </select>
            </th>
            <th class="table-th-tw">
              <select v-model="forexFilter" name="forexFilter" class="table-input-tw">
                <option value="">Include FX Pairs</option>
                <option value="" disabled>--</option>
                <option value="exclude">Exclude FX Pairs</option>
                <option value="only">Only FX Pairs</option>
              </select>
            </th>
            <th class="table-th-tw">
              <button class="btn-tw" @click="checkPairCoverage()">Check Pair Coverage</button>
            </th>
          </tr>
        </thead>
      </table>
    </section>

    <section class="table-wrap-tw">
      <table v-if="symbolsData.length" class="table-tw">
        <thead class="table-thead-tw">
          <tr>
            <th class="table-th-tw">Exchange Name</th>
            <th class="table-th-tw">Exchange Type</th>
            <th class="table-th-tw">Symbol</th>
            <th class="table-th-tw">Has Buy?</th>
            <th class="table-th-tw">Has Sell?</th>
          </tr>
        </thead>
        <tbody class="table-tbody-tw">
          <tr v-for="(symbol, i) in symbolsData" :key="i" class="table-tr-tw">
            <td class="table-td-tw">{{ symbol.exchangeName }}</td>
            <td class="table-td-tw">{{ symbol.exchangeType }}</td>
            <td class="table-td-tw">
              <router-link
                class="table-link-tw" :title="symbol.symbol"
                :to="{
                  path: `/exchanges/${symbol.exchangeName}/${symbol.exchangeType}/${encodeURIComponent(symbol.symbol)}`,
                }"
              >
                {{ symbol.consistentSymbol }}
              </router-link>
            </td>
            <td :class="symbol.hasBuy ? 'greenBg' : 'redBg'">{{ symbol.hasBuy }}</td>
            <td :class="symbol.hasSell ? 'greenBg' : 'redBg'">{{ symbol.hasSell }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { useOrdersStore } from '@/stores/exchanges/orders';
import { forexAssets } from '@/stores/user/settings';

// Store
const instrumentsStore = useInstrumentsStore();
const ordersStore = useOrdersStore();
const accountManagementStore = useAccountManagementStore();

// Computed
const instruments = computed(() => instrumentsStore.instruments);
const orders = computed(() => ordersStore.exchanges);
const accounts = computed(() => accountManagementStore.getAccounts(exchangeNameFilter.value));
const orderedExchangeNames = computed(() => accountManagementStore.getOrderedExchangesNames());

// Variables
const symbolsData = ref([]);
const exchangeNameFilter = ref('');
const exchangeTypeFilter = ref('');
const accountFilter = ref('');
const baseAssets = ref<string[]>([]);
const baseAssetFilter = ref('');
const quoteAssets = ref<string[]>([]);
const quoteAssetFilter = ref('');
const forexFilter = ref('');
const search = ref(false);

// Watchers
watch(instruments, () => {
  const baseAssetsNew: Record<string, boolean> = {};
  const quoteAssetsNew: Record<string, boolean> = {};

  for (const exchangeName in instruments.value) {
    for (const exchangeType in instruments.value[exchangeName]) {
      for (const symbolName in instruments.value[exchangeName][exchangeType]) {
        const instrument = instruments.value[exchangeName][exchangeType][symbolName];

        baseAssetsNew[instrument.baseAsset] = true;
        quoteAssetsNew[instrument.quoteAsset] = true;
      }
    }
  }

  baseAssets.value = Object.keys(baseAssetsNew);
  quoteAssets.value = Object.keys(quoteAssetsNew);

  if (search.value) {
    checkPairCoverage();
  }
}, { deep: true, immediate: true });

watch(orders, () => {
  if (search.value) {
    checkPairCoverage();
  }
}, { deep: true });

watch(exchangeNameFilter, () => {
  search.value = false;
});

watch(exchangeTypeFilter, () => {
  search.value = false;
});

watch(accountFilter, () => {
  search.value = false;
});

watch(baseAssetFilter, () => {
  search.value = false;
});

watch(quoteAssetFilter, () => {
  search.value = false;
});

// Functions
const checkPairCoverage = () => {
  const ordersSummary: Record<string, Record<string, Record<string, Record<string, boolean>>>> = {};

  search.value = true;
  symbolsData.value = [];

  for (const exchangeName in orders.value) {
    if (exchangeNameFilter.value !== '' && exchangeNameFilter.value !== exchangeName) {
      continue;
    }

    for (const accountId in orders.value[exchangeName].accounts) {
      if (accountFilter.value !== '' && accountFilter.value !== accountId) {
        continue;
      }

      const account = orders.value[exchangeName].accounts[accountId];

      for (const ordersId in account.orders) {
        const order = account.orders[ordersId];

        if (exchangeTypeFilter.value !== '' && exchangeTypeFilter.value !== order.exchangeType) {
          continue;
        }

        if (ordersSummary[exchangeName] === undefined) {
          ordersSummary[exchangeName] = {};
        }

        if (ordersSummary[exchangeName][order.exchangeType] === undefined) {
          ordersSummary[exchangeName][order.exchangeType] = {};
        }

        if (ordersSummary[exchangeName][order.exchangeType][order.symbol] === undefined) {
          ordersSummary[exchangeName][order.exchangeType][order.symbol] = {};
        }

        ordersSummary[exchangeName][order.exchangeType][order.symbol][order.side] = true;
      }
    }
  }

  for (const exchangeName in instruments.value) {
    if (exchangeNameFilter.value !== '' && exchangeNameFilter.value !== exchangeName) {
      continue;
    }

    for (const exchangeType in instruments.value[exchangeName]) {
      if (exchangeTypeFilter.value !== '' && exchangeTypeFilter.value !== exchangeType) {
        continue;
      }

      for (const symbolName in instruments.value[exchangeName][exchangeType]) {
        const instrument = instruments.value[exchangeName][exchangeType][symbolName];

        if (baseAssetFilter.value !== '' && baseAssetFilter.value !== instrument.baseAsset) {
          continue;
        }

        if (quoteAssetFilter.value !== '' && quoteAssetFilter.value !== instrument.quoteAsset) {
          continue;
        }

        const [baseAsset, quoteAsset] = instrument.consistentSymbol.split('-');

        if (forexFilter.value === 'only') {
          if (!forexAssets.includes(baseAsset) || !forexAssets.includes(quoteAsset)) {
            continue;
          }
        } else if (forexFilter.value === 'exclude') {
          if (forexAssets.includes(baseAsset) && forexAssets.includes(quoteAsset)) {
            continue;
          }
        }

        symbolsData.value.push({
          exchangeName: exchangeName,
          exchangeType: exchangeType,
          symbol: symbolName,
          consistentSymbol: instrument.consistentSymbol,
          hasBuy: ordersSummary[exchangeName]?.[exchangeType]?.[symbolName]?.['buy'] === true,
          hasSell: ordersSummary[exchangeName]?.[exchangeType]?.[symbolName]?.['sell'] === true,
        });
      }
    }
  }
};
</script>

<style></style>
