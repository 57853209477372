import { defineStore } from 'pinia';
import { ClientLogsState, ClientLogType, FilesCacheType } from '@/types/clientLogs';
import { LOG_SEVERITY } from '@/types/serverLogs';
import { useWebSocketStore } from '@/stores/user/ws';


const filesCache: FilesCacheType = new FilesCacheType();

export const useClientLogsStore = defineStore('clientLogs', {
  state: (): ClientLogsState => ({
    logs: [],
    logThrottler: {}, // log.key => last logged timestamp (ms)
  }),
  actions: {
    addLog(log: ClientLogType): void {
      this.logs.push(log);

      useWebSocketStore().send({
        category: 'client_log',
        body: JSON.stringify(log),
      });
    },
    addNewLog(log: ClientLogType, throttleMs: number): void {
      // Only enable logging in the test environment. This is partly for performance, but also because true
      // line setting (via the raw source files) is only available to the dev environment.
      if (!import.meta.env.DEV) {
        // Could still look to send error logs back
        return;
      }

      if (throttleMs !== 0) {
        if (log.key in this.logThrottler && log.timestamp - this.logThrottler[log.key] < throttleMs) {
          // Just drop the log for now
          return;
        }
      }

      this.logThrottler[log.key] = log.timestamp;

      if (!this.setTrueLogLine(log)) {
        return;
      }

      if (filesCache.pendingLogs.length === 0) {
        this.addLog(log);
      } else {
        filesCache.pendingLogs.push(log);
      }
    },
    setTrueLogLine(log: ClientLogType): boolean {
      // The log line needs to be overridden with the raw source mapping to get the actual line number for debugging.

      if (!(log.file in filesCache.rawSource)) { // Need to fetch the minified and raw source files first
        if (!(log.file in filesCache.pendingLogsByFile)) {
          filesCache.pendingLogsByFile[log.file] = [];
        }

        filesCache.pendingLogsByFile[log.file].push(filesCache.pendingLogs.length);
        filesCache.pendingLogs.push(log);

        if (!filesCache.currentlyFetching[log.file]) {
          filesCache.currentlyFetching[log.file] = true;
          this.fetchFile(log.file, '');
        }

        return false;
      }

      this.performLineMapping(log);

      return true;
    },
    performLineMapping(log: ClientLogType): void {
      if (log.key in filesCache.mapping) { // Exists in cache
        log.line = filesCache.mapping[log.key];

        return;
      }

      const minifiedSource = filesCache.minifiedSource[log.file];
      const rawSource = filesCache.rawSource[log.file];

      const minifiedSourceByLine = minifiedSource.split('\n');
      const rawSourceByLine = rawSource.split('\n');
      // If minified source files compact over multiple lines, then the column offset paramenter should be used.
      let lineOfInterest = minifiedSourceByLine[log.tempLine - 1].trim(); // File index starts from 1, not 0

      // It is best to match out just the function name to have more robust mapping. This is because the raw files
      // will sometimes differ in subtle ways to the minified source files. E.g. a raw file may have something like
      // `callFunc(someVar as number);`, however the minified source will have the following: `callFunc(someVar);`.
      // Another example is that single-quoted strings are also replaced with double quotes. This means the line
      // number is undefined (or worse, incorrectly defined). Proper sourcemapping according to the minification
      // algorithm is still the besy solution (via a service like Sentry, or a mozilla/source-map), but requires a lot
      // more effort.
      lineOfInterest = lineOfInterest.match(/[^(]+/)[0] + '(';

      let occurrenceCount1 = 0;
      let offset = -lineOfInterest.length;
      let maxOffset = 0;

      for (let i = 0; i < log.tempLine; ++i) {
        maxOffset += minifiedSourceByLine[i].length + 1; // 1 = for new line
      }

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const nextOffset = minifiedSource.indexOf(lineOfInterest, offset + lineOfInterest.length);

        if (nextOffset > maxOffset || nextOffset === -1) {
          break;
        }

        offset = nextOffset;
        ++occurrenceCount1;
      }

      let occurrenceCount2 = 0;
      offset = 0;

      for (let i = 0; i < rawSourceByLine.length; ++i) {
        // Technically, there could be multiple occurrences per line, but realistically, this is not going to happen...
        if (rawSourceByLine[i].indexOf(lineOfInterest) !== -1) {
          ++occurrenceCount2;

          if (occurrenceCount2 == occurrenceCount1) {
            filesCache.mapping[log.key] = i + 1;
            break;
          }
        }
      }

      log.line = filesCache.mapping[log.key];
    },
    fetchFile(logFile: string, fileSuffix: string): void {
      const file = logFile + fileSuffix;

      fetch(file, { headers: { 'Accept': 'text/html', 'Cache-Control': 'no-cache' } }).then(response => {
        // Only resolve the outer promise once the inner promise has resolved
        response.text().then(content => {
          if (!response.ok) {
            // eslint-disable-next-line no-console
            console.log(`Failed to fetch source file '${file}'`, response);
            return;
          }

          // console.log(`Successfully fetched source file '${file}'`);

          if (fileSuffix === '') { // This is for the minified file
            filesCache.minifiedSource[logFile] = content;

            // Now fetch the raw source file
            this.fetchFile(logFile, '?type=vue'); // If this breaks, try `?type=js` (default), with vue for .vue files
          } else {
            filesCache.rawSource[logFile] = content;
            delete filesCache.currentlyFetching[logFile];

            // Now update all pending logs with the correct lines
            for (const logIndex of filesCache.pendingLogsByFile[logFile]) {
              this.performLineMapping(filesCache.pendingLogs[logIndex]);
            }

            delete filesCache.pendingLogsByFile[logFile];

            // If no more files are being fetched, then we can flush the pendingLogs buffer
            if (Object.keys(filesCache.currentlyFetching).length === 0) {
              for (const log of filesCache.pendingLogs) {
                this.addLog(log);
              }

              filesCache.pendingLogs = [];
            }
          }
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(`Failed (2) to fetch source file '${file}'`, err);
        });
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(`Failed (1) to fetch source file '${file}'`, err);
      });
    },
    pinfoLog(message: string, stackOffset = 0, throttleMs = 0): void {
      this.addNewLog(new ClientLogType(message, LOG_SEVERITY.PINFO, stackOffset), throttleMs);
    },
    infoLog(message: string, stackOffset = 0, throttleMs = 0): void {
      this.addNewLog(new ClientLogType(message, LOG_SEVERITY.INFO, stackOffset), throttleMs);
    },
    noticeLog(message: string, stackOffset = 0, throttleMs = 0): void {
      this.addNewLog(new ClientLogType(message, LOG_SEVERITY.NOTICE, stackOffset), throttleMs);
    },
    warningLog(message: string, stackOffset = 0, throttleMs = 0): void {
      this.addNewLog(new ClientLogType(message, LOG_SEVERITY.WARNING, stackOffset), throttleMs);
    },
    errorLog(message: string, stackOffset = 0, throttleMs = 0): void {
      this.addNewLog(new ClientLogType(message, LOG_SEVERITY.ERROR, stackOffset), throttleMs);
    },
    getLogs(): ClientLogType[] {
      return this.logs;
    },
  },
});
