<template>
  <div v-if="props.open" class="panel panel--match-height panel--margin-right">
    <div class="panel__header">
      Filter
      <button class="panel__header__close-btn" title="Close Filter" @click="toggleSidebarOpen()">
        X
      </button>
    </div>

    <div>
      <section class="sidebar__setting">
        <label class="sidebar__setting__label">Log Files</label>
        <i class="ti-home table-icon-tw hand mx-3" @click="goToLogFiles()" />
      </section>

      <section class="sidebar__setting">
        <label class="sidebar__setting__label">Log Type</label>

        <select v-model="logType" class="sidebar__setting__input">
          <option value="" default>
            Select Log Type
          </option>
          <option v-for="(name, i) in Object.values(LOG_SEVERITY)" :key="i" class="capitalize">
            {{ name }}
          </option>
        </select>
      </section>

      <section class="sidebar__setting">
        <label class="sidebar__setting__label">Exchange</label>

        <select v-model="exchangeName" class="sidebar__setting__input">
          <option value="" default>
            Select Exchange
          </option>
          <option v-for="(name, i) in Object.values(EXCHANGE_NAME)" :key="i">
            {{ name }}
          </option>
        </select>
      </section>

      <section class="sidebar__setting">
        <label class="sidebar__setting__label">Phrase Search</label>

        <input v-model="phrase" class="sidebar__setting__input" placeholder="Phrase..." />
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useQueryStringStore } from '@/stores/queryString';
import { useRoute, useRouter } from 'vue-router';
import { EXCHANGE_NAME } from '@/types/exchange';
import { LOG_SEVERITY } from '@/types/serverLogs';
import { toggleSidebarOpen } from '@/stores/shared';

const props = defineProps({
  open: Boolean,
});

// Stores
const queryStringStore = useQueryStringStore();
const route = useRoute();
const router = useRouter();

const exchangeName = ref(route.query.exchangeName?.toString() || '');
const logType = ref(route.query.logType?.toString() || '');
const phrase = ref(route.query.phrase?.toString() || '');

watch(exchangeName, (val) => {
  queryStringStore.update({ exchangeName: val || null });
});

watch(logType, (val) => {
  queryStringStore.update({ logType: val || null });
});

watch(phrase, (val) => {
  queryStringStore.update({ phrase: val || null });
});

watch(() => route.query, (query) => {
  exchangeName.value = query.exchangeName?.toString() || '';
  logType.value = query.logType?.toString() || '';
});

// Fnunctions
const goToLogFiles = () => {
  void router.push({ path: '/logs' });
};
</script>

<style lang="scss" scoped>

</style>
