<template>
  <div class="panel">
    <div class="panel__header">
      <h3 class="panel__header__title">Categories</h3>
    </div>

    <div class="panel__body vtable">
      <div
        v-for="(category, id) in categories" :key="id" class="vtable__row vtable__row--selection hand"
        @click="redirectCategory(id)"
      >
        <div class="vtable__row__item">{{ category.title }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useCategoriesStore } from '@/stores/resources/categories';
import { FILE_TYPE, Category } from '@/types/resources';
import { useRoute, useRouter } from 'vue-router';

// Store
const categoriesStore = useCategoriesStore();
const route = useRoute();
const router = useRouter();

// Computed
const categories = computed<{ [key: string]: Category }>(() => categoriesStore.categories);

// Variables

// Watchers

// Functions
const redirectCategory = (id: string | number): void => {
  void router.push({ path: `/resources/categories/${id}/${FILE_TYPE.VIDEO}`, query: route.query });
};
</script>

<style lang="scss" scoped></style>
