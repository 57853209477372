// Import only the functions you need from the Firebase SDK
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAnalytics, isSupported as isAnalyticsSupported } from 'firebase/analytics';
import { getAuth, Auth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

import Config from '@/config';

const firebaseConfig = {
  apiKey: Config.apiKey(),
  authDomain: Config.authDomain(),
  projectId: Config.projectId(),
  storageBucket: Config.storageBucket(),
  messagingSenderId: Config.messagingSenderId(),
  appId: Config.appId(),
};

// Initialize Firebase
let app: FirebaseApp = null;
let db = null;
let analytics = null;
let auth: Auth = null;

if (!import.meta.env.VITE_APP_OFFLINE) {
  app = initializeApp(firebaseConfig);

  // Initialize Analytics if supported
  void isAnalyticsSupported().then((supported) => {
    if (supported) {
      analytics = getAnalytics(app);
    }
  });

  // Initialize Firestore
  db = getFirestore(app);

  // Initialize Auth
  auth = getAuth(app);
}

export { app, db, auth, analytics };
