<template>
  <div class="card">
    <h4 class="heading ml-3">
      Notifications: {{ notifications.length }}
    </h4>

    <div v-for="notification, i in notifications" :key="i" class="bg-core-700 p-2 rounded relative">
      {{ notification.type + ":" }} {{ notification.message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { NotificationType } from '@/types/user';
import { useNotificationsStore } from '@/stores/user/notifications';

// Stores
const notificationsStore = useNotificationsStore();

const notifications = computed<NotificationType[]>(() => notificationsStore.getNotifications);
</script>

<style lang="scss" scoped></style>
