import { defineStore } from 'pinia';
import { CommentsState, CommentServerResp, CommentsServerRep, Comment, CommentServer } from '@/types/resources';
import { createRequestData } from '@/utilities';
import Config from '@/config';
import { useUserStore } from '@/stores/user/user';
import { useClientLogsStore } from '@/stores/user/clientLogs';
import { createNotification } from '@/stores/user/notifications';
import { NOTIFICATION_TYPE } from '@/types/user';
import { RespError } from '@/types/general';

function convertCommentServer(comment: CommentServer): Comment {
  const replyComments: Comment[] = [];

  comment.replyComments.forEach((comment: CommentServer) => {
    replyComments.push(convertCommentServer(comment));
  });

  const newComment = new Comment(
    comment.parentId,
    comment.id,
    comment.author,
    comment.authorChannelId,
    comment.body,
    new Date(comment.created),
    new Date(comment.updated),
    comment.totalReplyCount,
    replyComments,
  );

  return newComment;
}

function convertCommentsServer(comments: Record<string, CommentServer>) {
  const newComments: Record<string, Comment> = {};

  for (const key in comments) {
    const comment = comments[key];
    const newComment = convertCommentServer(comment);
    newComments[comment.id] = newComment;
  }

  return newComments;
}

export const useCommentsStore = defineStore('comments', {
  state: (): CommentsState => ({
    comments: {},
  }),
  actions: {
    setReplyComments(resp: CommentsServerRep): void {
      const newComments = convertCommentsServer(resp.comments);
      const newCommentsArr = Object.values(newComments);
      if (newCommentsArr.length == 0) return;

      const parentId = newCommentsArr[0].parentId;
      if (!(parentId in this.comments) || parentId == '') {
        useClientLogsStore().errorLog(
          `[*][Resources] Can't set reply comments as parentId '${parentId}' doesn't exist in comments`);
        return;
      }

      const newCommentsArrSorted = newCommentsArr.sort((a: Comment, b: Comment) => {
        const date1 = new Date(a.created), date2 = new Date(b.created);
        return date2.getTime() - date1.getTime();
      });

      this.comments[parentId].replyComments = newCommentsArrSorted;
    },
    async createComment(categoryId: string, fileType: string, fileId: string, comment: Comment): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/files/${categoryId}/${fileType}/${fileId}/comments`,
          createRequestData('POST', useUserStore().token, comment),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        const resp = await response.json() as CommentServerResp;
        this.comments[resp.comment.id] = convertCommentServer(resp.comment);
        createNotification('success createComment', NOTIFICATION_TYPE.SUCCESS);
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error createComment ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    async updateComment(
      categoryId: string, fileType: string, fileId: string, commentId: string, comment: Comment,
    ): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/files/${categoryId}/${fileType}/${fileId}/comments/${commentId}`,
          createRequestData('PATCH', useUserStore().token, comment),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        const resp = await response.json() as CommentServerResp;
        this.comments[resp.comment.id] = convertCommentServer(resp.comment);
        createNotification('success updateComment', NOTIFICATION_TYPE.SUCCESS);
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error updateComment ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    async deleteComment(categoryId: string, fileType: string, fileId: string, commentId: string): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/files/${categoryId}/${fileType}/${fileId}/comments/${commentId}`,
          createRequestData('DELETE', useUserStore().token, null),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        delete this.comments[commentId];
        createNotification('success deleteComment', NOTIFICATION_TYPE.SUCCESS);
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error deleteComment ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    async getComments(categoryId: string, fileType: string, fileId: string): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/files/${categoryId}/${fileType}/${fileId}/comments`,
          createRequestData('GET', useUserStore().token, null),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        const resp = await response.json() as CommentsServerRep;
        this.comments = convertCommentsServer(resp.comments);
        createNotification('success getComments', NOTIFICATION_TYPE.SUCCESS);
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error getComments ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    async getReplyComments(categoryId: string, fileType: string, fileId: string, commentId: string): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/files/${categoryId}/${fileType}/${fileId}/comments/${commentId}`,
          createRequestData('GET', useUserStore().token, null),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        const resp = await response.json() as CommentsServerRep;
        this.setReplyComments(resp);
        createNotification('success getReplyComments', NOTIFICATION_TYPE.SUCCESS);
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error getReplyComments ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    reset(): void {
      this.comments = {};
    },
  },
});
