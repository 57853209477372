<template>
  <div class="col-remaining panel panel--margin-right">
    <header class="panel__header">
      <h3 class="panel__header__title">
        2 auth
      </h3>
    </header>

    <div class="form">
      <!-- TODO add note can't add 2auth if email not verified -->
      <div class="form__row">
        <label>Email Verified: {{ userEmailVerified }}</label>
      </div>
      <!-- TODO add abilty to disable 2auth -->
      <div class="form__row">
        <label>Multi Auth: {{ user2AuthEnabled ? 'Enabled' : 'Disabled' }}</label>
      </div>

      <div v-if="user2AuthEnabled" class="form__row">
        <input v-model="remove2AuthPw" class="w-1/2" type="password" placeholder="Pw" />
        <input v-model="remove2AuthVc" class="w-1/2" type="password" placeholder="Code" />
        <button class="form__row__button ml-2" @click="remove2Auth()">Disable</button>
      </div>
    </div>

    <div v-if="userEmailVerified && !user2AuthEnabled">
      <div v-if="!totpSecret" class="form">
        <div class="form__row">
          <input v-model="add2AuthPw" type="password" placeholder="Password" />
          <button class="form__row__button ml-2" @click="generate2AuthQrCode()">
            Generate QR Code
          </button>
        </div>
      </div>

      <div>
        <div class="flex justify-center items-center">
          <canvas ref="qrCodeCanvas" />
        </div>

        <div v-if="totpSecret" class="form">
          <div class="form__row">
            <input v-model="add2AuthVc" type="text" placeholder="Verification Code" />
            <button class="form__row__button ml-2" @click="add2Auth()">
              Add 2 Auth
            </button>
          </div>
        </div>
      </div>
    </div>

    <span v-if="warning" class="warning-text">*{{ warning }}</span>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useUserStore } from '@/stores/user/user';
import { Auth } from '@/firebaseAuth';
import { TotpSecret } from 'firebase/auth';
import QRCode from 'qrcode';

// Stores
const userStore = useUserStore();

// Computed
const firebaseUser = computed(() => userStore.firebaseUser);
const userEmailVerified = computed(() => firebaseUser.value?.emailVerified || false);
const user2AuthEnabled = computed(() => userStore.user2AuthEnabled);

// Reactive
const qrCodeCanvas = ref(null);
const warning = ref('');
const add2AuthPw = ref('');
const remove2AuthPw = ref('');
const add2AuthVc = ref('');
const remove2AuthVc = ref('');
const totpSecret = ref<TotpSecret>(null);

// Watchers

// Lifecycle

// Functions
const generate2AuthQrCode = async () => {
  try {
    const qrcode = await Auth.generate2AuthQrCode(add2AuthPw.value);

    if (qrcode.url) {
      void QRCode.toCanvas(qrCodeCanvas.value, qrcode.url, (error: Error) => {
        if (error) {
          warning.value = error.message;
        } else {
          warning.value = '';
          totpSecret.value = qrcode.totpSecret;
        }
      });
    }
  } catch(error: unknown) {
    const typedError = error as Error;
    warning.value = typedError.message;
  }
};

const add2Auth = async () => {
  try {
    await Auth.add2Auth(totpSecret.value as TotpSecret, add2AuthVc.value);
    resetForm();
    // Temp hack because composition api doesn't interacte with stores pinia stores well
    location.reload();
    // Auth.setUser();
  } catch(error: unknown) {
    const typedError = error as Error;
    warning.value = typedError.message;
  }
};

const remove2Auth = async () => {
  try {
    await Auth.remove2Auth(remove2AuthPw.value, remove2AuthVc.value);
    resetForm();
    location.reload();
  } catch (error: unknown) {
    const typedError = error as Error;
    warning.value = typedError.message;
  }
};

const resetForm = () => {
  qrCodeCanvas.value = null;
  totpSecret.value = null;
  add2AuthPw.value = '';
  remove2AuthPw.value = '';
  add2AuthVc.value = '';
  remove2AuthVc.value = '';
  warning.value = '';
};
</script>
