import { generateClientId } from '@/utilities';

export class FundingOverview {
  public symbol: string;
  public avg8HourPercent: number;
  public rOI: number;
  public cycles: number;
  public percentTotal: number;

  constructor(symbol: string, cycles: number) {
    this.symbol = symbol;
    this.cycles = cycles;
    // TODO are these defaults okay
    this.avg8HourPercent = 0;
    this.rOI = 0;
    this.percentTotal = 0;
  }
}

export class FundingWrapper {
  public statusId: string;
  public categoryWs: string;
  public exchange: string;
  public symbol: string;
  public startDate: string;
  public endDate: string;

  constructor(categoryWs: string, exchange: string, symbol: string, startDate: string, endDate: string) {
    this.statusId = generateClientId();
    this.categoryWs = categoryWs;
    this.exchange = exchange;
    this.symbol = symbol;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}

export class FundingCycle {
  fundingInterval: string;
  fundingRate: number;
  fundingRateDaily: number;
  symbol: string;
  timestamp: string;

  constructor(
    fundingInterval: string, fundingRate: number, fundingRateDaily: number, symbol: string, timestamp: string,
  ) {
    this.fundingInterval = fundingInterval;
    this.fundingRate = fundingRate;
    this.fundingRateDaily = fundingRateDaily;
    this.symbol = symbol;
    this.timestamp = timestamp;
  }
}

export interface FundingAnalysisState {
  fundingCycles: Record<string, FundingCycle[]>
}

export interface FundingCyclesServerResp {
  fundingCycles: FundingCycleServer[];
}

export interface FundingCycleServer {
  timestamp: string;
  symbol: string;
  fundingInterval: string;
  fundingRate: number;
  fundingRateDaily: number;
}
