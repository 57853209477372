<template>
  <div class="card">
    <p>Pair liquidity</p>
    <InstrumentsTable :symbol-link="false" @selected-instruments="setSelectedInstruments" />

    <section>
      <button class="btn-tw" @click="fetchPairLiquidity()">
        Fetch Pair Liquidity
      </button>
    </section>

    <section v-if="selectedInstruments && orderbooks.length">
      {{ statusMessage }}
      <table class="table-tw">
        <thead class="table-thead-tw">
          <tr class="hand" @click="updateTableData($event)">
            <th name="exchangeName" class="table-th-tw" :class="{ 'bg-core-700': sortBy == 'exchangeName' }">
              Exchange Name
            </th>
            <th name="exchangeType" class="table-th-tw" :class="{ 'bg-core-700': sortBy == 'exchangeType' }">
              Exchange Type
            </th>
            <th name="symbolName" class="table-th-tw" :class="{ 'bg-core-700': sortBy == 'symbol' }">
              Symbol Name
            </th>
            <th name="buysTotal" class="table-th-tw" :class="{ 'bg-core-700': sortBy == 'buysTotal' }">
              Buys Total ({{ chosenAssetSymbol }})
            </th>
            <th name="sellsTotal" class="table-th-tw" :class="{ 'bg-core-700': sortBy == 'sellsTotal' }">
              Sells Total ({{ chosenAssetSymbol }})
            </th>
          </tr>
        </thead>
        <tbody class="table-tbody-tw">
          <tr v-for="(liquidity, i) in orderbooks" :key="i" class="table-tr-tw">
            <td class="table-td-tw">
              {{ liquidity.exchangeName }}
            </td>
            <td class="table-td-tw">
              {{ liquidity.exchangeType }}
            </td>
            <td class="table-td-tw">
              <span :title="liquidity.symbol">{{ liquidity.consistentSymbol }}</span>
            </td>
            <td class="table-td-tw">
              {{ formatNumber(liquidity.buysTotal) }}
            </td>
            <td class="table-td-tw">
              {{ formatNumber(liquidity.sellsTotal) }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script setup lang="ts">
import { watch, ref, computed } from 'vue';
import { useUserSettingsStore } from '@/stores/user/settings';
import { useOrderbooksStore } from '@/stores/exchanges/orderbooks';
import { useWebSocketStore } from '@/stores/user/ws';

import { OrderbookLiquidity, OrderbookLiquidityKeys } from '@/types/orderbooks';
import { SelectedInstrument } from '@/types/instruments';
import InstrumentsTable from '@/components/exchanges/instruments/InstrumentsTable.vue';
import { formatNumber } from '@/utilities';

// Stores
const orderbooksStore = useOrderbooksStore();
const userSettingsStore = useUserSettingsStore();
const wsStore = useWebSocketStore();

// Computed
const chosenAssetSymbol = computed(() => userSettingsStore.getChosenAssetSymbol);
const orderbooksLiquidity = computed(() => orderbooksStore.orderbooksLiquidity);

// Variables
const selectedInstruments = ref<SelectedInstrument[]>(null);
const orderbooks = ref<OrderbookLiquidity[]>([]);
const statusMessage = ref('');
const sortBy = ref<OrderbookLiquidityKeys>('exchangeName');
const orderBy = ref('asc');

// Watchers
watch(() => orderbooksLiquidity.value, () => {
  orderbooks.value = [];

  for (const instrument of selectedInstruments.value) {
    const orderbookLiquidityTemp =
      orderbooksLiquidity.value[instrument.exchangeName + instrument.exchangeType + instrument.symbol];

    if (orderbookLiquidityTemp !== undefined) {
      const liquidity = JSON.parse(JSON.stringify(orderbookLiquidityTemp)) as OrderbookLiquidity;
      const asset = instrument.consistentSymbol.split('-')[1];

      liquidity.buysTotal = String(
        userSettingsStore.getChosenAssetValue(
          instrument.exchangeName, asset, +liquidity.buysTotal, true,
        ),
      );

      liquidity.sellsTotal = String(
        userSettingsStore.getChosenAssetValue(
          instrument.exchangeName, asset, +liquidity.sellsTotal, true,
        ),
      );

      orderbooks.value.push(liquidity);
    }
  }

  sortOrderbooks();
  statusMessage.value = `Fetched: ${orderbooks.value.length} of ${selectedInstruments.value.length} pairs`;
});

watch(sortBy, () => {
  sortOrderbooks();
});

watch(orderBy, () => {
  sortOrderbooks();
});

// Functions
const setSelectedInstruments = (selectedInstrumentsParam: SelectedInstrument[]) => {
  selectedInstruments.value = selectedInstrumentsParam;
};

const fetchPairLiquidity = () => {
  wsStore.send({
    category: 'fetch_pairs_liquidity',
    body: JSON.stringify(selectedInstruments.value),
  });
};

const updateTableData = (e: Event): void => {
  const target = e.target as HTMLElement;
  const name = target.getAttribute('name') as OrderbookLiquidityKeys;

  if (name === sortBy.value) {
    // Update orderBy
    orderBy.value = orderBy.value === 'asc' ? 'desc' : 'asc';
  } else {
    // Update sortBy
    sortBy.value = name;
  }
};

const sortOrderbooks = () => {
  orderbooks.value.sort((a, b) => {
    const x = typeof a[sortBy.value] == 'number' ? a[sortBy.value] : Number(a[sortBy.value]);
    const y = typeof b[sortBy.value] == 'number' ? b[sortBy.value] : Number(b[sortBy.value]);

    if ((x < y && orderBy.value === 'asc') || (x > y && orderBy.value === 'desc')) {
      return -1;
    }

    if ((x > y && orderBy.value === 'asc') || (x < y && orderBy.value === 'desc')) {
      return 1;
    }

    return 0;
  });
};
</script>

<style>
* {
  color: #fff;
}
</style>
