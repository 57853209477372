<template>
  <div v-if="props.open" class="panel panel--match-height panel--margin-right">
    <div class="panel__header">
      Filter
      <button class="panel__header__close-btn" title="Close Filter" @click="toggleSidebarOpen()">
        X
      </button>
    </div>

    <div v-if="categoryId" class="dark:text-white">
      <CategoryMenu />
      <ContentTypes />

      <!-- TODO make more dynamic -->
      <div class="category-div">
        <CategoryTable v-if="!textSearchMode || fileType == FILE_TYPE.ARTICLE" />

        <div v-if="fileType == FILE_TYPE.VIDEO">
          <TranscriptionsSearch v-if="textSearchMode" />

          <div class="text-center">
            <button class="button btn-margin" @click="textSearchMode = !textSearchMode">
              {{ textSearchMode ? "Video Search Mode" : "Text Search Mode" }}
            </button>
          </div>
        </div>
      </div>

      <div v-if="fileType == FILE_TYPE.ARTICLE" class="sidebar__setting">
        <label class="sidebar__setting__label capitalize p-0 m-0">Add {{ FILE_TYPE.ARTICLE }}</label>
        <i class="ti-plus sidebar-icon-tw hand mx-2" @click="openPopup()" />
      </div>
    </div>

    <div v-if="popup">
      <CreateContentPopup @close-popup="closePopup" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { FILE_TYPE } from '@/types/resources';
import CategoryMenu from '@/components/resources/sidebar/CategoryMenu.vue';
import ContentTypes from '@/components/resources/sidebar/ContentTypes.vue';
import CategoryTable from '@/components/resources/sidebar/CategoryTable.vue';
import TranscriptionsSearch from '@/components/resources/sidebar/TranscriptionsSearch.vue';
import CreateContentPopup from '@/components/resources/CreateContentPopup.vue';
import { toggleSidebarOpen } from '@/stores/shared';

// Parent properties
const props = withDefaults(defineProps<{
  open: boolean,
}>(), {
  open: false,
});

// Store
const route = useRoute();

// Computed
const categoryId = computed(() => route.params.categoryId ? String(route.params.categoryId) : '');
const fileType = computed(() => route.params.fileType ? String(route.params.fileType) : '');

// Variables
const popup = ref(false);
const textSearchMode = ref(false);

// Watchers

// Functions
const openPopup = (): void => {
  popup.value = true;
};

const closePopup = (): void => {
  popup.value = false;
};
</script>

<style lang="scss" scoped>
.category-div {
  max-height: 600px;
  overflow-y: scroll;
}
</style>
