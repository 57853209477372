export class WalletType {
  public id: string;
  public name: string;
  public balances: Record<string, AssetBalanceType>; // asset => balance

  // Auxiliary properties for presentation
  public exchangeName: string;
  public accountId: string;
  public chosenAssetAvailable: number;
  public chosenAssetTotal: number;
  public aggregationKeys: Record<string, string>[];
  public tableHeading: string;

  constructor(
    id: string,
    name: string,
    balances: Record<string, AssetBalanceType>,
    exchangeName: string,
    accountId: string,
  ) {
    this.id = id;
    this.name = name;
    this.balances = balances;
    this.exchangeName = exchangeName;
    this.accountId = accountId;
    this.chosenAssetAvailable = 0;
    this.chosenAssetTotal = 0;
    this.aggregationKeys = [];
    this.tableHeading = '';
  }

  public getBalance(assetName: string): AssetBalanceType {
    if (this.balances[assetName]) {
      return this.balances[assetName];
    }

    return new AssetBalanceType('0', '0');
  }
}

export interface WalletTypeServer {
  id: string;
  name: string;
  balances: Record<string, AssetBalanceTypeServer>;
}

export class AssetBalanceType {
  public asset: string;
  public available: string;
  public chosenAssetAvailable: string;
  public total: string;
  public chosenAssetTotal: string;

  constructor(available: string, total: string) {
    this.available = available;
    this.chosenAssetAvailable = '0';
    this.total = total;
    this.chosenAssetTotal = '0';
  }
}

export type AssetBalanceKeys = keyof AssetBalanceType;

export interface AssetBalanceTypeServer {
  available: string;
  total: string;
}

export interface WalletsState {
  wallets: Record<string, Record<string, WalletType>>
}

export interface WalletServerResp {
  exchangeName: string;
  accountId: string;
  walletId: string;
  wallet: WalletTypeServer;
}

export interface WalletsServerResp {
  exchangeName: string;
  accountId: string;
  wallets: Record<string, WalletTypeServer>;
}
