<template>
  <div class="grid">
    <div class="col-remaining panel panel--margin-right">
      <header class="panel__header">
        <h3 class="panel__header__title">
          Update hard-coded symbol prices
        </h3>
      </header>

      <div v-if="Object.keys(priceLookupCacheNotFound).length > 0">
        Current unconverted assets: {{ Object.keys(priceLookupCacheNotFound).join(', ') }}
      </div>

      <div>
        <div v-if="Object.keys(priceLookupCacheNotFound).length > 0">
          hard-coded assets:
          <div>
            <div v-for="(price, symbol) in userSettingsStore.hardcodedSymbolPrices" :key="symbol">
              {{ symbol }}: {{ price.lastPrice }} <button @click="removeSymbol(symbol)">X</button>
            </div>
          </div>
        </div>
        <div class="form__row">
          <label for="newNonce">Add symbol price:</label>
          <input
            id="symbolName" v-model="symbolName" type="text" autocomplete="off"
            placeholder="Name (e.g. GOOG-USD)" class="sidebar__setting__input"
          />
          <input
            id="symbolPrice" v-model="symbolPrice" type="text" autocomplete="off"
            placeholder="Price (e.g. 200)" class="sidebar__setting__input"
          />
        </div>

        <div class="form__row">
          <button
            class="form__row__button form__row__button form__row__button--right"
            @click="setSymbolPrice()"
          >
            Set symbol price
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useUserSettingsStore, priceLookupCacheNotFound, priceLookupCache } from '@/stores/user/settings';
import { EXCHANGE_TYPE } from '@/types/exchange';
import { PriceType } from '@/types/prices';

const userSettingsStore = useUserSettingsStore();

const symbolName = ref('');
const symbolPrice = ref('');

const setSymbolPrice = () => {
  userSettingsStore.hardcodedSymbolPrices[symbolName.value] = new PriceType(
    symbolName.value, symbolPrice.value, symbolPrice.value, symbolPrice.value, '', '', '', EXCHANGE_TYPE.SPOT,
  );
  const baseAsset = symbolName.value.split('-')[0];

  delete priceLookupCacheNotFound.value[baseAsset];

  symbolName.value = '';
  symbolPrice.value = '';
};

const removeSymbol = (symbol: string) => {
  delete userSettingsStore.hardcodedSymbolPrices[symbol];

  const baseAsset = symbol.split('-')[0];

  delete priceLookupCache[baseAsset];
};

</script>
