<template>
  <div class="panel">
    <div class="panel__header" :class="{ 'panel__header--active': !collasped }">
      <h3 class="panel__header__title">
        New Pairs - {{ newPairs.length }}
      </h3>
      <div class="panel__header__button panel__header__button--last">
        <router-link v-if="Object.keys($route.query).length > 0" class="button" :to="{ path: $route.fullPath }">
          Clear Filters
        </router-link>
      </div>
      <i
        :class="{ 'ti-angle-up': !collasped, 'ti-angle-down': collasped }" class="panel__header__icon"
        @click="collasped = !collasped"
      />
    </div>

    <div v-if="!collasped" class="panel__body vtable">
      <div class="vtable-header hand">
        <div class="vtable-header__item vtable__size--m-30 vtable__size--20">
          <select v-model="exchangeNameFilter" name="exchangeNameFilter" class="vtable-header__item__input">
            <option value="">EX. NAME</option>
            <option value="" disabled>--</option>
            <option v-for="exchangeName in exchangeNames" :key="exchangeName">{{ exchangeName }}</option>
          </select>
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--20">
          <select v-model="exchangeTypeFilter" name="exchangeTypeFilter" class="vtable-header__item__input">
            <option value="">EX. TYPE</option>
            <option value="" disabled>--</option>
            <option>Spot</option>
            <option>Derivatives</option>
          </select>
        </div>
        <div class="vtable-header__item vtable__size--m-60 vtable__size--30">
          Symbol
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--30">
          Date Added
        </div>
        <div class="vtable-header__item vtable__size--m-10 vtable__size--d-hide" />
      </div>
      <DynamicScroller
        :items="newPairs" :min-item-size="36" class="panel__body__table vtable panel__body__table--large"
      >
        <template #default="{ item, index, active }">
          <DynamicScrollerItem
            :item="item" :active="active" :size-dependencies="[item.showOverview,]" :data-index="index"
          >
            <div class="vtable__row">
              <div class="vtable__row__item vtable__size--m-30 vtable__size--d-hide">
                {{ item.exchangeName }}
                <span v-if="item.exchangeType === EXCHANGE_TYPE.DERIVATIVES" :title="item.exchangeType">
                  &nbsp; (D)
                </span>
                <span v-else :title="item.exchangeType">&nbsp; (S)</span>
              </div>

              <div class="vtable__row__item vtable__size--m-hide vtable__size--20">
                {{ item.exchangeName }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--20">
                {{ item.exchangeType }}
              </div>
              <div class="vtable__row__item vtable__size--m-60 vtable__size--30">
                <router-link
                  class="table-link-tw" :title="item.symbol"
                  :to="{
                    path: `/exchanges/${item.exchangeName}/${item.exchangeType}/${encodeURIComponent(item.symbol)}`,
                  }"
                >
                  {{ item.consistentSymbol }}
                </router-link>
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--30">
                {{ formatDate(item.released) }}
              </div>
              <div class="vtable__row__item vtable__row__item--open-btn vtable__size--d-hide vtable__size--m-10">
                <button class="vtable__row__button" @click="item.showOverview = !item.showOverview">
                  <i :class="!item.showOverview ? 'ti-angle-down' : 'ti-angle-up'" />
                </button>
              </div>
              <div v-if="item.showOverview" class="vtable__row__overview">
                <div class="vtable__m-overview">
                  <div class="vtable__m-overview__item">
                    <label>RELEASED DATE</label>
                    <div>{{ formatDate(item.released) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { useQueryStringStore } from '@/stores/queryString';
import { useRoute } from 'vue-router';
import { EXCHANGE_TYPE } from '@/types/exchange';

class NewInstrumentView {
  public id: string;
  public exchangeName: string;
  public exchangeType: string;
  public symbol: string;
  public consistentSymbol: string;
  public released: number;
  public showOverview: boolean;

  constructor(
    exchangeName: string,
    exchangeType: string,
    symbol: string,
    consistentSymbol: string,
    released: number,
    showOverview: boolean,
  ) {
    this.id = `${exchangeName}-${exchangeType}-${symbol}`;
    this.exchangeName = exchangeName;
    this.exchangeType = exchangeType;
    this.symbol = symbol;
    this.consistentSymbol = consistentSymbol;
    this.released = released;
    this.showOverview = showOverview;
  }
}

// Store
const instrumentsStore = useInstrumentsStore();
const queryStringStore = useQueryStringStore();
const route = useRoute();

// Computed
const instruments = computed(() => instrumentsStore.instruments);

// Variables
const collasped = ref(false);
const exchangeNames = ref<string[]>([]);
const exchangeNameFilter = ref('');
const exchangeTypeFilter = ref('');
const newPairs = ref<NewInstrumentView[]>([]);

// Watchers
watch(exchangeNameFilter, (val: string) => {
  if (val !== route.query.exchangeName as string) {
    queryStringStore.update({ 'exchangeName': val || null });
  }
});

watch(exchangeTypeFilter, (val: string) => {
  if (val !== route.query.exchangeType as string) {
    queryStringStore.update({ 'exchangeType': val || null });
  }
});

watch(() => route.query.exchangeName ? String(route.query.exchangeName) : '', (val: string) => {
  exchangeNameFilter.value = val || '';
  computeNewPairsData();
});

watch(() => route.query.exchangeType ? String(route.query.exchangeType) : '', (val: string) => {
  exchangeTypeFilter.value = val || '';
  computeNewPairsData();
});

watch(instruments, () => computeNewPairsData(), { deep: true });

// Vue Lifecycle Functions
onMounted(() => {
  exchangeNameFilter.value = route.query.exchangeName as string || '' ;
  exchangeTypeFilter.value = route.query.exchangeType as string || '' ;
  computeNewPairsData();
});

// Functions
const formatDate = (ts: number) => {
  return new Date(ts).toLocaleString('en-GB', { timeZone: 'UTC' });
};

const computeNewPairsData = () => {
  const newPairsLocal: NewInstrumentView[] = [];

  for (const exchangeName in instruments.value) {
    if (exchangeNameFilter.value !== '') {
      if (exchangeName !== exchangeNameFilter.value) {
        continue;
      }
    }
    for (const exchangeType in instruments.value[exchangeName]) {
      if (exchangeTypeFilter.value !== '') {
        if (exchangeType !== exchangeTypeFilter.value) {
          continue;
        }
      }

      for (const symbol in instruments.value[exchangeName][exchangeType]) {
        const instrument = instruments.value[exchangeName][exchangeType][symbol],
          d = new Date(),
          pD = d.getDate() - 7,
          releaseTs = instrument.released * 1000,
          showOverview = false; // TODO: Add ability to show overview information for an instrument

        d.setDate(pD);

        // Don't display instruments which haven't been released yet
        const oneDayFromNow = new Date();
        oneDayFromNow.setDate(oneDayFromNow.getDate() + 1);

        if (releaseTs > +d && releaseTs < +oneDayFromNow) {
          newPairsLocal.push(
            new NewInstrumentView(
              exchangeName,
              exchangeType,
              symbol,
              instrument.consistentSymbol,
              releaseTs,
              showOverview,
            ),
          );
        }
      }
    }
  }

  exchangeNames.value = Object.keys(instruments.value).sort();

  newPairsLocal.sort((a, b) => (a.released < b.released) ? 1 : ((b.released < a.released) ? -1 : 0));

  newPairs.value = newPairsLocal;
};
</script>

<style lang="scss" scoped></style>
