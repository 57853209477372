<template>
  <div>
    <div class="panel">
      <div class="panel__header">
        <h3 class="panel__header__title">
          Transcriptions: {{ hits.length }} / {{ hitsMeta.totalHits }}
        </h3>

        <div class="flex flex-row">
          <input
            v-model="query" class="input-tw w-3/4 input-settings mx-2"
            placeholder="Search Query" :disabled="requestProcessing"
            :class="{ 'table-disabled-input': requestProcessing }"
          />

          <!-- <select
            v-model="searchType" class="input-tw mx-2 w-1/4 input-settings" :disabled="true"
            :class="{ 'table-disabled-input': requestProcessing }"
          >
            <option :value="SEARCH_TYPE.QUERY_STRING_QUERY">
              {{ SEARCH_TYPE.QUERY_STRING_QUERY }}
            </option>
          </select> -->

          <button
            class="button input-settings" style="margin: 0px !important; padding: 4px !important;"
            :disabled="requestProcessing" :class="{ 'btn-disabled': requestProcessing }"
            @click="newSearchQuery"
          >
            Search
          </button>

          <div v-if="requestProcessing">
            <div class="text-center">
              <img class="spin-animation" :src="spinningCircle" />
            </div>
          </div>
        </div>
      </div>

      <div class="panel__body vtable table-div">
        <div class="vtable-header" style="padding-left: 1px;">
          <div class="vtable-header__item vtable__size--m-20 vtable__size--20">
            Filename
          </div>
          <div class="vtable-header__item vtable__size--m-60 vtable__size--60">
            Text
          </div>
        </div>

        <div
          v-for="(hit, index) in hits" :key="index" class="vtable__row vtable__row--selection hand"
          @click="goToContent(String(hit.fields.videoId), Number(hit.fields.start).toFixed(2))"
        >
          <div class="vtable__row__item vtable__size--m-20 vtable__size--20 text-overflow">
            {{ hit.fields.filename }}
          </div>
          <div class="vtable__row__item vtable__size--m-60 vtable__size--60 text-overflow">
            <MdPreview
              preview-theme="table-mdpreview" class="table-mdpreview" :editor-id="`hit-${index}`" language="en-US"
              :model-value="`<p class='table-mdpreview-text'>${hit.fragments.text[0]}</p>`" :theme="theme"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="text-center">
      <button v-if="!fullyLoaded" class="button btn-margin" @click="loadMore()">Load More</button>
      <button v-else class="button btn-margin btn-disabled" disabled>Load More</button>
    </div>

    <div>
      <div class="text-center">
        <button class="button btn-margin" @click="showDescription = !showDescription">
          {{ showDescription ? "Hide" : "View" }} Search Type Description
        </button>
      </div>

      <div v-if="showDescription">
        <iframe
          class="w-full" style="height: 600px;" target="_parent" src="http://blevesearch.com/docs/Query-String-Query/"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, defineAsyncComponent } from 'vue';
import { useUserSettingsStore } from '@/stores/user/settings';
import { useTranscriptionsStore } from '@/stores/resources/transcriptions';
import { HitsMeta, Hit, SEARCH_TYPE } from '@/types/resources';
import { useRoute, useRouter } from 'vue-router';
// import { convertMsToTime } from '@/utilities';
import spinningCircle from '@/assets/spinning-circle.svg';

// Lazy load
const MdPreview = defineAsyncComponent(() => import('md-editor-v3').then(module => module.MdPreview));

// Store
const userSettingsStore = useUserSettingsStore();
const transcriptionsStore = useTranscriptionsStore();
const route = useRoute();
const router = useRouter();

// Computed
const hitsMeta = computed<HitsMeta>(() => transcriptionsStore.hitsMeta);
const hits = computed<Hit[]>(() => transcriptionsStore.getHits);
const categoryId = computed(() => route.params.categoryId ? String(route.params.categoryId) : '');
const fileType = computed(() => route.params.fileType ? String(route.params.fileType) : '');
const fullyLoaded = computed(() => hits.value.length >= hitsMeta.value.totalHits);
const theme = computed(() => {
  const t = userSettingsStore.getTheme;
  return t.includes('dark') ? 'dark' : 'light';
});

// Variables
const searchType = ref(SEARCH_TYPE.QUERY_STRING_QUERY);
const requestProcessing = ref(false);
const query = ref('');
const showDescription = ref(false);

// Watchers

// Methods
const resetTranscriptionsHits = (): void => {
  transcriptionsStore.resetHits();
  transcriptionsStore.setHitsMeta(new HitsMeta(0, 100, 0));
};

const newSearchQuery = (): void => {
  resetTranscriptionsHits();
  void searchQuery(hitsMeta.value.limit, hitsMeta.value.offset);
};

const loadMore = (): void => {
  void searchQuery(hitsMeta.value.limit, hitsMeta.value.limit + hitsMeta.value.offset);
};

const searchQuery = async (limit: number, offset: number): Promise<void> => {
  requestProcessing.value = true;
  await transcriptionsStore.getTranscriptions(
    categoryId.value, fileType.value, query.value, searchType.value, limit, offset,
  );
  requestProcessing.value = false;
};

const goToContent = (id: string, start: string): void => {
  // Use router.push to navigate to the same route with the added contentId query parameter
  void router.push({
    path: route.path, // Keep the current path
    query: {
      ...route.query, // Preserve existing query parameters
      fileId: id, // Add or update the contentId parameter
      start,
    },
  });
};
</script>

<style lang="scss">
mark {
  background-color: #383C43;
  padding: 2px;
}

.table-mdpreview > * {
  margin: 0px !important;
  padding: 0px !important;
}

.table-mdpreview-text {
  font-size: 12px;
  font-weight: normal;
}

.table-div {
  max-height: 450px;
  overflow-y: auto;
}

.input-settings {
  height: 30px;
}
</style>
