import { defineStore } from 'pinia';
import {
  WalletType, AssetBalanceType, WalletsState, WalletServerResp, WalletsServerResp, WalletTypeServer,
  AssetBalanceTypeServer,
} from '@/types/wallets';
import { EXCHANGE_NAME } from '@/types/exchange';
import { useClientLogsStore } from '@/stores/user/clientLogs';


export function convertWalletTypeServer(
  wallet: WalletTypeServer, walletId: string, entityName: string, accountId: string,
): WalletType {
  const assetBalances: Record<string, AssetBalanceType> = {};

  for (const asset in wallet.balances) {
    const assetBalance = wallet.balances[asset];

    if (Number(assetBalance.total) == 0) {
      continue;
    }

    assetBalances[asset] = convertAssetBalanceTypeServer(assetBalance);
  }

  return new WalletType(walletId, wallet.name, assetBalances, entityName, accountId);
}

export function convertAssetBalanceTypeServer(assetBalance: AssetBalanceTypeServer): AssetBalanceType {
  return new AssetBalanceType(assetBalance.available, assetBalance.total);
}

export const useWalletsStore = defineStore('wallets', {
  state: (): WalletsState => ({
    wallets: {}, // accountId => { walletId => {} }
  }),
  actions: {
    setWallet({ exchangeName, accountId, walletId, wallet }: WalletServerResp) {
      if (!(accountId in this.wallets)) {
        this.wallets[accountId] = {};
      }

      this.wallets[accountId][walletId] = convertWalletTypeServer(wallet, walletId, exchangeName, accountId);

      const balances: Record<string, AssetBalanceType> = {};

      for (const asset in wallet.balances) {
        const balance = wallet.balances[asset];

        if (asset.includes('DUEL') && exchangeName == EXCHANGE_NAME.PROBIT) {
          continue;
        }

        if (Number(balance.total) == 0) {
          continue;
        }

        balances[asset] = new AssetBalanceType(balance.available, balance.total);
      }

      this.wallets[accountId][walletId] = new WalletType(walletId, wallet.name, balances, exchangeName, accountId);
    },
    addWallets(body: WalletsServerResp) {
      for (const walletId in body.wallets) {
        this.setWallet({
          exchangeName: body.exchangeName,
          accountId: body.accountId,
          walletId,
          wallet: body.wallets[walletId],
        });
      }
    },
    addWallet(body: WalletServerResp) {
      this.setWallet({
        exchangeName: body.exchangeName,
        accountId: body.accountId,
        walletId: body.walletId,
        wallet: body.wallet,
      });
    },
    updateWallet(body: WalletServerResp) {
      this.setWallet({
        exchangeName: body.exchangeName,
        accountId: body.accountId,
        walletId: body.walletId,
        wallet: body.wallet,
      });
    },
    deleteWallet(body: WalletServerResp) {
      const accountId = body.accountId,
        walletId = body.walletId;

      if (this.wallets[accountId]?.[walletId] === undefined) {
        useClientLogsStore().errorLog(
          `[*] Undefined: this.wallets[${accountId}][${walletId}] when deleting wallet`,
        );
        return;
      }

      delete this.wallets[accountId][walletId];
    },
  },
  getters: {
    getAccountsWallets: (state) => {
      return state.wallets;
    },
    getAccountWallets: (state) => (accountId: string) => {
      return Object.values(state.wallets?.[accountId] || []);
    },
  },
});
