<template>
  <div class="grid">
    <div class="col" :class="sidebarOpen ? 'col-2' : 'col-closed'">
      <ExchangeFilter :open="sidebarOpen" />
    </div>
    <div class="col col-remaining">
      <InstrumentsTable :symbol-link="true" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useSidebarStore } from '@/stores/sidebar';
import InstrumentsTable from '@/components/exchanges/instruments/InstrumentsTable.vue';
import ExchangeFilter from '@/components/exchanges/sidebar/ExchangeFilter.vue';

// Stores
const sidebarStore = useSidebarStore();

// Computed
const sidebarOpen = computed(() => sidebarStore.getSidebarOpen);
</script>

<style lang="scss">

</style>
