import { defineStore } from 'pinia';
import {
  LiquidationsServerResp, LiquidationType, LiquidationsState, LiquidationTypeServer,
} from '@/types/liquidations';

function convertLiquidationTypeServer(liquidation: LiquidationTypeServer): LiquidationType {
  return new LiquidationType(
    liquidation.time,
    liquidation.price,
    liquidation.quantity,
    liquidation.side,
  );
}

export const useLiquidationsStore = defineStore('liquidations', {
  state: (): LiquidationsState => ({
    liquidations: {},
  }),
  actions: {
    setLiquidations(body: LiquidationsServerResp) {
      const exchangeName = body.exchangeName,
        exchangeType = body.exchangeType,
        symbol = body.symbol;

      for (const liquidation of body.liquidations) {
        if (!(exchangeName in this.liquidations)) {
          this.liquidations[exchangeName] = {};
        }

        if (!(exchangeType in this.liquidations[exchangeName])) {
          this.liquidations[exchangeName][exchangeType] = {};
        }

        if (!(symbol in this.liquidations[exchangeName][exchangeType])) {
          this.liquidations[exchangeName][exchangeType][symbol] = [];
        }

        this.liquidations[exchangeName][exchangeType][symbol].push(convertLiquidationTypeServer(liquidation));
      }
    },
  },
});
