import { defineStore } from 'pinia';
import router from '../router/index';

type QueryParams = Record<string, string | string[]>;

export const useQueryStringStore = defineStore('queryString', {
  actions: {
    update(body: QueryParams) {
      const stateNames = Object.keys(body);
      const queryString = JSON.parse(JSON.stringify(router.currentRoute.value.query)) as QueryParams;

      stateNames.forEach((name: string) => {
        if (body[name]) {
          queryString[name] = body[name];
        } else {
          delete queryString[name];
        }
      });

      void router.push({ query: queryString });
    },
  },
});
