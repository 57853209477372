import { generateClientId } from '@/utilities';

export class Category {
  id: string;
  title: string;
  body: string;

  constructor(id: string, title: string, body: string) {
    this.id = id;
    this.title = title;
    this.body = body;
  }
}

export interface CategoryServer {
  id: string;
  title: string;
  body: string;
}

export interface CategoriesState {
  categories: Record<string, Category>
}

export interface CategoriesServerResp {
  categories: Record<string, CategoryServer>;
}

export interface CommentServer {
  id: string;
  parentId: string;
  author: string;
  authorChannelId: string;
  body: string;
  created: string;
  updated: string;
  totalReplyCount: number;
  replyComments: CommentServer[];
}

export class Comment {
  parentId: string;
  id: string;
  author: string;
  authorChannelId: string;
  body: string;
  created: Date;
  updated: Date;
  totalReplyCount: number;
  replyComments: Comment[];

  constructor(
    parentId: string, id: string, author: string, authorChannelId: string, body: string, created: Date,
    updated: Date, totalReplyCount: number, replyComments: Comment[],
  ) {
    this.parentId = parentId;
    this.id = id;
    this.author = author;
    this.authorChannelId = authorChannelId;
    this.body = body;
    this.created = created;
    this.updated = updated;
    this.totalReplyCount = totalReplyCount;
    this.replyComments = replyComments;
  }
}

export interface CommentsState {
  comments: Record<string, Comment>;
}

export interface CommentServerResp {
  comment: CommentServer;
}

export interface CommentsServerRep {
  comments: Record<string, CommentServer>;
}

export enum FILE_TYPE {
  VIDEO = 'video',
  ARTICLE = 'article'
}

export class File {
  public id: string;
  public body: string;
  public created: string;
  public tags: string[];
  public title: string;
  public type: string;

  constructor(id: string, body: string, created: string, tags: string[], title: string, type: string) {
    this.id = id;
    this.body = body;
    this.created = created;
    this.tags = tags;
    this.title = title;
    this.type = type;
  }
}

export interface FilesState {
  files: Record<string, File>
}

export interface FilesServerResp {
  files: Record<string, FileServer>;
}

export interface FileServerResp {
  file: FileServer;
}

export interface FileServer {
  id: string;
  title: string;
  body: string;
  created: string;
  type: string;
  tags: string[];
}

// TODO Get rid of
export class ResourceWrapper {
  public statusId: string;
  public categoryWs: string;
  public code: string;

  constructor(categoryWs: string) {
    this.statusId = generateClientId();
    this.categoryWs = categoryWs;
    this.code = '';
  }
}

export interface ResourcesState {
  resourcesAuthLink: string
  resourcesChannelId: string
}

export interface ResourcesAuthLinkServerResp {
  resourcesAuthLink: string;
}

export interface ResourcesChannelIdServerResp {
  resourcesChannelId: string;
}

export enum SEARCH_TYPE {
  QUERY_STRING_QUERY = 'QueryStringQuery',
  MATCH = 'Match',
  PHRASE = 'Phrase',
  MATCH_PHRASE = 'MatchPhrase',
  FUZZY = 'Fuzzy'
}

interface HitFields {
  [key: string]: string;
}

interface HitFragments {
  [key: string]: string;
}

export class Hit {
  id: string;
  index: string;
  score: number;
  fields: HitFields;
  fragments: HitFragments;
  sort: string[];

  constructor(id: string, index: string, score: number, fields: HitFields, fragments: HitFragments, sort: string[]) {
    this.id = id;
    this.index = index;
    this.score = score;
    this.fields = fields;
    this.fragments = fragments;
    this.sort = sort;
  }
}

export class HitsMeta {
  public totalHits: number;
  public limit: number;
  public offset: number;

  constructor(totalHits: number, limit: number, offset: number) {
    this.totalHits = totalHits;
    this.limit = limit;
    this.offset = offset;
  }
}

export interface TranscriptionsState {
  hitsMeta: HitsMeta;
  hits: Hit[];
  totalHits: number;
}

export class HitServer {
  id: string;
  index: string;
  score: number;
  fields: HitFields;
  fragments: HitFragments;
  sort: string[];
}

interface TranscriptionsSearchServer {
  hits: HitServer[];
  total_hits: number;
  request: {
    size: number;
    from: number;
  };
}

export interface TranscriptionsServerResp {
  transcriptions_search: TranscriptionsSearchServer
  category: string;
}
