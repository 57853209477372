<template>
  <PopupScreen>
    <div>
      <h2 class="my-2">Create {{ fileType }}</h2>

      <div class="mb-2">
        <input v-model="title" class="vtable-header__item__input-wrap__input" placeholder="Title here..." />
      </div>

      <MdEditor v-model="body" :theme="theme" language="en-US" placeholder="Text here..." style="height: 300px" />

      <SelectTags :file-tags="[]" class="my-2" @update-selected-tags="updateSelectedTags" />

      <div class="my-2">
        <button class="btn-tw mx-2" type="button" @click="cancel()">Cancel</button>
        <button class="btn-tw mx-2" type="button" @click="createFile()">Create</button>
      </div>
    </div>
  </PopupScreen>
</template>

<script setup lang="ts">
import { ref, computed, defineAsyncComponent } from 'vue';
import { useUserSettingsStore } from '@/stores/user/settings';
import { useRoute } from 'vue-router';
import SelectTags from '@/components/resources/content/SelectTags.vue';
import PopupScreen from '@/components/resources/PopupScreen.vue';
import { File, FILE_TYPE } from '@/types/resources';
import { useFilesStore } from '@/stores/resources/files';
import { useClientLogsStore } from '@/stores/user/clientLogs';

// Lazy load
const MdEditor = defineAsyncComponent(() => import('md-editor-v3').then(module => module.MdEditor));

// Store
const filesStore = useFilesStore();
const userSettingsStore = useUserSettingsStore();
const clientLogsStore = useClientLogsStore();
const route = useRoute();
const emit = defineEmits(['closePopup']);

// Computed
const categoryId = computed(() => route.params.categoryId ? String(route.params.categoryId) : '');
const theme = computed(() => {
  const t = userSettingsStore.getTheme;
  return t.includes('dark') ? 'dark' : 'light';
});

// Variables
const fileType = ref(FILE_TYPE.ARTICLE);
const title = ref('');
const body = ref('');
const selectedTags = ref<string[]>([]);
const requestProcessing = ref(false);

// Functions
const cancel = (): void => {
  emit('closePopup');
};

const createFile = async (): Promise<void> => {
  if (title.value.length == 0 || body.value.length == 0) {
    // TODO highlight whats needed
    clientLogsStore.errorLog('[*][Resources] Add name / description / tags');
    return;
  }

  const file = new File('', body.value, new Date().toISOString(), selectedTags.value, title.value, fileType.value);
  requestProcessing.value = true;
  await filesStore.createFile(categoryId.value, fileType.value, file);
  requestProcessing.value = false;
  cancel();
};

const updateSelectedTags = (selectedTagsNew: string[]): void => {
  selectedTags.value = selectedTagsNew;
};
</script>

<style lang="scss" scoped></style>
