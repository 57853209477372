<template>
  <div>
    <button class="notifications-button" title="Open Notifiation Sidebar" @click="toggleDrawer()">
      <i class="ti-announcement" />
      <span class="notifications-button__count">{{ notifications.length }}</span>
    </button>

    <div v-show="drawerOpen" id="notificationElement" class="notifications notifications--show-history">
      <button class="notifications__close" @click="toggleDrawer()">
        X
      </button>
      <div class="notifications__column">
        <section v-if="orderedExchangeNames.length > 0" class="notifications__filter sidebar__setting">
          <label for="exchangeNameFilter" class="sidebar__setting__label">Exchange Name</label>
          <select id="exchangeNameFilter" v-model="exchangeNameFilter" class="sidebar__setting__input">
            <option value="">ALL</option>
            <option v-for="exchangeName in orderedExchangeNames" :key="exchangeName">{{ exchangeName }}</option>
          </select>
        </section>
        <div v-if="localNotifications" id="notificationsScroll" class="notifications__inner">
          <template v-for="notification in localNotifications.reverse()" :key="notification">
            <div
              v-if="notification"
              :class="' notifications__notification__item-- ' + notification.type"
              class="notifications__notification "
            >
              <p class="text-current">
                <template v-if="notification.exchangeName">[{{ notification.exchangeName }}]</template>
                {{ notification.message }}
              </p>
              <p class="text-current" style="font-size: .75em; margin: -8px 8px 3px 8px; font-style: italic;">
                {{ new Date(notification.timestamp).toISOString() }}
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useNotificationsStore } from '@/stores/user/notifications';

// Stores
const accountManagementStore = useAccountManagementStore();
const notificationsStore = useNotificationsStore();

// Reactive state
const exchangeNameFilter = ref('');

// Computed properties
const orderedExchangeNames = computed(() => accountManagementStore.getOrderedExchangesNames(true));
const drawerOpen = computed(() => notificationsStore.getDrawerOpen);
const notifications = computed(() => notificationsStore.notifications);

const localNotifications = computed(() => {
  const notificationsCopy = [...notifications.value]; // Create a copy to avoid mutating pinia state directly

  if (exchangeNameFilter.value === '') {
    return notificationsCopy;
  }

  return notificationsCopy.filter(notification => notification.exchangeName === exchangeNameFilter.value);
});

// Methods
const toggleDrawer = () => {
  notificationsStore.toggleDrawerOpen();
  exchangeNameFilter.value = ''; // Reset exchange name dropdown filter on close
};
</script>

<style lang="scss" scoped>
.notifications-button {
  border: 2px solid transparent;
  padding: 4px 16px 4px 10px;
  margin: 5px 8px 4px 0;
  font-size: 18px;
  transition: all ease .25s;
  position: relative;
  opacity: .5;

  &__count {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: white;
    color: black;
    font-size: 10px;
  }

  &:hover {
    background: var(--dropdown-bg--active);
    border: 2px solid var(--dropdown-border);
    opacity: 1;

    .notifications-button__count {
      color: var(--brand-primary);
    }
  }
}

.notifications {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  z-index: 999999;
  max-height: 100%;
  width: 350px;
  max-width: 100vw;
  pointer-events: none;
  border: 1px solid transparent;

  &__close {
    border: 1px solid var(--panel-border);
    background: var(--panel-header-bg);
    color: rgba(255,255,255,.2);
    z-index: 99999;
    width: 25px;
    padding: 5px;
    height: 100%;

    &:hover {
      background-color: var(--brand-primary);
      transition: all ease .25s;
      color: white;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
  }

  &__filter {
    border-bottom: 1px solid var(--panel-border);
    box-shadow: 0 5px 8px 2px black;
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: 10px;
    width: 100%;
  }

  &.notifications--show-history{
    background-color: black;
    pointer-events: all;
    justify-content: unset;
    border: 1px solid var(--panel-border);

    .hidden {
      pointer-events: all;
      transform: translateY(0%);
      height: auto;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }
    }

  }

  &.notifications--show-history {
    .hidden {
      transition: unset;
      &:hover {
        opacity: 1;
      }
    }
  }

  &__notification {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    color: black;
    font-size: 12px;
    border-radius: 2px;

    p {
      margin: 6px;
    }
  }
}
</style>
