<template>
  <div>
    <div v-for="(comment, index) in orderedComments" :key="comment.id">
      <CommentCard
        :comment="comment" :index="index" :file-id="fileId" :file-type="fileType" :category-id="categoryId"
      />
      <div v-if="comment.replyComments.length > 0">
        <div v-for="(replyComment, replyCommentIndex) in comment.replyComments" :key="replyComment.id">
          <CommentCard
            class="ml-5" :comment="replyComment" :index="replyCommentIndex" :file-id="fileId"
            :file-type="fileType" :category-id="categoryId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import CommentCard from '@/components/resources/content/CommentCard.vue';
import { useCommentsStore } from '@/stores/resources/comments';
import { Comment } from '@/types/resources';
import { useRoute } from 'vue-router';

// Store
const commentsStore = useCommentsStore();
const route = useRoute();

// Computed
const comments = computed<Record<string, Comment>>(() => commentsStore.comments);
const categoryId = computed(() => route.params.categoryId ? String(route.params.categoryId) : '');
const fileType = computed(() => route.params.fileType ? String(route.params.fileType) : '');
const fileId = computed(() => route.query.fileId ? String(route.query.fileId) : '');
const orderedComments = computed<Comment[]>(() => {
  const commentsNew: Comment[] = Object.values(comments.value);
  return commentsNew.sort((a: Comment, b: Comment) => {
    const date1 = new Date(a.created), date2 = new Date(b.created);
    return date2.getTime() - date1.getTime();
  });
});

// Variables

// Watchers

// Vue Lifecycle Functions

</script>

<style lang="scss" scoped>

</style>
