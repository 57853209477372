<template>
  <div class="panel">
    <div class="panel__header" :class="{ 'panel__header--active': !collasped }">
      <h3 class="panel__header__title">Instruments (results: {{ symbolsData.length }})</h3>
      <select v-model="forexFilter" name="forexFilter" class="panel__header__input">
        <option value="">Include FX Pairs</option>
        <option value="" disabled>--</option>
        <option value="exclude">Exclude FX Pairs</option>
        <option value="only">Only FX Pairs</option>
      </select>
      <select v-model="ordersFilter" name="ordersFilter" class="panel__header__input">
        <option value="">Include Pairs with Orders</option>
        <option value="" disabled>--</option>
        <option value="exclude">Exclude Pairs with Orders</option>
        <option value="only">Only Pairs with Orders</option>
      </select>
      <div class="panel__header__button panel__header__button--last">
        <router-link v-if="Object.keys($route.query).length > 0" class="button" :to="{ path: $route.fullPath }">
          Clear Filters
        </router-link>
      </div>
      <i
        :class="{ 'ti-angle-up': !collasped, 'ti-angle-down': collasped }"
        class="panel__header__icon" @click="collasped = !collasped"
      />
    </div>

    <div v-if="!collasped" class="panel__body vtable">
      <div class="vtable-header" style="padding-left: 1px;" @click="updateTableData($event)">
        <div class="vtable-header__item vtable__size--m-25 vtable__size--15" name="exchangeName">
          <select v-model="exchangeNameFilter" name="exchangeNameFilter" class="vtable-header__item__input">
            <option value="">EX. NAME</option>
            <option value="" disabled>--</option>
            <option v-for="exchangeName in exchangeNames.sort()" :key="exchangeName">{{ exchangeName }}</option>
          </select>
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--15">
          <select v-model="exchangeTypeFilter" name="exchangeTypeFilter" class="vtable-header__item__input">
            <option value="">EX. TYPE</option>
            <option value="" disabled>--</option>
            <option>Spot</option>
            <option>Derivatives</option>
          </select>
        </div>
        <div class="vtable-header__item vtable__size--m-30 vtable__size--15">
          <div class="vtable-header__item__input-wrap">
            <input
              v-model="symbolFilterInputFieldOnly" class="vtable-header__item__input-wrap__input" name="symbolFilter"
              placeholder="SYMBOL NAME" autocomplete="off" @input="debouncedSymbolSearch($event)"
            />
          </div>
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--15">
          <select v-model="marginAssetFilter" name="marginAssetFilter" class="vtable-header__item__input">
            <option value="">MARGIN ASSET</option>
            <option value="" disabled>--</option>
            <option v-for="marginAsset in marginAssets.sort()" :key="marginAsset">{{ marginAsset }}</option>
          </select>
        </div>
        <div class="vtable-header__item vtable__size--m-35 vtable__size--20" name="lastPrice">
          PRICE: LP (MP) <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--20" name="released">
          RELEASE DATE <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-10 vtable__size--d-hide" />
      </div>
      <DynamicScroller
        :items="symbolsData" :min-item-size="36" class="panel__body__table vtable panel__body__table--large"
      >
        <template #default="{ item, index, active }">
          <DynamicScrollerItem
            :item="item" :active="active" :size-dependencies="[item.showOverview]" :data-index="index"
          >
            <div
              class="vtable__row vtable__row--selection"
              :class="selectedSymbols.findIndex(
                element => element.symbol === item.symbol &&
                  element.exchangeType === item.exchangeType &&
                  element.exchangeName === item.exchangeName,
              ) > -1 ? 'selected' : ''"
              @click="updateSelectedSymbols(item)"
            >
              <div class="vtable__row__item vtable__size--m-25 vtable__size--15">
                <div v-if="!props.symbolLink"><i class="vtable__row__item__selection-dot" /></div>
                {{ item.exchangeName }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--15">{{ item.exchangeType }}</div>
              <div class="vtable__row__item vtable__size--m-30 vtable__size--15">
                <span v-if="props.symbolLink">
                  <router-link
                    class="table-link-tw" :title="item.symbol"
                    :to="{
                      path: `/exchanges/${item.exchangeName}/${item.exchangeType}/${encodeURIComponent(item.symbol)}`,
                    }"
                  >
                    {{ item.consistentSymbol }}
                  </router-link>
                </span>
                <span v-else>{{ item.consistentSymbol }}</span>
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--15">{{ item.marginAsset }}</div>
              <div class="vtable__row__item vtable__size--m-35 vtable__size--20">
                {{ formatNumber(item.lastPrice) }}
                <span v-if="item.markPrice != '0'"> ({{ formatNumber(item.markPrice) }})</span>
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--20">{{ formatDate(item.released) }}</div>
              <div class="vtable__row__item vtable__row__item--open-btn vtable__size--d-hide vtable__size--m-10">
                <button class="vtable__row__button" @click="item.showOverview = !item.showOverview">
                  <i :class="!item.showOverview ? 'ti-angle-down' : 'ti-angle-up'" />
                </button>
              </div>
              <div v-if="item.showOverview" class="vtable__row__overview">
                <div class="vtable__m-overview">
                  <div class="vtable__m-overview__item">
                    <label>EXCHANGE TYPE</label>
                    <div>{{ item.exchangeType }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label>MARGIN ASSET</label>
                    <div>{{ item.marginAsset }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label>RELEASE DATE </label>
                    <div>{{ formatDate(item.released) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { usePricesStore } from '@/stores/exchanges/prices';
import { useQueryStringStore } from '@/stores/queryString';
import { useRoute } from 'vue-router';
import { useOrdersStore } from '@/stores/exchanges/orders';
import { formatNumber } from '@/utilities';
import { forexAssets } from '@/stores/user/settings';
import { EXCHANGE_TYPE } from '@/types/exchange';
import { SelectedInstrument, InstrumentView, InstrumentViewKeys, InstrumentType } from '@/types/instruments';
import { useClientLogsStore } from '@/stores/user/clientLogs';

// Store
const accountManagementStore = useAccountManagementStore();
const instrumentsStore = useInstrumentsStore();
const ordersStore = useOrdersStore();
const pricesStore = usePricesStore();
const queryStringStore = useQueryStringStore();
const clientLogsStore = useClientLogsStore();

const route = useRoute();

const emit = defineEmits(['subscribeToSymbol', 'unsubscribeToSymbol', 'selectedInstruments']);

// Computed
const instruments = computed(() => instrumentsStore.instruments);
const prices = computed(() => pricesStore.prices);
const pairsWithOrders = computed(() => {
  const pairsWithOrders: Record<string, boolean> = {};

  for (const exchangeName in ordersStore.exchanges) {
    const exchange = ordersStore.exchanges[exchangeName];

    for (const accountId in exchange.accounts) {
      const account = exchange.accounts[accountId];

      for (const orderId in account.orders) {
        const order = account.orders[orderId];
        const key = `${exchangeName}+++${order.exchangeType}+++${order.symbol}`;

        pairsWithOrders[key] = true;
      }
    }
  }

  return pairsWithOrders;
});
const dynamicExchanges = computed(() => {
  const exchangeNames = [];

  if ('IG' in accountManagementStore.exchanges) {
    exchangeNames.push('IG');
  }

  if ('Capital' in accountManagementStore.exchanges) {
    exchangeNames.push('Capital');
  }

  return exchangeNames;
});

// Parent properties
const props = withDefaults(defineProps<{
  symbolLink: boolean,
}>(), {
  symbolLink: false,
});

// Variables
const selectedSymbols = ref<InstrumentView[]>([]);
const collasped = ref(false);
const symbolsData = ref<InstrumentView[]>([]);
const symbolsDataCacheTime = ref(0);
const exchangeNameFilter = ref('');
const exchangeTypeFilter = ref('');
const symbolFilter = ref('');
const symbolFilterInputFieldOnly = ref('');
const marginAssetFilter = ref('');
const forexFilter = ref('');
const ordersFilter = ref('');
const exchangeNames = ref<string[]>([]);
const marginAssets = ref<string[]>([]);
const sortBy = ref<InstrumentViewKeys>('exchangeName');
const orderBy = ref('asc');
const sortByTH = ref<HTMLElement>(undefined);
const timeout = ref<number | null>(null);

let previouslyFetchedInstruments: InstrumentType[] = [];

// Watchers
watch(instruments, (newInstruments) => {
  const newMarginAssets: Record<string, boolean> = {};

  for (const exchangeName in newInstruments) {
    for (const exchangeType in newInstruments[exchangeName]) {
      for (const symbol in newInstruments[exchangeName][exchangeType]) {
        const instrument = newInstruments[exchangeName][exchangeType][symbol];
        newMarginAssets[instrument.marginAsset] = true;
      }
    }
  }

  // Assuming these are reactive properties in your setup
  marginAssets.value = Object.keys(newMarginAssets);
  exchangeNames.value = Object.keys(newInstruments);

  if (dynamicExchanges.value.length) {
    for (const exchangeName of dynamicExchanges.value) {
      if (!exchangeNames.value.includes(exchangeName)) {
        exchangeNames.value.push(exchangeName);
      }
    }
  }

  void computeSymbolsData();
}, { deep: true });

watch(dynamicExchanges, () => {
  if (dynamicExchanges.value.length) {
    for (const exchangeName of dynamicExchanges.value) {
      if (!exchangeNames.value.includes(exchangeName)) {
        exchangeNames.value.push(exchangeName);
      }
    }
  }
}, { deep: true });

watch(prices, () => {
  void computeSymbolsData();
}, { deep: true });

watch(forexFilter, () => {
  symbolsDataCacheTime.value = 0;
  void computeSymbolsData();
});

watch(ordersFilter, () => {
  symbolsDataCacheTime.value = 0;
  void computeSymbolsData();
});

watch(sortBy, () => {
  symbolsDataCacheTime.value = 0;
  void computeSymbolsData();
});

watch(orderBy, () => {
  symbolsDataCacheTime.value = 0;
  void computeSymbolsData();
});

watch(exchangeNameFilter, (val: string) => {
  if (val !== route.query.exchangeName as string) {
    queryStringStore.update({ 'exchangeName': val || null });
  }
});

watch(exchangeTypeFilter, (val: string) => {
  if (val !== route.query.exchangeType as string) {
    queryStringStore.update({ 'exchangeType': val || null });
  }
});

watch(marginAssetFilter, (val: string) => {
  if (val !== route.query.marginAsset as string) {
    queryStringStore.update({ 'marginAsset': val || null });
  }
});

watch(() => route.query.exchangeName ? String(route.query.exchangeName) : '', (val: string) => {
  exchangeNameFilter.value = val || '';
  symbolsDataCacheTime.value = 0; // trigger re-rendering instantly
  void computeSymbolsData(true);
});

watch(() => route.query.exchangeType ? String(route.query.exchangeType) : '', (val: string) => {
  exchangeTypeFilter.value = val || '';
  symbolsDataCacheTime.value = 0; // trigger re-rendering instantly
  void computeSymbolsData();
});

watch(() => route.query.symbol ? String(route.query.symbol) : '', (val: string) => {
  if (symbolFilter.value != val) {
    symbolFilter.value = val || '';
    symbolsDataCacheTime.value = 0; // trigger re-rendering instantly
    void computeSymbolsData(true);
  }
});

watch(() => route.query.marginAsset ? String(route.query.marginAsset) : '', (val: string) => {
  marginAssetFilter.value = val || '';
  symbolsDataCacheTime.value = 0; // trigger re-rendering instantly
  void computeSymbolsData();
});

// Vue Lifecycle Functions
onMounted(() => {
  exchangeNameFilter.value = route.query.exchangeName as string || '' ;
  exchangeTypeFilter.value = route.query.exchangeType as string || '' ;
  marginAssetFilter.value = route.query.marginAsset as string || '' ;
  symbolFilter.value = route.query.symbol as string || '' ;
  symbolFilterInputFieldOnly.value = route.query.symbol as string || '' ;

  exchangeNames.value = Object.keys(instruments.value);

  if (dynamicExchanges.value.length) {
    for (const exchangeName of dynamicExchanges.value) {
      if (!exchangeNames.value.includes(exchangeName)) {
        exchangeNames.value.push(exchangeName);
      }
    }
  }

  void computeSymbolsData(true);
});

// Functions
const updateSelectedSymbols = (item: InstrumentView) => {
  const index = selectedSymbols.value.findIndex(
    element => element.symbol === item.symbol &&
      element.exchangeType === item.exchangeType &&
      element.exchangeName === item.exchangeName,
  );

  if (index < 0) {
    selectedSymbols.value.push(item);
    emit('subscribeToSymbol', item);
  } else {
    selectedSymbols.value.splice(index, 1);
    emit('unsubscribeToSymbol', item);
  }
};

const debouncedSymbolSearch = (event: Event) => {
  if (timeout.value) clearTimeout(timeout.value);
  timeout.value = window.setTimeout(() => {
    const target = event.target as HTMLInputElement;

    if (target.value !== route.query.symbol as string) {
      if (target.value !== '') {
        queryStringStore.update({ 'symbol': target.value });
      } else {
        queryStringStore.update({ 'symbol': null });
      }
    }
  }, 500);
};

const formatDate = (epoch: number) => {
  const date = new Date(epoch * 1000);
  return date.toLocaleDateString('en-GB');
};

const computeSymbolsData = async (refetchData = false) => {
  // Only recompute at most once every 2 seconds
  if (new Date().getTime() - symbolsDataCacheTime.value < 2000) {
    return;
  }

  const symbols: InstrumentView[] = [];
  const selectedInstruments: SelectedInstrument[] = [];
  const newMarginAssets: Record<string, boolean> = {};

  if (symbolFilter.value === '' && exchangeNameFilter.value === '' && exchangeTypeFilter.value === '' &&
      marginAssetFilter.value === '' && forexFilter.value === '' && ordersFilter.value === '')
  {
    marginAssets.value = Object.keys(newMarginAssets);
    symbolsData.value = symbols;
    symbolsDataCacheTime.value = new Date().getTime();
    emit('selectedInstruments', selectedInstruments);
    return;
  }

  if (['IG', 'Capital'].includes(exchangeNameFilter.value)) {
    if (symbolFilter.value === '') { // A search value must be specified (cannot simply return all instruments)
      marginAssets.value = Object.keys(newMarginAssets);
      symbolsData.value = symbols;
      symbolsDataCacheTime.value = new Date().getTime();
      emit('selectedInstruments', selectedInstruments);
      return;
    }

    let instruments = previouslyFetchedInstruments;

    if (refetchData) {
      instruments = await instrumentsStore.searchInstruments(
        exchangeNameFilter.value, symbolFilter.value,
      );
      previouslyFetchedInstruments = instruments;
    }

    for (const instrument of instruments) {
      if (exchangeTypeFilter.value !== '' && exchangeTypeFilter.value !== EXCHANGE_TYPE.DERIVATIVES) {
        continue;
      }

      const instrumentView = new InstrumentView(
        `${exchangeNameFilter.value}-${exchangeTypeFilter.value}-${instrument.symbol}`, exchangeNameFilter.value,
        EXCHANGE_TYPE.DERIVATIVES, instrument.symbol, instrument.consistentSymbol, '', 0, '', '', '', instrument,
      );
      symbols.push(instrumentView);
    }
  } else {
    for (const exchangeName in instruments.value) {
      if (exchangeNameFilter.value !== '' && exchangeNameFilter.value !== exchangeName) {
        continue;
      }

      for (const exchangeType in instruments.value[exchangeName]) {
        if (exchangeTypeFilter.value !== '' && exchangeTypeFilter.value !== exchangeType) {
          continue;
        }

        for (const symbol in instruments.value[exchangeName][exchangeType]) {
          const instrument = instruments.value[exchangeName][exchangeType][symbol];

          if (instrument.consistentSymbol.toLowerCase().indexOf(symbolFilter.value.toLowerCase()) === -1) {
            continue;
          }

          // Deliberately show other margin assets when selecting a single margin asset
          newMarginAssets[instrument.marginAsset] = true;

          if (marginAssetFilter.value !== '' && marginAssetFilter.value !== instrument.marginAsset) {
            continue;
          }

          const [baseAsset, quoteAsset] = instrument.consistentSymbol.split('-');

          if (forexFilter.value === 'only') {
            if (!forexAssets.includes(baseAsset) || !forexAssets.includes(quoteAsset)) {
              continue;
            }
          } else if (forexFilter.value === 'exclude') {
            if (forexAssets.includes(baseAsset) && forexAssets.includes(quoteAsset)) {
              continue;
            }
          }

          const key = `${exchangeName}+++${exchangeType}+++${instrument.symbol}`;

          if (ordersFilter.value === 'only') {
            if (pairsWithOrders.value[key] === undefined) {
              continue;
            }
          } else if (ordersFilter.value === 'exclude') {
            if (pairsWithOrders.value[key] !== undefined) {
              continue;
            }
          }

          let lastPrice = '0', markPrice = '0', quoteVolume = '0';

          if (prices.value?.[exchangeName]?.[exchangeType]?.[symbol] !== undefined) {
            lastPrice = prices.value[exchangeName][exchangeType][symbol].lastPrice;
            markPrice = prices.value[exchangeName][exchangeType][symbol].markPrice;
            quoteVolume = prices.value[exchangeName][exchangeType][symbol].quoteVolume;
          } else {
            clientLogsStore.warningLog(
              `[${exchangeName}][${exchangeType}][${symbol}] No price information found at (` +
              `${String(prices.value?.[exchangeName] !== undefined)}, ` +
              `${String(prices.value?.[exchangeName]?.[exchangeType] !== undefined)}, ` +
              `${String(prices.value?.[exchangeName]?.[exchangeType]?.[symbol] !== undefined)})`,
            );
          }

          if (!instrument.showOverview) {
            instrument.showOverview = false;
          }

          const data = new InstrumentView(
            `${exchangeName}-${exchangeType}-${symbol}`, exchangeName, exchangeType, symbol,
            instrument.consistentSymbol, instrument.marginAsset, instrument.released, lastPrice,
            markPrice, quoteVolume, instrument,
          );

          symbols.push(data);

          selectedInstruments.push(
            new SelectedInstrument(exchangeName, exchangeType, instrument.symbol, instrument.consistentSymbol),
          );
        }
      }
    }
  }

  symbols.sort((a, b) => {
    const x = typeof a[sortBy.value] === 'number' ? a[sortBy.value] : +a[sortBy.value];
    const y = typeof b[sortBy.value] === 'number' ? b[sortBy.value] : +b[sortBy.value];

    if ((x < y && orderBy.value === 'asc') || (x > y && orderBy.value === 'desc')) {
      return -1;
    }

    if ((x > y && orderBy.value === 'asc') ||  (x < y && orderBy.value === 'desc')) {
      return 1;
    }

    return 0;
  });

  marginAssets.value = Object.keys(newMarginAssets);
  symbolsData.value = symbols;
  symbolsDataCacheTime.value = new Date().getTime();
  emit('selectedInstruments', selectedInstruments);
};

const updateTableData = (e: Event) => {
  let target = e.target as HTMLElement;
  if (target.classList.contains('sort-icon')) {
    target = target.parentNode as HTMLElement;
  }
  const name = target.getAttribute('name');

  if (
    !name ||
      name === 'symbolFilter' ||
      name === 'exchangeNameFilter' ||
      name === 'exchangeTypeFilter') {
    return;
  }

  if (sortByTH.value) {
    sortByTH.value.classList.remove('asc');
    sortByTH.value.classList.add('desc');
  }

  if (name === sortBy.value) {
    // Update orderBy
    const lastOrderBy = orderBy.value;
    orderBy.value = orderBy.value === 'asc' ? 'desc' : 'asc';

    if (sortByTH.value) {
      sortByTH.value.classList.remove(lastOrderBy);
      sortByTH.value.classList.add(orderBy.value);
    }
  } else {
    // Update sortBy
    sortBy.value = name as InstrumentViewKeys;

    if (sortByTH.value) {
      sortByTH.value.classList.remove('desc');
      sortByTH.value.classList.remove('asc');
    }
    sortByTH.value = target;
    orderBy.value = 'asc';
    sortByTH.value.classList.add('asc');
  }
};
</script>

<style lang="scss" scoped></style>
