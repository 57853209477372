<template>
  <section class="sidebar__setting">
    <label class="sidebar__setting__label">{{ categoryTitle }}</label>
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useCategoriesStore } from '@/stores/resources/categories';
import { Category } from '@/types/resources';
import { useRoute } from 'vue-router';

// Store
const categoriesStore = useCategoriesStore();
const route = useRoute();

// Computed
const category = computed<Category> (() => categoriesStore.getCategory(categoryId.value));
const categoryTitle = computed(() => category.value ? category.value.title: '');
const categoryId = computed(() => route.params.categoryId ? String(route.params.categoryId) : '');

// Functions
</script>

<style lang="scss" scoped></style>
