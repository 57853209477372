import { PriceData, PriceDataServer, PriceDataItemServer, Candle } from '@/types/pricedata';

export interface TradingviewSymbolsServerResp {
  category: string;
  type: string;
  page: number;
  symbols: TradingViewSymbolServer[];
}

export interface TradingViewSymbolServer {
  symbol: string;
  type: string;
  exchange: string;
}

export class SymbolType {
  public symbol: string;
  public exchange: string;
  public type: string;
  public fatFingerAnlaysis: boolean;
  public fatFingerErrorInfo: string;
  public fatFingerInfo: Candle[];
  public selected = true;

  constructor(symbol: string, exchange: string, type: string) {
    this.symbol = symbol;
    this.exchange = exchange;
    this.type = type;
  }
}

export interface TradingviewState {
  symbols: Map<string, SymbolType>
  symbolData: PriceData;
}

export interface TradingviewSymbolDataServerResp {
  category: string;
  type: string;
  priceData: PriceDataServer
}

export interface TradingviewSymbolDataFatFingerServerResp {
  category: string;
  type: string;
  symbol: string;
  error: string;
  fatFingers: PriceDataItemServer[];
}
