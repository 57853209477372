import { defineStore } from 'pinia';
import {
  ResourcesState, ResourcesAuthLinkServerResp, ResourcesChannelIdServerResp, ResourceWrapper,
} from '@/types/resources';
import { createRequestData } from '@/utilities';
import { useUserStore } from '@/stores/user/user';
import { createNotification } from '@/stores/user/notifications';
import Config from '@/config';
import { NOTIFICATION_TYPE } from '@/types/user';
import { RespError } from '@/types/general';

export const useResourcesStore = defineStore('resources', {
  state: (): ResourcesState => ({
    // Users youtube channel id
    resourcesAuthLink: '',
    resourcesChannelId: '',
  }),
  actions: {
    async getAuthLink(): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/authLink`, createRequestData('GET', useUserStore().token, null),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        const data = await response.json() as ResourcesAuthLinkServerResp;
        this.resourcesAuthLink = data.resourcesAuthLink;
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error getAuthLink ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    async logout(): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/logout`, createRequestData('POST', useUserStore().token, null),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        this.resourcesChannelId = '';
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error logging out of resources ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    async authenticateUser(): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/authenticateUser`, createRequestData('GET', useUserStore().token, null),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        const data = await response.json() as ResourcesChannelIdServerResp;
        this.resourcesChannelId = data.resourcesChannelId;
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error authenticating resources users ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    async createAuthCode(body: ResourceWrapper): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/authCode`, createRequestData('POST', useUserStore().token, body),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        void this.authenticateUser();
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error createAuthCode ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
  },
  getters: {
    isLoggedIn: (state): boolean => {
      return state.resourcesChannelId !== '';
    },
  },
});
