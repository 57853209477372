<template>
  <div class="comment-card">
    <p class="comment-author">{{ comment.author }}</p>

    <div v-if="!editingComment">
      <p class="comment-text">{{ comment.body }}</p>

      <div class="comment-actions">
        <p
          v-if="comment.parentId == ''" class="comment-replies" :class="{ 'comment-text-reply': hasReplies }"
          @click="getReplyComments()"
        >
          Replies: {{ comment.totalReplyCount }}
        </p>
        <div v-if="hasWriteAuth(comment.authorChannelId)">
          <i class="edit-icon ti-pencil" @click="editComment()" />
          <i class="delete-icon ti-trash" @click="deleteComment(comment)" />
        </div>
      </div>
    </div>
    <div v-else>
      <textarea v-model="newCommentText" class="comment-textarea" />
      <div class="comment-actions">
        <p
          v-if="comment.parentId == ''" class="comment-replies" :class="{ 'comment-text-reply': hasReplies }"
          @click="getReplyComments()"
        >
          Replies: {{ comment.totalReplyCount }}
        </p>
        <div v-if="requestProcessing" class="text-center">
          <img class="spin-animation" :src="spinningCircle" />
        </div>
        <div v-else>
          <i class="close-icon ti-close" @click="editingComment = false" />
          <i class="save-icon ti-save-alt" @click="updateComment(comment)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useResourcesStore } from '@/stores/resources/resources';
import { Comment  } from '@/types/resources';
import { useCommentsStore } from '@/stores/resources/comments';
import { useClientLogsStore } from '@/stores/user/clientLogs';
import spinningCircle from '@/assets/spinning-circle.svg';

// Store
const resourcesStore = useResourcesStore();
const commentsStore = useCommentsStore();
const clientLogsStore = useClientLogsStore();

// Parent variables
const props = withDefaults(defineProps<{
  comment: Comment,
  index: number,
  fileId: string,
  fileType: string,
  categoryId: string,
}>(), {
  comment: null,
  index: 0,
  fileId: '',
  fileType: '',
  categoryId: '',
});

// Computed
const resourceChannelId = computed(() => resourcesStore.resourcesChannelId);
const hasReplies = computed(() => props.comment.totalReplyCount > 0);

// Variables
const requestProcessing = ref(false);
const editingComment = ref(false);
const newCommentText = ref('');

// Watchers

// Functions
const editComment = () => {
  editingComment.value = true;
  newCommentText.value = props.comment.body;
};

const updateComment = async (comment: Comment): Promise<void> => {
  // Make copy of comment so not mutating orginal comment
  const updatedComment = { ...comment };

  if (newCommentText.value.length == 0) {
    clientLogsStore.errorLog('[*][Resources] Todo body can\'t be empty');
    return;
  }

  updatedComment.body = newCommentText.value;
  updatedComment.updated = new Date();

  requestProcessing.value = true;
  await commentsStore.updateComment(props.categoryId, props.fileType, props.fileId, updatedComment.id, updatedComment);
  requestProcessing.value = false;
  editingComment.value = false;
};

const deleteComment = async (comment: Comment): Promise<void> => {
  const msg = `Are you sure you want to delete comment ${comment.id}?`,
    confirmation = window.confirm(msg);

  if (!confirmation) return;


  requestProcessing.value = true;
  await commentsStore.deleteComment(props.categoryId, props.fileType, props.fileId, comment.id);
  requestProcessing.value = false;
};

const hasWriteAuth = (commentAuthorChannelId: string): boolean => {
  return resourceChannelId.value == commentAuthorChannelId;
};

const getReplyComments = async (): Promise<void> => {
  // Don't get as no reply comments
  if (props.comment.totalReplyCount == 0) return;
  // Don't get as reply comments already got
  if (props.comment.replyComments.length > 0) return;

  requestProcessing.value = true;
  await commentsStore.getReplyComments(props.categoryId, props.fileType, props.fileId, props.comment.id);
  requestProcessing.value = false;
};
</script>

<style lang="scss" scoped>
.comment-card {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #444;
}

.comment-author {
  padding: 5px;
  font-weight: bold;
  color: #cccccc;
  margin: 0px;
  font-size: 8px; /* Reduce font size for compactness */
  border-bottom: 1px solid #444;
}

.comment-actions {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #444;
}

.comment-replies {
  font-size: 10px;
  color: #cccccc;
  cursor: pointer;
}

.edit-icon,
.delete-icon,
.close-icon,
.save-icon {
  font-size: 14px;
  color: #cccccc;
  cursor: pointer;
  margin-left: 5px;
}

.edit-icon:hover,
.delete-icon:hover,
.close-icon:hover,
.save-icon:hover {
  color: #ffffff; /* Change color on hover for a visual cue */
}

.comment-text {
  padding: 5px;
  font-size: 12px;
  color: #ffffff;
  margin: 0px !important;
  word-wrap: break-word; /* Ensures long words wrap to the next line */
  white-space: normal;    /* Allows text to wrap */
  overflow: visible;      /* Ensure no content is clipped */
  text-overflow: initial; /* No text truncation */
}

.comment-textarea {
  background-color: #1f1f1f; /* Same dark background */
  color: #ffffff; /* White text */
  border: none; /* Remove default border */
  padding: 5px; /* Padding inside the textarea */
  width: 100%;
  font-size: 12px; /* Adjust text size */
  overflow: hidden; /* Hide overflow if any */
  outline: none; /* Remove focus outline */
  margin: 5px 0px;
}
</style>
