<template>
  <div v-if="props.open" class="panel panel--match-height panel--margin-right" data-testid="exchange-filter-panel">
    <div class="panel__header">
      Filter
      <button class="panel__header__close-btn" title="Close Filter" @click="toggleSidebarOpen()">
        X
      </button>
    </div>

    <section class="sidebar__setting">
      <div class="sidebar__setting__toggle">
        <input
          id="aggregateByPair" v-model="aggregateByPair" type="checkbox"
          :disabled="orderStatusFilter !== ORDER_STATUS.UNFILLED && orderStatusFilter !== ORDER_STATUS.PARTIALLY_FILLED"
        />
        <label for="aggregateByPair">Aggregate Orders</label>
      </div>
    </section>

    <section v-if="exchanges" class="sidebar__setting">
      <label for="exchangeNameFilter" class="sidebar__setting__label">Exchange</label>
      <select id="exchangeNameFilter" v-model="exchangeNameFilter" class="sidebar__setting__input">
        <option value="">ALL</option>
        <option v-for="exchangeName in orderedExchangeNames" :key="exchangeName">
          {{ exchangeName }}
        </option>
      </select>
    </section>

    <section class="sidebar__setting">
      <label class="sidebar__setting__label">Exchange Type </label>
      <div class="sidebar__setting__buttons">
        <button
          class="sidebar__setting__buttons__btn" :class="{'selected': $route.query.exchangeType == EXCHANGE_TYPE.SPOT}"
          title="Spot"
          data-testid="exchange-filter-type-spot"
          @click="toggleType(EXCHANGE_TYPE.SPOT)"
        >
          S
        </button>
        <button
          class="sidebar__setting__buttons__btn"
          :class="{'selected': $route.query.exchangeType == EXCHANGE_TYPE.DERIVATIVES}"
          title="Derivatives"
          data-testid="exchange-filter-type-derivatives"
          @click="toggleType(EXCHANGE_TYPE.DERIVATIVES)"
        >
          D
        </button>
      </div>
    </section>

    <section class="sidebar__setting">
      <label class="sidebar__setting__label">Side</label>
      <div class="sidebar__setting__buttons">
        <button
          class="sidebar__setting__buttons__btn green" :class="{'deselected': $route.query.side == SIDE.SELL}"
          data-testid="exchange-filter-side-buy"
          @click="toggleSide(SIDE.BUY)"
        >
          Buy
        </button>
        <button
          class="sidebar__setting__buttons__btn red" :class="{'deselected': $route.query.side == SIDE.BUY}"
          data-testid="exchange-filter-side-sell"
          @click="toggleSide(SIDE.SELL)"
        >
          Sell
        </button>
      </div>
    </section>

    <section class="sidebar__setting">
      <label for="orderCategoryFilter" class="sidebar__setting__label">Order Category</label>
      <select
        id="orderCategoryFilter"
        v-model="orderCategoryFilter"
        class="sidebar__setting__input"
        data-testid="exchange-filter-order-type"
      >
        <option :value="ORDER_CATEGORY.NONE">Any</option>
        <option>{{ ORDER_CATEGORY.PLAIN }}</option>
        <option>{{ ORDER_CATEGORY.LIQUIDITY }}</option>
        <option>{{ ORDER_CATEGORY.TRADING }}</option>
        <option>{{ ORDER_CATEGORY.INVESTMENT }}</option>
      </select>
    </section>

    <section class="sidebar__setting">
      <label for="orderStatusFilter" class="sidebar__setting__label">Order Status</label>
      <select
        id="orderStatusFilter"
        v-model="orderStatusFilter"
        class="sidebar__setting__input"
        data-testid="exchange-filter-order-status"
      >
        <option>{{ ORDER_STATUS.UNFILLED }}</option>
        <option>{{ ORDER_STATUS.PARTIALLY_FILLED }}</option>
        <option>{{ ORDER_STATUS.FILLED }}</option>
        <option>{{ ORDER_STATUS.FAILED }}</option>
      </select>
    </section>

    <section class="sidebar__setting">
      <label class="sidebar__setting__label">Pair Type</label>
      <div class="sidebar__setting__switches">
        <div class="toggle">
          <input id="showForexOrders" v-model="showForexOrders" type="checkbox" />
          <label for="showForexOrders">Forex</label>
        </div>

        <div class="toggle">
          <input id="showIndicesOrders" v-model="showIndicesOrders" type="checkbox" />
          <label for="showIndicesOrders">Indices</label>
        </div>

        <div class="toggle">
          <input id="showOtherOrders" v-model="showOtherOrders" type="checkbox" />
          <label for="showOtherOrders">Others</label>
        </div>
      </div>
    </section>

    <section v-if="exchangeNameFilter !== '' && exchanges && exchanges[exchangeNameFilter]" class="sidebar__setting">
      <label for="accountFilter" class="sidebar__setting__label">Account</label>
      <select id="accountFilter" v-model="accountIdFilter" class="sidebar__setting__input">
        <option value="">ALL</option>
        <option
          v-for="account in exchanges[exchangeNameFilter].accounts"
          :key="account.id" :value="account.id"
        >
          {{ account.name }}
        </option>
      </select>
    </section>
    <section v-else class="sidebar__setting sidebar__setting--disabled">
      <label for="accountFilter" class="sidebar__setting__label">Account</label>
      <select id="accountFilter" class="sidebar__setting__input">
        <option value="">ALL</option>
      </select>
    </section>
  </div>
  <button v-else class="panel open-button" data-testid="exchange-filter-open-btn" @click="toggleSidebarOpen()">
    <i class="ti-filter icon" /><p>Filter</p>
  </button>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useQueryStringStore } from '@/stores/queryString';
import { useRoute } from 'vue-router';
import { SIDE } from '@/types/account';
import { EXCHANGE_TYPE } from '@/types/exchange';
import { ORDER_STATUS, ORDER_CATEGORY } from '@/types/orders';
import { toggleSidebarOpen } from '@/stores/shared';

// Store
const accountManagementStore = useAccountManagementStore();
const queryStringStore = useQueryStringStore();
const route = useRoute();

const orderedExchangeNames = computed(() => accountManagementStore.getOrderedExchangesNames());
const exchanges = computed(() => accountManagementStore.exchanges);
const props = defineProps({
  open: Boolean,
});

const aggregateByPair = ref(false);
const showForexOrders = ref(true);
const showIndicesOrders = ref(true);
const showOtherOrders = ref(true);

const exchangeNameFilter = ref('');
const accountIdFilter = ref('');
const orderCategoryFilter = ref(ORDER_CATEGORY.NONE);
const orderStatusFilter = ref(ORDER_STATUS.UNFILLED);

watch(() => route.query.aggregate, (val) => {
  aggregateByPair.value = val === 'true';
}, { immediate: true });

watch(() => route.query.hideForexOrders, (val) => {
  showForexOrders.value = val !== 'true';
}, { immediate: true });

watch(() => route.query.hideIndicesOrders, (val) => {
  showIndicesOrders.value = val !== 'true';
}, { immediate: true });

watch(() => route.query.hideOtherOrders, (val) => {
  showOtherOrders.value = val !== 'true';
}, { immediate: true });

watch(() => route.query.exchangeName, (val) => {
  exchangeNameFilter.value = val ? String(val) : '';
}, { immediate: true });

watch(() => route.query.accountId, (val) => {
  accountIdFilter.value = val ? String(val) : '';
}, { immediate: true });

watch(() => route.query.orderCategory, (val) => {
  orderCategoryFilter.value = val as ORDER_CATEGORY || ORDER_CATEGORY.NONE;
}, { immediate: true });

watch(() => route.query.orderStatus, (val) => {
  orderStatusFilter.value = val as ORDER_STATUS || ORDER_STATUS.UNFILLED;
}, { immediate: true });

watch(exchangeNameFilter, (val) => {
  if (val !== route.query.exchangeName) {
    accountIdFilter.value = '';
    queryStringStore.update({ exchangeName: val || null });
  }
});

watch(accountIdFilter, (val) => {
  if (val !== route.query.accountId) {
    queryStringStore.update({ accountId: val || null });
  }
});

watch(orderCategoryFilter, (val) => {
  if (val !== route.query.orderCategory) {
    queryStringStore.update({ orderCategory: val || null });
  }
});

watch(orderStatusFilter, (val) => {
  if (val !== route.query.orderStatus) {
    queryStringStore.update({ orderStatus: val || null });
  }
});

watch(aggregateByPair, (val: boolean) => {
  if (val.toString() !== route.query.aggregate) {
    queryStringStore.update({ aggregate: String(val) || null });
  }
});

watch(showForexOrders, (val: boolean) => {
  if (val.toString() !== route.query.hideForexOrders) {
    queryStringStore.update({ hideForexOrders: String(!val) || null });
  }
});

watch(showIndicesOrders, (val) => {
  if (val.toString() !== route.query.hideIndicesOrders) {
    queryStringStore.update({ hideIndicesOrders: String(!val) || null });
  }
});

watch(showOtherOrders, (val) => {
  if (val.toString() !== route.query.hideOtherOrders) {
    queryStringStore.update({ hideOtherOrders: String(!val) || null });
  }
});

const toggleSide = (side: string) => {
  if (side !== route.query.side) {
    queryStringStore.update({ 'side': String(side) });
  } else {
    queryStringStore.update({ 'side': null });
  }
};

const toggleType = (type: EXCHANGE_TYPE) => {
  if (String(type) !== route.query.exchangeType) {
    queryStringStore.update({ 'exchangeType': String(type) });
  } else {
    queryStringStore.update({ 'exchangeType': null });
  }
};
</script>

<style lang="scss">
$mobile-menu-breakpoint: 900px;
.open-button {
  height: 100%;
  background: var(--panel-header-bg);
  border: 1px solid var(--panel-border);
  color: rgba(255,255,255,0.2);
  transition: all 0.2s ease;
  width: 100%;

  .icon {
    color: inherit;
    font-size: 14px;
  }
  p {
    writing-mode: vertical-rl;text-orientation: upright;
    letter-spacing: .1em;;
    text-transform: uppercase;
    color: inherit;
    transition: letter-spacing 0.2s ease;
    font-size: 0;
  }
  &:hover {
    background-color: var(--brand-primary);
    color: white;

    @media (min-width: $mobile-menu-breakpoint)  {
      p {
        letter-spacing: 1em;
        font-size: 1em;
      }
      .icon {
        display: none;
      }
    }
  }
}
.sidebar {
  display: flex;
  flex-direction: column;

  &__setting {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--panel-border);
    padding: 2px 0px;
    margin: 0 4px;

    &--disabled {
      opacity: .4;
    }

    &__label {
      margin: 10px 10px;
      font-size: 14px;
      width: 47%;
    }

    &__input {
      background: var(--panel-header-bg);
      border: 1px solid var(--panel-border);
      padding: 2px;
      max-width: 100%;
      display: flex;
      font-size: 12px;
      width: 42%;
    }

    &__toggle {
      padding: 8px 8px;
      display: flex;
      align-items: center;

      label {
        margin-left: 5px;
        font-size: 14px;
      }
    }

    &__switches {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: -10px;
      padding: 0 2px 4px;
    }

    &__buttons {
      padding: 2px !important;
      flex-grow: 2;

      &__btn {
        padding: 2px 0;
        text-transform: uppercase;
        color: #fff;
        min-width: 10px;
        width: 50%;
        height: 24px;
        margin: 5px 0 5px 0;
        background: var(--panel-header-bg);
        border: 0;
        border: 1px solid var(--panel-border);
        font-size: 14px;
        line-height: 1em;

        &:hover {
          background: var(--brand-primary);
        }
        &.selected {
          background: #fff;
          color: black;
        }
        &:last-child {
          border-left: 0;
        }

        &.green {
          background: #27ae60;
          border-color: #27ae60;

          &:hover {
            // background: darken(#27ae60, 5%);
          }
          &.deselected {
            background: rgba(#27ae60, .25);
            border-color: rgba(#27ae60, .25);
          }
        }
        &.red {
          background: #c0392b;
          border-color: #c0392b;

          &:hover {
            // background: darken(#c0392b, 5%);
          }
          &.deselected {
            background: rgba(#c0392b, .25);
            border-color: rgba(#c0392b, .25);
          }
        }
      }
    }
  }
}
</style>

