import { formatNumber } from '@/utilities';
import { ORDER_TYPE, ActiveAction } from '@/types/orders';
import { SIDE } from '@/types/account';
import { InstrumentType } from '@/types/instruments';
import { EXCHANGE_TYPE } from './exchange';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { useUserSettingsStore } from '@/stores/user/settings';

export enum POSITION_STATUS {
  OPEN = 'Open',
  CLOSED = 'Closed',
}

export type calculatePnLInternalFunction =
  ((fromPrice: string, toPrice: string, quantity: string, side: SIDE, instrument: InstrumentType) => string);

export type PositionTypeKeys = keyof PositionType;

export class PositionType {
  public id: string;
  public symbol: string;
  public created: number;
  public updated: number;
  public price: string; // TODO: make this `entries: string[]`
  public stopLossPrice: string; // TODO: make this `stopLosses: string[]`
  public profitTargetPrice: string; // TODO: make this `profitTargets: string[]`
  public quantity: string;
  public margin: string;
  public marginAsset: string;
  public side: SIDE;
  public status: POSITION_STATUS;
  public fills: FillType[] = [];
  public leverage: string;
  public liquidation: string;
  public calculatePnLInternal: null | calculatePnLInternalFunction;
  public dpFormat = 2;
  public activeAction: ActiveAction;

  // IG properties
  public pnl: string = '0'; // Should be with other properties eventually

  // Auxiliary properties for presentation
  public exchangeName: string = '';
  public exchangeType: string = '';
  public accountId: string;
  // For calculating the initial risk when entering the trade
  public initialPrice: string;
  public initialQuantity: string;
  public initialStopLoss: string;
  public initialProfitTarget: string;

  constructor(id: string) {
    this.id = id;
    this.activeAction = new ActiveAction();
  }

  public setPrice(price: string): void {
    if (!this.initialPrice) {
      this.initialPrice = price;
    }

    this.price = price;
  }

  public setStopLoss(stopLoss: string): void {
    if (!this.initialStopLoss) {
      this.initialStopLoss = stopLoss;
    }

    this.stopLossPrice = stopLoss;
  }

  public setProfitTarget(profitTarget: string): void {
    if (!this.initialProfitTarget) {
      this.initialProfitTarget = profitTarget;
    }

    this.profitTargetPrice = profitTarget;
  }

  public setQuantity(quantity: string): void {
    if (!this.initialQuantity) {
      this.initialQuantity = quantity;
    }

    this.quantity = quantity;
  }

  public ToString(): string {
    const pnl = useUserSettingsStore().getChosenAssetSymbolWithAmount(formatNumber(this.pnl));

    return `${this.side} ${this.quantity} @ ${this.price} ` +
      `(stop = ${this.stopLossPrice}, target = ${this.profitTargetPrice}, ` +
      `status = ${this.status}, PnL = ${pnl})`;
  }

  public addFill(fill: FillType): void {
    this.fills.push(fill);
  }

  public calculatePnL(fromPrice: string, toPrice: string, quantity: string): string {
    if (this.calculatePnLInternal === null || fromPrice === '' || toPrice === '' || quantity === '') {
      return 'NaN'; // Not implemented value
    }

    const instrument = useInstrumentsStore().getInstrument(this.exchangeName, this.exchangeType, this.symbol);

    if (instrument === null) {
      return 'NaN';
    }

    const pnl = this.calculatePnLInternal(fromPrice, toPrice, quantity, this.side, instrument);
    let currency = '';

    if (this.exchangeType === EXCHANGE_TYPE.DERIVATIVES) {
      currency = instrument.marginAsset;
    } else {
      currency = instrument.quoteAsset;
    }

    return String(useUserSettingsStore().getChosenAssetValue(this.exchangeName, currency, +pnl, false));
  }

  public clone(): PositionType {
    // Object.create() only does a shallow clone
    const positionClone = Object.assign(new PositionType(this.id), this as PositionType);

    positionClone.activeAction = Object.assign(new ActiveAction(), this.activeAction);
    positionClone.fills = [];

    for (const fill of this.fills) {
      positionClone.fills.push(Object.assign(new FillType(), fill));
    }

    return positionClone;
  }
}

export interface PositionTypeServer {
  id: string;
  symbol: string;
  created: number;
  updated: number;
  quantity: string;
  margin: string;
  marginAsset: string;
  side: string;
  price: string;
  stopLossPrice: string;
  profitTargetPrice: string;
  status: string;
  leverage: string;
  liquidation: string
  fills: FillTypeServer[];
  exchangeType: string;
  activeAction: ActiveAction;
  lock: number;
}

export interface FillTypeServer {
  id: string;
  quantity: string;
  side: string;
  created: number;
  price: string;
  type: string;
  fee: string;
}

export class FillType {
  public quantity: string;
  public created: number;
  public price: string;
  public type: ORDER_TYPE;
  public fee: string;
  public pnl: string;
  public side: SIDE;
}

export interface PositionsState {
  exchanges: Record<string, { accounts: Record<string, { positions: Record<string, PositionType> }> }>
}

export interface PositionServerResp {
  exchangeName: string;
  accountId: string;
  position: PositionTypeServer;
}

export interface PositionsServerResp {
  exchangeName: string;
  accountId: string;
  positions: Record<string, PositionTypeServer>;
}
