import { defineStore } from 'pinia';
import { SidebarState } from '@/types/general';

export const useSidebarStore = defineStore('sidebar', {
  state: (): SidebarState => ({
    sidebarOpen: localStorage.getItem('sidebarOpen') ? localStorage.getItem('sidebarOpen') === 'true' : false,
  }),
  actions: {
    toggleSidebarOpen() {
      this.sidebarOpen = !this.sidebarOpen;
      localStorage.setItem('sidebarOpen', String(this.sidebarOpen));
    },
  },
  getters: {
    getSidebarOpen(state): boolean {
      return state.sidebarOpen;
    },
  },
});
