<template>
  <div class="wrapper-tw">
    <div v-if="loadView" class="w-full grid">
      <router-view class="col col-remaining" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useResourcesStore } from '@/stores/resources/resources';

const resourcesStore = useResourcesStore();
const router = useRouter();
const route = useRoute();

const loadView = ref(false);
const loggedIn = computed(() => resourcesStore.isLoggedIn);

watch(loggedIn ,() => {
  loginRedirect();
});

onMounted(async () => {
  await resourcesStore.authenticateUser();
  loginRedirect();
  loadView.value = true;
});

const loginRedirect = () => {
  const loginPath = '/resources/login';

  if (!loggedIn.value && route.path != loginPath) {
    void router.replace({ path: loginPath });
  } else if (loggedIn.value && route.path == loginPath) {
    void router.replace({ path: '/resources/categories' });
  }
};
</script>

<style lang="scss">
</style>
