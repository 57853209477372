import { defineStore } from 'pinia';
import { FilesState, FilesServerResp, FileServerResp, File, FileServer } from '@/types/resources';
import { createRequestData } from '@/utilities';
import { useUserStore } from '@/stores/user/user';
import Config from '@/config';
import { createNotification } from '@/stores/user/notifications';
import { NOTIFICATION_TYPE } from '@/types/user';
import { RespError } from '@/types/general';

function convertFileServer(file: FileServer): File {
  const tags = file.tags == null ? [] : file.tags;
  const newFile = new File(file.id, file.body, file.created, tags, file.title, file.type);
  return newFile;
}

export const useFilesStore = defineStore('files', {
  state: (): FilesState => ({
    files: {},
  }),
  actions: {
    async getFiles(categoryId: string, fileType: string): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/files/${categoryId}/${fileType}`,
          createRequestData('GET', useUserStore().token, null),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        const resp = await response.json() as FilesServerResp;
        const newFiles: Record<string, File> = {};

        for (const key in resp.files) {
          const newFile = convertFileServer(resp.files[key]);
          newFiles[newFile.id] = newFile;
        }

        this.files = newFiles;
        createNotification('success getFiles', NOTIFICATION_TYPE.SUCCESS);
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error getFile ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    async getFile(categoryId: string, fileType: string, fileId: string): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/files/${categoryId}/${fileType}/${fileId}`,
          createRequestData('GET', useUserStore().token, null),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        const resp = await response.json() as FileServerResp;
        this.files[resp.file.id] = convertFileServer(resp.file);
        createNotification('success getFile', NOTIFICATION_TYPE.SUCCESS);
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error getFile ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    async updateFile(categoryId: string, fileType: string, fileId: string, file: File): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/files/${categoryId}/${fileType}/${fileId}`,
          createRequestData('PATCH', useUserStore().token, file),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        const resp = await response.json() as FileServerResp;
        this.files[resp.file.id] = convertFileServer(resp.file);
        createNotification('success updateFile', NOTIFICATION_TYPE.SUCCESS);
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error updateFile ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    async deleteFile(categoryId: string, fileType: string, fileId: string): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/files/${categoryId}/${fileType}/${fileId}`,
          createRequestData('DELETE', useUserStore().token, null),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        delete this.files[fileId];
        createNotification('success deleteFile', NOTIFICATION_TYPE.SUCCESS);
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error deleteFile ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
    async createFile(categoryId: string, fileType: string, file: File): Promise<void> {
      try {
        const response = await fetch(
          `${Config.apiEndpoint()}/resources/files/${categoryId}/${fileType}`,
          createRequestData('POST', useUserStore().token, file),
        );

        if (!response.ok) {
          const resp = await response.json() as RespError;
          throw new Error(resp.error);
        }

        const resp = await response.json() as FileServerResp;
        this.files[resp.file.id] = convertFileServer(resp.file);
        createNotification('success createFile', NOTIFICATION_TYPE.SUCCESS);
      } catch (error: unknown) {
        const typedError = error as string;
        createNotification(`error createFile ${typedError}`, NOTIFICATION_TYPE.ERROR);
      }
    },
  },
});
