export interface LiquidationsServerResp {
  category: string;
  type: string;
  exchangeName: string;
  exchangeType: string;
  symbol: string;
  liquidations: LiquidationTypeServer[];
}

export interface LiquidationTypeServer {
  time: number;
  price: string;
  quantity: string;
  side: string;
}

export class LiquidationType {
  public time: number;
  public price: string;
  public quantity: string;
  public side: string;

  // Auxilliary properties (for lightweight-charts)
  public buyVolume: number;
  public sellVolume: number;

  constructor(time: number, price: string, quantity: string, side: string) {
    this.time = time;
    this.price = price;
    this.quantity = quantity;
    this.side = side;
  }
}

export interface LiquidationsState {
  liquidations: Record<string, Record<string, Record<string, LiquidationType[]>>>;
}
