<template>
  <div class="grid">
    <div class="col-2">
      <aside class="sidebar-nav panel panel--loose-spacing">
        <header class="panel__header">
          <h2 class="panel__header__title">Settings</h2>
        </header>
        <section class="sidebar-nav__items">
          <router-link
            :class="$route.path.includes('account-management') ? 'active' : ''" to="/settings/account-management"
            class="flex items-center justify-between"
          >
            <span>System</span>

            <span v-if="systemWarnings > 0" class="bg-red-600 text-white text-sm font-bold px-2 py-1 rounded-full">
              {{ systemWarnings }}
            </span>
          </router-link>
          <router-link
            :class="$route.path.includes('exchange-management') ? 'active' : ''"
            to="/settings/exchange-management" class="flex items-center justify-between"
          >
            <span>Exchanges</span>

            <span v-if="exchangeWarnings > 0" class="bg-red-600 text-white text-sm font-bold px-2 py-1 rounded-full">
              {{ exchangeWarnings }}
            </span>
          </router-link>
          <router-link
            :class="$route.path.includes('misc-management') ? 'active' : ''"
            to="/settings/misc-management" class="flex items-center justify-between"
          >
            <span>Misc</span>
          </router-link>
        </section>
      </aside>
    </div>
    <div class="col col-10">
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useUserStore } from '@/stores/user/user';
import { checkPwExpired } from '@/utilities';

// Stores
const accountManagementStore = useAccountManagementStore();
const userStore = useUserStore();

// Computed
const userPasswordsTs = computed(() => userStore.passwordsTs);
const user2AuthEnabled = computed(() => userStore.user2AuthEnabled);
const systemWarnings = computed(() => {
  let warnings = 0;
  const passwordsTs = userPasswordsTs.value;

  if (checkPwExpired(passwordsTs.encrypt || 0)) warnings++;
  if (checkPwExpired(passwordsTs.firestore || 0)) warnings++;
  if (checkPwExpired(passwordsTs.nonce || 0 )) warnings++;
  if (!user2AuthEnabled.value) warnings++;

  return warnings;
});
const exchangeWarnings = computed(() => {
  let warnings = 0;
  const exchanges = accountManagementStore.getExchanges;

  for (const key in exchanges) {
    const exchange = exchanges[key];

    for (const id in exchange.accounts) {
      const account = exchange.accounts[id];
      if (checkPwExpired(account.apiKey?.createdTs || 0)) warnings++;
    }
  }

  return warnings;
});
</script>

<style lang="scss" scoped>
.sidebar-nav {

  .panel__header {
    line-height: 1.35;
    height: auto;
    h2 {
      font-size: 1.25em;

    }
  }
  &__items {
    display: flex;
    flex-direction: column;

    a {
      padding: .5rem;
      border-top: 1px solid var(--panel-border);
      color: rgba(255,255,255,.85);
      transition: all 0.25s ease;

      &:hover {
        background-color: var(--brand-primary);
        color: white;
      }

      &.active {
        color: var(--brand-primary);

        &:hover {
          color: white;
        }
      }
    }
  }
}
</style>
