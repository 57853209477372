<template>
  <div id="overlay" class="row-tw" @click.self="closePopup()">
    <div class="card text-center mx-40 my-2">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['closePopup']);

const closePopup = () => {
  emit('closePopup');
};
</script>

<style lang="scss" scoped>
#overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 101; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.popup {
  cursor: auto;
  max-height: 600px;
  overflow: scroll;
}
</style>
