import { IBox, SeriesMarker, Time, CrosshairMode, PriceScaleMode } from 'lightweight-charts-private';

export class PriceDataMeta {
  public ticker: string;
  public priceData: PriceData;
  public priceDataDebug: boolean;
  public swings: Swing[];
  public swingBoxes: Box[];
  public swingColour: string;
  public swingsDebug: boolean;
  public trends: Trend[];
  public trendBoxes: Box[];
  public trendColour: string;
  public trendsDebug: boolean;

  constructor(ticker: string) {
    this.ticker = ticker;
    this.swings = [];
    this.swingBoxes = [];
    this.trends = [];
    this.trendBoxes = [];
  }
}

export class Corner {
  public x: number;
  public y: string;

  constructor(x: number, y: string) {
    this.x = x;
    this.y = y;
  }
}

export class Box {
  public fromDate: number;
  public toDate: number;
  public fromPrice: string;
  public toPrice: string;
  public corners: Corner[];
  public colour: string;
  // title string

  constructor(
    fromDate: number,
    toDate: number,
    fromPrice: string,
    toPrice: string,
    colour: string,
    corners: Corner[] = null,
  ) {
    if (corners !== null) {
      this.corners = corners;
    } else {
      this.fromDate = fromDate;
      this.toDate = toDate;
      this.fromPrice = fromPrice;
      this.toPrice = toPrice;
    }

    this.colour = colour;
  }
}

export class PriceData {
  public ticker: string;
  public timeframe: number; // Seconds
  public fromDate: number;
  public toDate: number;
  public candles: Candle[];
  public candleChanges: Candle[];

  constructor(ticker: string, timeframe: number, fromDate: number, toDate: number, candles: Candle[]) {
    this.ticker = ticker;
    this.timeframe = timeframe;
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.candles = candles;
    this.candleChanges = [];
  }
}

export class Candle {
  public startTime: number;
  public endTime: number;
  public open: string;
  public close: string;
  public high: string;
  public low: string;
  public volume: string;
  public chosenAssetVolume: string;

  constructor(
    startTime: number,
    endTime: number,
    open: string,
    close: string,
    high: string,
    low: string,
    volume: string,
    chosenAssetVolume: string,
  ) {
    this.startTime = startTime;
    this.endTime = endTime;
    this.open = open;
    this.close = close;
    this.high = high;
    this.low = low;
    this.volume = volume;
    this.chosenAssetVolume = chosenAssetVolume;
  }
}

export class PriceDataSwingTestResult {
  public testName: string;
  public expectedResults: Swing[];
  public expectedResultsMeta: Box[];
  public actualResults: Swing[];
  public actualResultsMeta: Box[];
  public priceData: PriceData;
  public error: string;
  public category: string;

  constructor(
    testName: string,
    expectedResults: Swing[],
    expectedResultsMeta: Box[],
    actualResults: Swing[],
    actualResultsMeta: Box[],
    priceData: PriceData,
    error: string,
    category: string,
  ) {
    this.testName = testName;
    this.expectedResults = expectedResults;
    this.expectedResultsMeta = expectedResultsMeta;
    this.actualResults = actualResults;
    this.actualResultsMeta = actualResultsMeta;
    this.priceData = priceData;
    this.error = error;
    this.category = category;
  }
}

export class PriceDataTrendTestResult {
  public testName: string;
  public expectedResults: Trend[];
  public expectedResultsMeta: Box[];
  public actualResults: Trend[];
  public actualResultsMeta: Box[];
  public priceData: PriceData;
  public error: string;
  public category: string;

  constructor(
    testName: string,
    expectedResults: Trend[],
    expectedResultsMeta: Box[],
    actualResults: Trend[],
    actualResultsMeta: Box[],
    priceData: PriceData,
    error: string,
    category: string,
  ) {
    this.testName = testName;
    this.expectedResults = expectedResults;
    this.expectedResultsMeta = expectedResultsMeta;
    this.actualResults = actualResults;
    this.actualResultsMeta = actualResultsMeta;
    this.priceData = priceData;
    this.error = error;
    this.category = category;
  }
}

export class Swing {
  public fromIndex: number;
  public toIndex: number;
  public fromPriceWick: string;
  public fromPriceBody: string;
  public toPriceBody: string;
  public toPriceWick: string;
  public direction: string;

  constructor(
    fromIndex: number,
    toIndex: number,
    fromPriceWick: string,
    fromPriceBody: string,
    toPriceBody: string,
    toPriceWick: string,
    direction: string,
  ) {
    this.fromIndex = fromIndex;
    this.toIndex = toIndex;
    this.fromPriceWick = fromPriceWick;
    this.fromPriceBody = fromPriceBody;
    this.toPriceBody = toPriceBody;
    this.toPriceWick = toPriceWick;
    this.direction = direction;
  }
}

export class Trend {
  public fromIndex: number;
  public toIndex: number;
  public fromPriceWick: string;
  public fromPriceBody: string;
  public toPriceBody: string;
  public toPriceWick: string;
  public direction: string;
  public swings: Swing[];
  public trends: Trend[];

  constructor(
    fromIndex: number,
    toIndex: number,
    fromPriceWick: string,
    fromPriceBody: string,
    toPriceBody: string,
    toPriceWick: string,
    direction: string,
  ) {
    this.fromIndex = fromIndex;
    this.toIndex = toIndex;
    this.fromPriceWick = fromPriceWick;
    this.fromPriceBody = fromPriceBody;
    this.toPriceBody = toPriceBody;
    this.toPriceWick = toPriceWick;
    this.direction = direction;
    this.swings = [];
    this.trends = [];
  }
}

// TODO improve naming
export class State {
  public direction: string;
  public swings: Swing[];
  public stage: string;
  public currentSwing: Swing;
  public currentBox: Box;
  public breakingRangeDirection: string;
  public trends: Trend[];
  public trendStack: Trend[];
  public toIndex: number;

  constructor(
    direction: string,
    swings: Swing[],
    stage: string,
    breakingRangeDirection: string,
    trends: Trend[],
    trendStack: Trend[],
    toIndex: number,
  ) {
    this.direction = direction;
    this.swings = swings;
    this.stage = stage;
    this.breakingRangeDirection = breakingRangeDirection;
    this.trends = trends;
    this.trendStack = trendStack;
    this.toIndex = toIndex;
  }
}

export class DebuggedState {
  public priceData: PriceData;
  public states: State[];

  constructor(priceData: PriceData, states: State[]) {
    this.priceData = priceData;
    this.states = states;
  }
}

export class DisplayTrend {
  public prefix: string;
  public trend: Trend;
  public boxes: Box[];
  // TODO is the correct
  public createdBoxes: IBox[];
  public suffix: string;
}

export class PriceDataState {
  data: Record<string, PriceData>;
  meta: Record<string, PriceDataMeta>;
  testResults: Record<string, PriceDataTrendTestResult[] | PriceDataSwingTestResult[]>;
  testResultsDebug: DebuggedState;
}

export interface PriceDataTestResultsServerResp {
  body: PriceDataSwingTestResultServer[] | PriceDataTrendTestResultServer[];
}

export interface PriceDataTestResultsDebuggerServerResp {
  body: DebuggedStateServer;
}

export interface DebuggedStateServer {
  priceData: PriceDataServer;
  states: State[];
}

export interface PriceDataServerResp {
  body: PriceDataServer
  exchangeName: string;
  exchangeType: string;
  symbol: string;
}

export interface PriceDataSwingTestResultServer {
  testName: string;
  expectedResults: Swing[];
  expectedResultsMeta: Box[];
  actualResults: Swing[];
  actualResultsMeta: Box[];
  priceData: PriceDataServer;
  error: string;
  category: string;
}

export interface PriceDataTrendTestResultServer {
  testName: string;
  expectedResults: Trend[];
  expectedResultsMeta: Box[];
  actualResults: Trend[];
  actualResultsMeta: Box[];
  priceData: PriceDataServer;
  error: string;
  category: string;
}

export interface PriceDataMetaServer {
  ticker: string;
  priceData: PriceDataServer;
  priceDataDebug: boolean;
  swings: Swing[];
  swingBoxes: Box[];
  swingColour: string;
  swingsDebug: boolean;
  trends: Trend[];
  trendBoxes: Box[];
  trendColour: string;
  trendsDebug: boolean;
}

export interface PriceDataMetaServerResp {
  body: PriceDataMetaServer;
}

export interface GraphCandle {
  time: Time;
  open?: number;
  close?: number;
  high?: number;
  low?: number;
  value?: number;
  color?: string;
}

export interface PriceDataServer {
  ticker: string;
  timeframe: number;
  fromDate: number;
  toDate: number;
  priceDataItems: PriceDataItemServer[];
}

export interface PriceDataItemServer {
  startTime: number;
  endTime: number;
  open: string;
  close: string;
  high: string;
  low: string;
  volume: string;
  chosenAssetVolume: string;
}

export interface ChartViewI {
  setGraphCandles: (newGraphCandles: GraphCandle[]) => void;
  setTimeframe: (timeframe: number) => void;
  renderChartFixedScale: () => void;
  removeAllRenderedBoxes: () => void;
  addBoxesToGraph: (boxes: Box[], defaultFillColour: string, boxesTracker?: DisplayTrend) => void;
  setMarkers: (markers: SeriesMarker<Time>[]) => void;
  removeRenderedBoxes: (boxes: IBox[]) => void;

  tickerName: string;
}

export interface ChartOptions {
  autoSize: boolean;
  rightPriceScale: {
    mode: PriceScaleMode;
    invertScale: boolean;
  };
  layout: {
    background: {
      color: string;
    };
    textColor: string;
  };
  grid: {
    vertLines: {
      color: string;
    };
    horzLines: {
      color: string;
    };
  };
  crosshair: {
    mode: CrosshairMode;
  };
  timeScale: {
    timeVisible: boolean;
  };
}
