<template>
  <div class="grid">
    <div class="col" :class="sidebarOpen ? 'col-2' : 'col-closed'">
      <LogsFilter :open="sidebarOpen" />
    </div>

    <div class="col col-10">
      <div class="panel">
        <div class="panel__header">
          <h3 class="panel__header__title">
            {{ logFileName }} Logs ({{ filteredLogFile.length }})
            <span v-if="filteredLogFile.length == 10000" class="text-red-500 text-xs">max amount of display logs</span>
          </h3>
          <i class="ti-reload sidebar-icon-tw hand mx-2" @click="getFile()" />
        </div>

        <div class="panel__body vtable">
          <div v-for="(row, index) in filteredLogFile" :key="index" class="vtable__row">
            <div class="vtable__row__item">
              <pre style="text-wrap: wrap;">{{ row }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted } from 'vue';
import { useSidebarStore } from '@/stores/sidebar';
import { useClientLogsStore } from '@/stores/user/clientLogs';
import { useServerLogsStore } from '@/stores/user/serverLogs';
import { useWebSocketStore } from '@/stores/user/ws';
import { useRoute } from 'vue-router';
import LogsFilter from '@/components/user/logs/LogsFilter.vue';
import { LogsWrapper } from '@/types/serverLogs';

// Store
const clientLogsStore = useClientLogsStore();
const serverLogsStore = useServerLogsStore();
const wsStore = useWebSocketStore();
const sidebarStore = useSidebarStore();
const route = useRoute();

// Computed
const logFileName = computed(() => String(route.params.logFileName));
const logFile = computed(() => serverLogsStore.file);
const sidebarOpen = computed(() => sidebarStore.getSidebarOpen);
const exchangeNameFilter = computed(() => route.query.exchangeName ? String(route.query.exchangeName) : '');
const phrase = computed(() => route.query.phrase ? String(route.query.phrase) : '');
const logTypeFilter = computed(() => route.query.logType ?  String(route.query.logType) : '');
const filteredLogFile = computed(() => {
  let logLines = logFile.value;

  if (logFileName.value === 'client') {
    logLines = clientLogsStore.getLogs().map(log => log.toString());
  }

  return logLines.filter((line) => {
    if (phrase.value && !line.includes(phrase.value)) {
      return false;
    }

    if (logTypeFilter.value && !line.includes(logTypeFilter.value)) {
      return false;
    }

    if (exchangeNameFilter.value && !line.includes(exchangeNameFilter.value)) {
      return false;
    }

    return true;
  });
});

const maxLines = 10000;

onMounted(() => {
  getFile();
});

onUnmounted(() => {
  serverLogsStore.setLogFile({ logFile: [] });
});

const getFile = () => {
  if (logFileName.value === 'client') {
    return;
  }

  const logsWrapper = new LogsWrapper('get_log_file');
  logsWrapper.fileName = logFileName.value;
  logsWrapper.maxLines = maxLines;
  wsStore.sendWithRequestStatus(logsWrapper);
};
</script>

<style lang="scss" scoped>

</style>
