<script setup lang="ts">
const emit = defineEmits(['closeModal']);
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
});
const sendClose = () => {
  emit('closeModal');
};
</script>

<template>
  <div class="modal" @click.self.stop="sendClose">
    <div class="modal__inner">
      <header class="modal__inner__header">
        <h2>{{ props.title }}</h2>
        <button class="modal__inner__header__close" @click="sendClose">X</button>
      </header>
      <div class="modal__inner__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.modal {
    background: rgba(0,0,0,.4);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px);

    &__inner {
        background: black;
        min-width: 400px;
        min-height: 200px;

        &__header {
            background: var(--panel-header-bg);
            color: var(--panel-header-color);
            display: flex;
            align-items: center;
            padding: .5rem .5rem;

            &__close {
                margin-left: auto;
                padding: .5rem .5rem;

                &:hover {
                    color: white;
                }
            }
        }
    }
}
.modal-form__error {
  width: auto;
  padding: .5rem;
  font-size: 14px;
  margin: 0;
  width: 100%;
  font-style: italic;
  color: red;
  text-align: center;
}
.modal-form__button {
  width: auto;
  padding: .5rem;
  font-size: 14px;
  margin: 0;
  width: 100%;
}
.modal-form__item {
    display: flex;
    align-items: flex-start;
    padding: 5px;

    label {
      padding: 4px 8px;
      width: 40%;
      text-align: right;
    }
    input {
      margin: 2px 2px 5px;
      width: 60%;
      padding: 2px 4px;
      color: white;
      background-color: transparent;
      border: 1px solid var(--panel-border);

      &:disabled {
        background-color: #222;
        opacity: .75;
      }
      &:focus {
        outline: none;
        border-bottom: 4px solid var(--panel-border);
        margin-bottom: 2px;
      }
    }
    select {
      margin: 2px 2px 5px;
      width: 60%;
      padding: 2px 1px;
      color: white;
      background-color: transparent;
      border: 1px solid var(--panel-border);
    }
}
</style>
